import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BlogPostsService } from '../blog-posts.service';

@Injectable()
export class EditBlogPostResolver implements Resolve<boolean> {
    constructor(
      private router: Router,
      private service: BlogPostsService,
      private snackBar: MatSnackBar,
      ) { }


    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        const postID = route.params.postid;
        // Return Data
        return this.service.getBlogPost(postID).pipe(map(result => {
            if (!result) {
                this.router.navigate(['../../']);
                this.snackBar.open('Gönderi bulunamadı', 'ok', {duration: 3000});
            }
            return result.post;
        }));
    }
}
