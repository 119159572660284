import { getAppUrl } from './../../common/helpers/get-app-url';
import { LocaleLoaderService } from 'src/app/translations/locale-loader.service';
import { Injectable, Inject, OnDestroy } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute, NavigationEnd, ActivatedRouteSnapshot, RoutesRecognized, RouterEvent } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class SeoService implements OnDestroy {
    public routerEvents: Subscription;
    constructor(
        private title: Title,
        private meta: Meta,
        private translate: LocaleLoaderService,
        private router: Router,
        private route: ActivatedRoute,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    ngOnDestroy(): void {
        this.routerEvents.unsubscribe();
    }

    init(): Promise<boolean>{
      return new Promise<boolean>((resolve, reject) => {
        this.observeAndChangeData();
        resolve(true);
      });
    }

    observeAndChangeData() {
        this.routerEvents = this.router.events
        .pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => {
                let  child = this.route.firstChild;
                while (child.firstChild) {
                    child = child.firstChild;
                }

                return child.snapshot;
            })
        )
        .subscribe((snapShot: any) => {
            const routeData = snapShot.data;
            const pagePath = snapShot._routerState.url as string;

            this.setHtmlLang();
            this.addLangugageTags();
            this.createCanonicalURL(pagePath);

            // sitename
            this.addMeta('property', 'og:site_name', 'public.generals.name', true);

            // type
            this.addMeta('property', 'og:type', 'article');

            // no index tag
            if (!routeData.noIndex) { // for aux routes
                this.addMeta('name', 'robots', 'index, follow');
            } else {
                this.addMeta('name', 'robots', 'noindex');
            }
            // Set unique data...
            // if it is project
            if (routeData.postData) {
                this.setUniqueData({
                    title: routeData.postData.title,
                    desc: routeData.postData.summary,
                    image: routeData.postData.image
                });
            } else if (routeData.projectData) {
                this.setUniqueData({
                    title: routeData.projectData.title,
                    desc: routeData.projectData.summary,
                    image: routeData.projectData.image
                });

                if (pagePath.includes('subpage')) {
                    this.addMeta('name', 'robots', 'noindex');
                }

            } else {
                this.setUniqueData({
                    title: routeData.title,
                    desc: routeData.desc,
                    image: this.getImageUrl(routeData.image)
                });
            }
         });
    }


    setUniqueData(details: {title: string, desc: string, image: string}) {
        if (details.title) {
            this.setTitle(details.title);
        }

        if (details.desc) {
            this.addMeta('name', 'description', details.desc, true);
            this.addMeta('property', 'og:description', details.desc, true);
            this.addMeta('name', 'twitter:description', details.desc, true);
        }

        if (details.image) {
            this.addMeta('property', 'og:image', details.image);
            this.addMeta('name', 'twitter:image', details.image);
        }
    }


    getImageUrl(name: string) {
        const currentPath = this.translate.currentBase;
        const image = '/assets/images/' + name + (currentPath ? '_' + currentPath : '');
        return image + '.jpg';
    }


    setHtmlLang() {
        this.document.documentElement.setAttribute('lang', this.translate.currentLang);
        this.addMeta('property', 'og:locale', this.translate.getLocale(this.translate.currentLang, '_'));
    }

    // this is language tags...
    addLangugageTags() {
        // remove all
        this.removeLanguageTags();

        // generate new ones
        const translations = this.translate.getTranslations().map(data => {
            return {
                ...data,
                url:  getAppUrl + data.url
            };
        });

        for (const translation of translations) {
            this.createElement('link', {
                rel: 'alternate',
                href: translation.url,
                hreflang: translation.locale,
            });
            this.createElement('meta', {
                property: 'og:locale:alternate',
                content: translation.locale2,
            });
        }
    }


    createElement(el: string, attrs: any) {
        const newElement = this.document.createElement(el);

        for (const attr of Object.keys(attrs)) {
            newElement.setAttribute(attr, attrs[attr]);
        }

        return this.document.head.appendChild(newElement);
    }

    // remove language tags
    removeLanguageTags() {
        const currentLangTags = this.document.querySelectorAll('link[rel=\'alternate\'][hreflang]');
        const currentOggTags = this.document.querySelectorAll('meta[property=\'og:locale:alternate\']');

        if (currentLangTags.length) {
            currentLangTags.forEach(node => node.parentNode.removeChild(node));
        }
        if (currentOggTags.length) {
            currentOggTags.forEach(node => node.parentNode.removeChild(node));
        }
    }

    // this is canonical url
    createCanonicalURL(path: string) {
        let url = getAppUrl + path;

        if (path.includes('subpage')) { // for aux routes
            url = url.substring(0, url.lastIndexOf('/'));
        }

        // add ogg
        this.addMeta('property', 'og:url', url);

        const canonicals = this.document.querySelectorAll('link[rel=\'canonical\']');
        // if canonical tag exists update it
        if (canonicals.length) {
            return canonicals[0].setAttribute('href', url);
        }

        // if not create new one...
        this.createElement('link', {
            rel: 'canonical',
            href: url,
        });
    }

    // remove canonical url
    removeCanonical() {
        const canonicals = this.document.querySelectorAll('link[rel=\'canonical\']');
        if (canonicals.length) {
            canonicals.forEach(node => node.parentNode.removeChild(node));
        }
    }

    setTitle(title: string) {
        let titleToSet  = this.translateThis(title);

        // add brand name...
        if (!title.includes('home.title')) {
            titleToSet = titleToSet + ' - ' + this.translateThis('public.generals.name');
        }

        this.addMeta('property', 'og:title', titleToSet, true);
        this.addMeta('name', 'twitter:title', titleToSet, true);
        this.title.setTitle(titleToSet);
    }


    addMeta(meta: string, metaName: string, metaContent: string, translate: boolean = false) {
        const isTag = this.meta.getTag(meta + '=\'' + metaName + '\'');

        const data = { [meta]: metaName, content: translate ? this.translateThis(metaContent) : metaContent };
        if (isTag) {
            return this.meta.updateTag(data);
        }

        return this.meta.addTag(data);
    }


    translateThis(str: string) {
        if (str) {
            return this.translate.instant(str);
        }
    }

}
