<form [formGroup]="othersForm" class="formUnit">
    <app-image-upload-input formControlName="image" #fileUpload [bgValue]="thumb"></app-image-upload-input>
</form>

<mat-card class="formUnit">
    <div class="lang">
        <button mat-stroked-button [matMenuTriggerFor]="menu"><span>Dil: </span><span class="font-weight-bold">{{currentLang}}</span></button>
        <mat-menu #menu="matMenu">
            <ng-container *ngFor="let lang of langs">
                <button mat-menu-item *ngIf="currentLang !== lang" (click)="changeLang(lang)">{{lang}}</button>
            </ng-container>
        </mat-menu>
    </div>

    <form [formGroup]="textForms[currentLang]">
        <mat-form-field>
            <mat-label>Başlık</mat-label>
            <input type="text" matInput formControlName="title"
                    placeholder="Ex. Teneli attık...">
            <mat-error *ngIf="!textForms[currentLang].controls.title.valid && textForms[currentLang].controls.title.touched">
                Lütfen geçerli bir başlık girin
            </mat-error>
        </mat-form-field>
    
        <mat-form-field>
            <mat-label>Açıklama</mat-label>
            <textarea 
            cdkTextareaAutosize
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"
            matInput placeholder="Ex. It makes me feel because..." formControlName="desc"></textarea>
            <mat-error *ngIf="!textForms[currentLang].controls.desc.valid && textForms[currentLang].controls.desc.touched">
                Lütfen projeniz için açıklama yazın...
            </mat-error>
        </mat-form-field>
    </form>    
</mat-card>



<app-fixed-action-buttons
    actionText="{{mode === 'edit' ? 'Güncelle' : 'Ekle'}}"
    mode="single"
    cancelText="İptal et"
    (applyAction)="onSubmit()"
    (cancelAction)="onBack()"
    [disabledState]="!formsValid"
></app-fixed-action-buttons>