<h4 class="mb-1">Kullanıcı Yönetimi</h4>
<div class="topButtons">
    <button mat-raised-button [routerLink]="['../add-user']">
        <mat-icon>add</mat-icon>
        {{ 'Yeni kullanıcı' | translate }}
    </button>
    <button class="openColumnSelector ml-1" mat-raised-button (click)="openDialog(columnSelectorTemplate, '250px')">
        <mat-icon>view_column</mat-icon>
        {{ 'Kolonlar' | translate }}
    </button>
</div>


<ng-template #columnSelectorTemplate>
    <div><strong>{{ 'Kolonlar' | translate }}</strong></div>
    <mat-slide-toggle class="w-100" *ngFor="let col of columns; let i = index" color="primary" [checked]="col.show"  (change)="showOrHideColumn($event, i)">
        {{ col.string | translate }}
    </mat-slide-toggle>

    <div mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>{{ 'kapat' | translate }}</button>
    </div>
</ng-template>

<mat-form-field>
    <mat-label>{{ 'Eposta ile ara' | translate }}</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="{{ 'Eposta ile ara' | translate }}" #input>
</mat-form-field>

<div class="dataTable">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="email" matSortDirection="desc" matSortDisableClear>
        <ng-container *ngFor="let column of columns; let i = index; " [matColumnDef]="column.field">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="column.field === 'controls'">
                {{ column.string }}
            </th>
            <td mat-cell *matCellDef="let row" [ngSwitch]="column.field">
                <ng-container *ngSwitchCase="'uid'">
                    {{row[column.field]}}
                </ng-container>
                <ng-container *ngSwitchCase="'displayName'">
                    {{row[column.field]}}
                </ng-container>
                <ng-container *ngSwitchCase="'email'">
                    {{row[column.field]}}
                </ng-container>
                <ng-container *ngSwitchCase="'role'">
                    <mat-icon [inline]="true" *ngIf="row[column.field] === 'admin'" class="mr-2">security</mat-icon>
                    {{row[column.field]}}
                </ng-container>
                <ng-container *ngSwitchCase="'creationTime'">
                    {{row[column.field] | localDate}}
                </ng-container>
                <ng-container *ngSwitchCase="'controls'">
                    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu">
                        <button *ngIf="row.email" mat-menu-item (click)="sendResetPasswordLink(row.email)">
                          <span>Şifre sıfırlama epostası gönder</span>
                        </button>
                        <button mat-menu-item (click)="removeUser(row.uid)">
                          <span>Kullanıcıyı sil</span>
                        </button>
                      </mat-menu>
                </ng-container>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['../user', row.uid]"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="9999" *ngIf="(loading | async) === false">
                <div class="tableWarn">
                    {{ 'Sonuç bulunamadı' | translate }}
                </div>
            </td>
        </tr>
    </table>

    <div class="tableWarn loading" *ngIf="(loading | async)"><mat-progress-bar mode="indeterminate"></mat-progress-bar></div>

</div>

<mat-paginator [length]="total" [pageSize]="10" [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
