import { SinglePostResolver } from './single-post/single-post.resolver';
import { AllPostsResolver } from './all-posts/all-posts.resolver';
import { MagazineService } from './magazine.service';
import { SinglePostComponent } from './single-post/single-post.component';
import { AllPostsComponent } from './all-posts/all-posts.component';
import { PublicModule } from 'src/app/public/public.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MagazineComponent } from './magazine.component';
import { MagazineRoutingModule } from './magazine.routing.module';



@NgModule({
  declarations: [MagazineComponent, AllPostsComponent, SinglePostComponent],
  imports: [
    CommonModule,
    PublicModule,
    MagazineRoutingModule
  ],
  providers: [
    MagazineService,
    AllPostsResolver,
    SinglePostResolver
  ]
})
export class MagazineModule { }
