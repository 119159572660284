import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, OnInit, AfterViewInit, Inject, PLATFORM_ID } from '@angular/core';

import { gsap } from 'gsap';
import LazyLinePainter from 'lazy-line-painter';

@Component({
  selector: 'app-it-is-success',
  templateUrl: './it-is-success.component.html',
  styleUrls: ['./it-is-success.component.scss']
})
export class ItIsSuccessComponent implements OnInit, AfterViewInit {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.animate();
    }
  }


  animate() {
    const check = this.document.querySelector('#check');
    const home = this.document.querySelector('#home');

    const checkDrawAnimation = new LazyLinePainter(check, {reverse: true});
    const homeDrawAnimation = new LazyLinePainter(home, { delay: 1100, reverse: true });
    checkDrawAnimation.paint();
    gsap.to(check, {rotateZ: 180, delay: 0.8, duration: 0.4});
    gsap.to('#check path', {stroke: '#000', delay: 0.8, duration: 0.4});
    homeDrawAnimation.paint();
  }

}
