<div *ngIf="(project | async); let project">
    <div class="notUploaded" *ngIf="!project?.projectPlan">
        <p>Proje görsellerinize ek olarak dilerseniz indirilebilir bir pdf yükleyebilirsiniz</p>
        <p class="lead">Henüz proje pdf'i yüklemediniz.</p>
    </div>

    <div class="uploadedPlan" *ngIf="project?.projectPlan">
        <p class="lead">Proje planınız yüklendi</p>
        <p>Görüntülemek için <a class="font-weight-bold color-pri" target="_blank" href="{{fileUrl}}">buraya</a> tıklayın</p>
    </div>

    <button mat-raised-button color="primary" (click)="openDialog()"><mat-icon class="mr-1">file_upload</mat-icon>
        {{ project?.projectPlan ? 'Yeniden Yükleyin' : 'Şimdi Yükle'}}
    </button>

    <button class="ml-2" mat-raised-button color="warn" *ngIf="project?.projectPlan" (click)="removePlan()"><mat-icon class="mr-1">delete</mat-icon>Pdf'i sil</button>

</div>

<app-fixed-action-buttons
    actionText="İptal et"
    mode="single"
    (applyAction)="goBack()"
></app-fixed-action-buttons>

<ng-template #uploaderDialog>
    
    <app-file-uploader *ngIf="!(uploading | async)" [mimeTypes]="['application/pdf']" [extensions]="['.pdf']" [maxMb]="10" (filesSelected)="onPdfSelect($event)"></app-file-uploader>

    <div class="uploadProgress" *ngIf="(uploading | async)">
        <div class="message">Pdf yükleniyor...</div>
        <div class="spinner">
            <mat-progress-spinner color="primary" mode="determinate" [value]="(uploadProgress | async)"></mat-progress-spinner>
            <div class="progress"><span class="per">%</span>{{uploadProgress | async }}</div>
        </div>
    </div>
</ng-template>
