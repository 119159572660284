import { AddProjectService } from './../add-project.service';
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil, filter, debounceTime, take } from 'rxjs/operators';
import { ImageItem } from '../../../../public/models/general.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImagesComponent implements OnInit, OnDestroy {
  destroyed = new Subject();
  images = new BehaviorSubject<ImageItem[]>(null);
  endPoint: string;
  imageCount: number;
  // actions
  deleteImage = new BehaviorSubject(null);
  updateFav = new BehaviorSubject(null);
  updateTitle = new BehaviorSubject(null);
  updateOrder = new BehaviorSubject(null);
  updateImages = new BehaviorSubject(null);

  projectID: string;
  constructor(
    private projectService: AddProjectService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.parent.params.pipe(take(1)).subscribe( params => {
      const id = params.projectid;
      if (!id) {
        this.projectService.jumpTo('labels');
      }
    });

    this.projectService.project.pipe(takeUntil(this.destroyed)).subscribe(project => {
      if (project) {
        this.projectService.getShowCaseImages(project.uid).pipe(take(1)).subscribe(result => {
          this.projectService.setProgress(50);
          this.projectID = project.uid;
          this.images.next(result.images);
          this.imageCount = result.images.length;
        });
      }
    });

    this.endPoint = this.projectService.baseUrl + 'upload-showcase-image';

    this.deleteImage.pipe(takeUntil(this.destroyed), filter(data => !!data))
    .subscribe(uid => {
      this.projectService.removeShowCaseImage(this.projectID, uid).pipe(take(1)).subscribe(() => {
        this.imageCount = this.imageCount - 1;
      });
    });

    this.updateFav.pipe(takeUntil(this.destroyed), filter(data => !!data))
    .subscribe(uid => {
      this.projectService.updateShowCasePrimaryImage(this.projectID, uid).pipe(take(1)).subscribe();
    });

    this.updateTitle.pipe(takeUntil(this.destroyed), debounceTime(1000), filter(data => !!data))
    .subscribe(data => {
      this.projectService.updateShowCaseImageTitle(this.projectID, data).pipe(take(1)).subscribe();
    });

    this.updateOrder.pipe(takeUntil(this.destroyed), debounceTime(300), filter(data => !!data))
    .subscribe(data => {
      this.projectService.updateShowCaseImageOrder(this.projectID, data).pipe(take(1)).subscribe();
    });
  }

  onUpload(image) {
    this.imageCount = this.imageCount + 1;
  }

  onDelete(data) {
    this.deleteImage.next(data);
  }

  onFav(data) {
    this.updateFav.next(data);
  }

  onTitle(data) {
    this.updateTitle.next(data);
  }

  onOrder(data) {
    this.updateOrder.next(data);
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  submit() {
    this.projectService.jumpTo('project-images');
  }

  goBack() {
    this.projectService.jumpTo('shorters');
  }

}
