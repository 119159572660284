<div @fadeIn>
    <div class="topMenu">
        <button mat-raised-button *ngFor="let button of customTopButtons;" class="mr-1" (click)="customTopClick(button.buttonID)">{{button.label | translate}}</button>
        <button (click)="openDialog(uploadDialog, '800px')" mat-raised-button class="mr-1"><mat-icon class="color-pri mr-1">add</mat-icon><span>{{'Yeni Resim' | translate }}</span></button>
        <ng-container *ngIf="images.length">
            <button mat-raised-button [matMenuTriggerFor]="menu"><span>Dil: </span><span class="font-weight-bold">{{currentLang}}</span></button>
            <mat-menu #menu="matMenu">
                <ng-container *ngFor="let lang of langs">
                    <button mat-menu-item *ngIf="currentLang !== lang" (click)="changeLang(lang)">{{lang}}</button>
                </ng-container>
            </mat-menu>
        </ng-container>
    
    </div>
    
    <div class="imageHandler" #imageHandler>
        <div *ngIf="!images.length" class="noImage text-center w-100">
            <img src="/assets/images/threehand.svg">
            <div class="inf">Projenizin aktive olabilmesi için lütfen en az <span class="num">3</span> resim ekleyin</div>
        </div>
        <ng-container *ngFor="let image of images; let i=index;">
            <div #imageUnit class="imageUnit" *ngIf="!deletedImages.includes(image.uid)" [attr.data-id]="image.uid">
                <ng-container 
                    [ngTemplateOutlet]="imageTemplate" 
                    [ngTemplateOutletContext]="{ image:image }">
                </ng-container>
            </div>
        </ng-container>
    </div>
    
    
    <ng-template #imageTemplate let-image='image'>
        <div class="imageSettings" *ngIf="!image.onProgress">
            <button class="iMenu" mat-icon-button [matMenuTriggerFor]="iMenu"><mat-icon>more_vert</mat-icon></button>
            <mat-menu #iMenu="matMenu">
                    <button mat-menu-item  *ngIf="image.uid !== favImage" (click)="changeFavImage(image?.uid)">{{'Kapak Resmi Yap' | translate}}</button>
                    <button mat-menu-item (click)="deleteImage(image?.uid)">{{'Resmi Sil' | translate}}</button>
                    <button mat-menu-item *ngFor="let button of customButtons;" (click)="customClick(image?.uid, button.buttonID)">{{button.label | translate}}</button>
            </mat-menu>
        </div>
    
        <div class="image" [style.background-image]="image.path" (dblclick)="changeFavImage(image?.uid)">
            <div class="uploadProgress" *ngIf="image.onProgress">
                <mat-progress-spinner *ngIf="!image.error"
                    color="primary"
                    mode="determinate"
                    [value]="image.progress">
                </mat-progress-spinner>
                <div class="err" *ngIf="image.error">
                    <mat-icon>close</mat-icon>
                    <div>{{'Resim yüklenemedi' | translate}}</div>
                </div>
            </div>
            <div class="iamFav" *ngIf="image.uid === favImage && !image.error">
                <mat-icon class="fav1" [inline]="true">star</mat-icon>
                <mat-icon class="fav2" [inline]="true">star</mat-icon>
            </div>
        </div>
        <div class="tit">
            <mat-form-field>
                <mat-label>{{'Başlık' | translate }}</mat-label>
                <input [disabled]="image.onProgress" matInput placeholder="Ex. This is entrance" [value]="image.title ? image.title[currentLang] : null" #title (keyup)="changeTitle(image?.uid, title.value)">
            </mat-form-field>
        </div>
    </ng-template>
    
    <ng-template #uploadDialog>
        <div class="text-center pb-3" appDragAndDrop (FileDropped)="onFileSelected($event)">
            <span class="material-icons text-secondary h1">perm_media</span>
            <h4 class="text-secondary">{{ 'Resimleri Sürükleyip Bırakın' | translate }}</h4>
            <p class="text-secondary">{{ 'ya da' | translate }}</p>
            <button #fileUploadButton type="button" mat-raised-button (click)="imgFileInput.click()">
                {{ 'Bilgisayarınızdan seçin' | translate }}
            </button>
        </div>
    
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>
                {{ 'kapat' | translate }}
            </button>
        </mat-dialog-actions>
    </ng-template>    
</div>
<input hidden type="file" #imgFileInput onclick="this.value = null" multiple accept=".jpg, .jpeg, .png" (change)="onFileSelected($event.target.files)"/>
