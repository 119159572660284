<div appDragAndDrop (FileDropped)="onFileDropped($event)" class="dropBox">
    <span class="material-icons text-secondary h1">file_copy</span>
    <h4 class="text-secondary">{{ 'Dosyayı Sürükleyip Bırakın' | translate }}</h4>
    <p class="text-secondary">{{ 'ya da' | translate }}</p>
    <button #fileUploadButton type="button" mat-raised-button (click)="fileHandler.click()">
        {{ 'Bilgisayarınızdan seçin' | translate }}
    </button>
</div>

<input id="file-input" type="file" #fileHandler (change)="onChange($event)" [multiple]="multiple" [accept]="getExtensions()">
