import { ViewProjectComponent } from './view-project/view-project.component';
import { SingleProjectResolver } from './single-projects.resolver';
import {Routes, RouterModule} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import { SingleProjectComponent } from './single-project.component';


const routes: Routes = [
    {
        path: ':slug',
        component: SingleProjectComponent,
        resolve: { projectData: SingleProjectResolver },
        children: [
            {
                path: '',
                component: ViewProjectComponent
            },
            {
                path: 'plans',
                loadChildren: () => import('./plans/plans.module').then(m => m.PlansModule),
                outlet: 'subpage',
            },
            {
                path: 'promo',
                loadChildren: () => import('./project-video/project-video.module').then(m => m.ProjectVideoModule),
                outlet: 'subpage',
            }
        ]
    },
];

export const SingleProjectRoutingModule: ModuleWithProviders = RouterModule.forChild(routes);
