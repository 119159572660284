import { LocaleLoaderService } from 'src/app/translations/locale-loader.service';
// Usage: dateString | localDate:'format'

import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
    name: 'localDate'
})
export class LocalDatePipe implements PipeTransform {

    constructor(private localeLoader: LocaleLoaderService) { }

    transform(value: any, format: string = 'medium') {

        if (!value) { return ''; }
        if (!format) { format = 'medium'; }

        return formatDate(value, format, this.localeLoader.getLocale());
    }
}
