import { LocaleLoaderService } from 'src/app/translations/locale-loader.service';
import { DataService } from './../../public/services/data.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  public messageHasSent = false;
  constructor(
    private dataService: DataService,
    private translate: LocaleLoaderService
  ) {
  }

  addEmail(email: {senderEmail: string, content: string, name: string }) {
    return this.dataService.create('public/send-contact', {...email, lang: this.translate.currentLang}).pipe(
      map(result => {
        return result;
      })
    );
  }

  setMessageHasSent(value: boolean){
    this.messageHasSent = true;
  }
}
