import { LocaleLoaderService } from './../../../translations/locale-loader.service';
import { takeUntil } from 'rxjs/operators';
import { getThumb, getOptimized } from '../../../common/helpers/get-thumb';
import { DomSanitizer } from '@angular/platform-browser';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  AfterViewInit,
  NgZone,
  TemplateRef,
  ViewChild,
  Inject,
  PLATFORM_ID,
  ChangeDetectorRef,
} from '@angular/core';
import { ImageItem } from '../../models/general.model';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

// import Swiper JS
import Swiper, { SwiperOptions } from 'swiper/bundle';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageGalleryComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() images: Observable<ImageItem[]>;
  @Input() countOnStart = 3;
  @Input() mode: 'link' | 'default' = 'default';
  @Input() linkTemplate: TemplateRef<any>;

  destroyed = new Subject();

  galleryImages = new BehaviorSubject<ImageItem[]>([]);
  gallerySwiper: Swiper;
  public gallerSwiperClass = Math.random().toString(36).substring(3);
  @ViewChild('swipeDom', { static: false }) swipeDom: TemplateRef<any>;

  @ViewChild('lightBox', { static: false }) lightBox: TemplateRef<any>;
  constructor(
    private sanitizer: DomSanitizer,
    private translationService: LocaleLoaderService,
    private zone: NgZone,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    @Inject(PLATFORM_ID) private platformId: any,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    this.images.pipe(takeUntil(this.destroyed)).subscribe(images => {
      this.setImages(images);
      this.cd.detectChanges();
      if (this.mode === 'default' && !isPlatformServer(this.platformId)) {
          this.setGallerySwiper();
      }
    });
  }

  ngOnDestroy() {
    this.destroyed.next(true);
    this.destroyed.complete();

    if (this.mode === 'default' && !isPlatformServer(this.platformId)) {
      this.gallerySwiper.destroy(true, true);
    }
  }


  get lang() {
    return this.translationService.currentLang;
  }


  // set images
  setImages(images: ImageItem[]) {
    this.galleryImages.next(images.sort((a, b) => b.order - a.order));
  }

  // Open Gallery
  openGallery(){
    this.appendSwipeToDom();
    const pswpElement = this.document.querySelectorAll('.pswp')[0] as HTMLElement;

    // build items array
    const items = this.galleryImages.value.map(image => {
      return {
        src: getOptimized(image.path as string),
        w: image.width ? image.width : 1024,
        h: image.height ? image.height : 768,
        title: image.title?.[this.lang]
      };
    });

    // define options (if needed)
    const options = {
      closeEl: true,
      captionEl: true,
      fullscreenEl: true,
      zoomEl: true,
      shareEl: false,
      counterEl: false,
      arrowEl: true,
      preloaderEl: true,
      maxSpreadZoom: 2,
      history: false,
      showAnimationDuration: 1,
      hideAnimationDuration: 0
    };


    const gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
    gallery.init();
  }


  // Set Gallery Swiper
  setGallerySwiper() {
    this.zone.runOutsideAngular(() => {

      const navigateEvent = (swiper: Swiper) => {
        if (swiper.activeIndex === 0 ) {
          (this.document.getElementsByClassName('swiper-button-prev')[0] as HTMLDivElement ).style.visibility = 'hidden';
          (this.document.getElementsByClassName('swiper-button-next')[0] as HTMLDivElement ).style.visibility = 'visible';
        } else if (swiper.activeIndex === swiper.slides.length - 1) {
          (this.document.getElementsByClassName('swiper-button-prev')[0] as HTMLDivElement ).style.visibility = 'visible';
          (this.document.getElementsByClassName('swiper-button-next')[0] as HTMLDivElement ).style.visibility = 'hidden';
        } else {
          (this.document.getElementsByClassName('swiper-button-prev')[0] as HTMLDivElement ).style.visibility = 'visible';
          (this.document.getElementsByClassName('swiper-button-next')[0] as HTMLDivElement ).style.visibility = 'visible';
        }
      };

      const options: SwiperOptions = {
        slidesPerView: 1,
        spaceBetween: 0,
        centeredSlides: true,
        slideToClickedSlide: true,
        loop: true,
        lazy: true,
        observer: true,
        observeParents: true,
        parallax: true,
        autoHeight: true,
        breakpoints: {
          // when window width is >= 728px
          768: {
            slidesPerView: this.countOnStart,
            spaceBetween: this.countOnStart > 1 ? 10 : 0,
          }
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          init: (swiper) => navigateEvent(swiper),
          activeIndexChange: (swiper) => navigateEvent(swiper),
        }
      };
      this.gallerySwiper = new Swiper('.gallery', options);
      this.initPhotoSwipeWitGallery('.my-gallery');
    });
  }

  // Swiper with Gallery
  initPhotoSwipeWitGallery(gallerySelector) {

    // parse slide data (url, title, size ...) from DOM elements
    // (children of gallerySelector)
    this.appendSwipeToDom();
    const parseThumbnailElements = (el) => {
      const thumbElements = el.childNodes;
      const numNodes = thumbElements.length;
      let items = [];
      let figureEl;
      let linkEl;
      let size;
      let item;

      for (let i = 0; i < numNodes; i++) {
        figureEl = thumbElements[i]; // <figure> element

        // include only element nodes
        if (figureEl.nodeType !== 1) {
          continue;
        }

        linkEl = figureEl.children[0]; // <a> element

        size = linkEl.getAttribute('data-size').split('x');

        // create slide object
        item = {
          src: linkEl.getAttribute('href'),
          w: parseInt(size[0], 10),
          h: parseInt(size[1], 10),
          title: linkEl.getAttribute('title')
        };

        if (figureEl.children.length > 1) {
          // <figcaption> content
          item.title = figureEl.children[1].innerHTML;
        }

        if (linkEl.children.length > 0) {
          // <img> thumbnail element, retrieving thumbnail url
          item.msrc = linkEl.children[0].getAttribute('src');
        }

        item.el = figureEl; // save link to element for getThumbBoundsFn
        items = [...items, item];
      }

      return items;
    };

    // find nearest parent element
    const closest = (el, fn) => {
      return el && (fn(el) ? el : closest(el.parentNode, fn));
    };

    // triggers when user clicks on thumbnail
    const onThumbnailsClick = (e) => {
      e.preventDefault ? e.preventDefault() : (e.returnValue = false);

      const eTarget = e.target || e.srcElement;

      // find root element of slide
      const clickedListItem = closest(eTarget, (el) => {
        return el.tagName && el.tagName.toUpperCase() === 'LI';
      });

      if (!clickedListItem) {
        return;
      }

      // find index of clicked item by looping through all child nodes
      // alternatively, you may define index via data- attribute
      const clickedGallery = clickedListItem.parentNode;
      const childNodes = clickedListItem.parentNode.childNodes;
      const numChildNodes = childNodes.length;

      let nodeIndex = 0;
      let index;

      for (let i = 0; i < numChildNodes; i++) {
        if (childNodes[i].nodeType !== 1) {
          continue;
        }

        if (childNodes[i] === clickedListItem) {
          index = nodeIndex;
          break;
        }
        nodeIndex++;
      }

      if (index >= 0) {
        // open PhotoSwipe if valid index found
        openPhotoSwipe(index, clickedGallery);
      }
      return false;
    };

    // parse picture index and gallery index from URL (#&pid=1&gid=2)
    const photoswipeParseHash = () =>  {
      const hash = this.window.location.hash.substring(1);
      const params: any = {};

      if (hash.length < 5) {
        return params;
      }

      const vars = hash.split('&');
      for (const varItem of vars) {
        if (!varItem) {
          continue;
        }
        const pair = varItem.split('=');
        if (pair.length < 2) {
          continue;
        }
        params[pair[0]] = pair[1];
      }

      if (params.gid) {
        params.gid = parseInt(params.gid, 10);
      }

      return params;
    };

    const openPhotoSwipe = ( index, galleryElement, disableAnimation = null, fromURL = null ) => {
      const pswpElement = this.document.querySelectorAll('.pswp')[0] as HTMLElement;
      let gallery;
      let options;
      let items;

      items = parseThumbnailElements(galleryElement);

      // #################### 3/4 define photoswipe options (if needed) ####################
      // https://photoswipe.com/documentation/options.html //
      options = {
        /* "showHideOpacity" uncomment this If dimensions of your small thumbnail don't match dimensions of large image */
        // showHideOpacity:true,
        closeEl: true,
        captionEl: true,
        fullscreenEl: true,
        zoomEl: true,
        shareEl: false,
        counterEl: false,
        arrowEl: true,
        preloaderEl: true,
        maxSpreadZoom: 2,
        history: false,
        // define gallery index (for URL)
        galleryUID: galleryElement.getAttribute('data-pswp-uid'),
        getThumbBoundsFn: (i) =>  {
          // See Options -> getThumbBoundsFn section of documentation for more info
          const thumbnail = items[i].el.getElementsByTagName('img')[0]; // find thumbnail
          const pageYScroll = this.window.pageYOffset || this.document.documentElement.scrollTop;
          const rect = thumbnail.getBoundingClientRect();

          return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
        }
      };

      // PhotoSwipe opened from URL
      if (fromURL) {
        if (options.galleryPIDs) {
          // parse real index when custom PIDs are used
          // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
          for (let j = 0; j < items.length; j++) {
            if (items[j].pid === index) {
              options.index = j;
              break;
            }
          }
        } else {
          // in URL indexes start from 1
          options.index = parseInt(index, 10) - 1;
        }
      } else {
        options.index = parseInt(index, 10);
      }

      // exit if index not found
      if (isNaN(options.index)) {
        return;
      }

      if (disableAnimation) {
        options.showAnimationDuration = 0;
      }

      // Pass data to PhotoSwipe and initialize it
      gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
      gallery.init();

      /* ########### PART 4 - EXTRA CODE  ########### */
      /* EXTRA CODE (NOT FROM photoswipe CORE) -
      1/2. UPDATE SWIPER POSITION TO THE CURRENT ZOOM_IN IMAGE (BETTER UI) */
      // photoswipe event: Gallery unbinds events
      // (triggers before closing animation)
      gallery.listen('unbindEvents', () => {
        // The index of the current photoswipe slide
        const getCurrentIndex = gallery.getCurrentIndex();
        // Update position of the slider
        this.gallerySwiper.slideTo(getCurrentIndex, 0, false);
        // 2/2. Start swiper autoplay (on close - if swiper autoplay is true)
        this.gallerySwiper.autoplay.start();
      });
      // 2/2. Extra Code (Not from photoswipe) - swiper autoplay stop when image in zoom mode (When lightbox is open) */
      gallery.listen('initialZoomIn', () =>  {
        if (this.gallerySwiper.autoplay.running){
          this.gallerySwiper.autoplay.stop();
        }
      });
    };

    // loop through all gallery elements and bind events
    const galleryElements = this.document.querySelectorAll(gallerySelector);

    for (let i = 0, l = galleryElements.length; i < l; i++) {
      galleryElements[i].setAttribute('data-pswp-uid', i + 1);
      galleryElements[i].onclick = onThumbnailsClick;
    }

    // Parse URL and open gallery if it contains #&pid=3&gid=1
    const hashData = photoswipeParseHash();
    if (hashData.pid && hashData.gid) {
      openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true, true);
    }
  }


  appendSwipeToDom() {
    const isAppended = !!this.document.querySelectorAll('.pswp').length;
    if (isAppended) {
      return;
    }
    const view = this.swipeDom.createEmbeddedView(null);
    view.detectChanges();
    const node = view.rootNodes[0];
    this.document.body.appendChild(node);
  }

  // get sanitized...
  getSanitizedImage(path: string, size: number, styled = false) {
    if (styled) {
      return this.sanitizer.bypassSecurityTrustStyle('url(' + getThumb(path, size) + ' )');
    }

    return this.sanitizer.bypassSecurityTrustUrl(getThumb(path, size));
  }

  getImage(path: string){
    return this.sanitizer.bypassSecurityTrustUrl(getOptimized(path));
  }

  getSizes(index) {
    if (!this.galleryImages.value.length) {
      return;
    }
    const {width, height} = this.galleryImages.value[index];
    return width && height ? width + 'x' + height : '1024x768';
  }
}
