import { DashBoardGuard } from './dashboard/dashboard.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { AdminComponent } from './admin.component';
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { UsersGuard } from './users/users.guard';
import { ProjectsGuard } from './projects/projects.guard';
import { BlogPostsGuard } from './blog-posts/blog-posts.guard';

const routes: Routes = [
    {
        path: '',
        component: AdminComponent,
        data : {
            title: 'Admin',
            noIndex: true
          },
        children: [
            {
                path: '',
                component: DashboardComponent,
                canActivate: [DashBoardGuard]
            },
            {
                path: 'sign-in',
                component: SignInComponent,
            },
            {
                path: 'users',
                loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
                canActivate: [UsersGuard]
            },
            {
                path: 'projects',
                loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule),
                canActivate: [ProjectsGuard]
            },
            {
                path: 'blog-posts',
                loadChildren: () => import('./blog-posts/blog-posts.module').then(m => m.BlogPostsModule),
                canActivate: [BlogPostsGuard]
            },
        ]
    },

];

export const AdminRoutingModule: ModuleWithProviders = RouterModule.forChild(routes);
