import { LocaleLoaderService } from 'src/app/translations/locale-loader.service';
import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  NgZone,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer2,
  PLATFORM_ID,
  Inject
} from '@angular/core';
import gsap from 'gsap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('logo', { static: true }) logo: ElementRef<HTMLDivElement>;
  @Input() link: string;

  @Input() theme: 'dark' | 'light' = 'dark';
  @Input() width: string;
  @Input() height: string;
  @Input() scale: string;
  @Input() reverseAnimation = new Subject();
  stopAnimation = false;
  destroyed = new Subject();
  uniqueClass = 'log_' + Math.round(Math.random() * 1000);
  over = false;
  logoMotion: any;

  hoverEvent: () => void;
  leaveEvent: () => void;
  constructor(
    private zone: NgZone,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: any,
    private translate: LocaleLoaderService
  ) { }

  ngOnInit(): void {
  }

  get urlForLogo() {
    if (this.link) {
      return this.link;
    }
    const base = this.translate.currentBase;
    return '/' + base;
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.animate();
    }
  }

  animate() {
    this.zone.runOutsideAngular(() => {
      this.logoMotion = gsap.timeline()
      .to('.' + this.uniqueClass, { duration: 0.2, skewY: '30deg', skewX : '-30deg', marginTop: '24%', scale: 0.87})
      .reverse();

      this.reverseAnimation.pipe(takeUntil(this.destroyed)).subscribe((event: boolean) => {
        this.stopAnimation = event;
      });

      this.hoverEvent = this.renderer.listen(
        this.logo.nativeElement,
        'mouseleave',
        () => this.run('leave')
      );

      this.leaveEvent = this.renderer.listen(
        this.logo.nativeElement,
        'mouseover',
        () => this.run('over')
      );
    });
  }

  ngOnDestroy() {
    this.leaveEvent = null;
    this.hoverEvent = null;
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  run(on: 'over' | 'leave'){
    if (this.stopAnimation) {
      return;
    }
    if (on === 'over' && this.over) {
      return;
    }
    this.over = on === 'over' ? true : false;

    this.logoMotion.reversed( !this.logoMotion.reversed() );

  }

}
