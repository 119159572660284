import { ContactService } from '../contact.service';
import { ChangeDetectionStrategy, Component, OnInit, AfterViewInit, OnDestroy, NgZone, PLATFORM_ID, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactPageComponent implements OnInit, AfterViewInit, OnDestroy {
  contactForm: FormGroup;
  lang: string;
  destroyed = new Subject();
  failed = new Subject<boolean>();
  sending = new Subject<boolean>();
  constructor(
    private contactService: ContactService,
    private fb: FormBuilder,
    private zone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: any,
  ) { }

  ngOnInit(): void {

    this.contactForm = this.fb.group({
      name : ['',
        [
          Validators.required,
        ]],
      email : ['',
        [
          Validators.email
        ]],
      content : ['',
        [
          Validators.required,
        ]],
    });
  }


  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      gsap.registerPlugin(ScrollTrigger);
      this.animate();
    }
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  animate() {
    this.zone.runOutsideAngular(() => {
      gsap.timeline({scrollTrigger: {trigger: '.contactTitle'}})
      .fromTo('.contactTitle .bg', {width: 0, duration: 0.2}, {width: '100%'})
      .fromTo('.contactTitle h1', {alpha: 0, duration: 0.3 }, {alpha: 1})
      .fromTo('.corpInfo', {x: '-100%', alpha: 0, duration: 0.3}, {alpha: 1, x: 0})
      .fromTo('.title2', {right: '-120%', alpha: 0, duration: 0.3}, {right: 0, alpha: 1})
      .fromTo('.contactFormInner', { y: '-100%', alpha: 0, duration: 0.2}, { y: 0, alpha: 1});
    });
  }

  submit() {
    if (!this.contactForm.valid) {
      return;
    }

    this.sending.next(true);

    const params = {
      senderEmail: this.contactForm.controls.email.value,
      content: this.contactForm.controls.content.value,
      name: this.contactForm.controls.name.value,
    };

    this.contactService.addEmail(params).pipe(
      take(1),
    ).subscribe(result => {
      this.runSent();
      this.sending.next(false);
    }, err => {
      this.sending.next(false);
      this.runFailed();
    });
  }

  runSent() {
    // if success navigate to success
    this.contactService.setMessageHasSent(true);
    this.router.navigate(['./success'], {relativeTo: this.route});
  }

  runFailed() {
    // If email sent has failed.
    this.failed.next(true);
    setTimeout(() => {
      this.failed.next(false);
    }, 5000);
  }
}
