import { ImageHandlerComponent } from './image-handler/image-handler.component';
import { AdminSharedModule } from './../../admin-shared/admin-shared.module';
import { PublicModule } from './../../../public/public.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShortersFormComponent } from './shorters-form/shorters-form.component';
import { LabelsFormComponent } from './labels-form/labels-form.component';
import { StoryFormComponent } from './story-form/story-form.component';



@NgModule({
  declarations: [
    ShortersFormComponent,
    LabelsFormComponent,
    ImageHandlerComponent,
    StoryFormComponent
  ],
  imports: [
    CommonModule,
    PublicModule,
    AdminSharedModule
  ],
  exports: [
    ShortersFormComponent,
    LabelsFormComponent,
    ImageHandlerComponent,
    StoryFormComponent
  ]
})
export class ProjectFormsModule { }
