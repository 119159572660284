import { ProjectStory } from 'src/app/public/models/projects.model';
import { LocaleLoaderService } from 'src/app/translations/locale-loader.service';
import { DataService } from './../../public/services/data.service';
import { CacheService } from './../../public/services/cache-service';
import { DomSanitizer, makeStateKey } from '@angular/platform-browser';
import { Project } from './../../public/models/projects.model';
import { Injectable } from '@angular/core';
import { getThumb } from '../../common/helpers/get-thumb';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ImageItem } from 'src/app/public/models/general.model';

const TEMP_PROJECTS = makeStateKey<any>('projectData');
const PROJECT_PAGE = makeStateKey<any>('projectPageData');
const PROJECT_PLANS = makeStateKey<any>('projectPlansData');
const PROJECT_STORIES = makeStateKey<any>('projectStories');

interface ProjectParams {
  name?: string;
  year?: number[];
  onRoad?: string[];
  location?: {lat: number, lng: number, radius: number};
}

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  path = 'public';
  constructor(
    private sanitizer: DomSanitizer,
    private translationService: LocaleLoaderService,
    private cacheService: CacheService,
    private dataService: DataService
  ) {}

  get lang() {
    return this.translationService.currentLang;
  }

  getProjectWithSlug(slug: string) {
    const tempSavedProjects = this.cacheService.getDataFromState(TEMP_PROJECTS, {});

    const project = tempSavedProjects?.[slug];
    if (project) {
      return of(project);
    }

    return this.dataService.get(this.path + '/get-project-with-slug/' + this.lang + '/' + slug).pipe(
      map(result => {
        const p = result.project;
        if (p) {
          return this.mapProject(p);
        }
        return null;
      }),
      tap( p => {
        if (p) {
          this.cacheService.setState(TEMP_PROJECTS, {...tempSavedProjects, [slug]: p});
        }
      }),
      shareReplay(1),
      catchError(err => {
        return err;
      })
    );
  }


  getProjects(length: number = 9, startPoint: number = 0, params: ProjectParams = {}, cached = true) {
    const tempProjectPage = this.cacheService.getDataFromState(PROJECT_PAGE, {});
    let uniqueKey = '';

    if (cached) {
      uniqueKey = 'PROJECT_PAGE_' + this.lang + '_' +
        String(length)  + String(startPoint) + Object.keys(params).reduce((all, current) => {
        return all + current + params[current];
      }, '');

      const tempData = tempProjectPage?.[uniqueKey];
      if (tempData) {
        return of(tempData);
      }
    }


    const requestBody: any = {
      size: length,
      offset: startPoint,
      ...params
    };

    return this.dataService.create(this.path + '/get-projects', requestBody).pipe(
      map((data: {hits: Project[], length: number, offset: number, nbHits: number}) => {
        const mappedProjects = data.hits.map(p => this.mapProject(p));
        return {projects: mappedProjects, length: data.length, offset: data.offset, total: data.nbHits};
      }),
      tap((data) => {
        if (cached) {
          this.cacheService.setState(PROJECT_PAGE, {...tempProjectPage, [uniqueKey]: data});
        }
      }),
      shareReplay(1), // for late subscribers...
    );
  }

  getProjectStories(projectID: string, length: number, after: string ) {
    const tempStories = this.cacheService.getDataFromState(PROJECT_STORIES, {});
    const uniqueKey = 'PROJECT_STORIES_' + projectID + '_' + length + '_' + after;

    const tempData = tempStories?.[uniqueKey];
    if (tempData) {
      return of(tempData);
    }

    let params: any = {size: length };

    if (after) {
      params = { ...params, startAfter: after };
    }

    return this.dataService.create(this.path + '/get-project-stories/' + projectID, params).pipe(
      map((data: ProjectStory[]) => {
        return data;
      }),
      tap((data) => {
          this.cacheService.setState(PROJECT_STORIES, {...tempData, [uniqueKey]: data});
      }),
      shareReplay(1), // for late subscribers...
    );
  }

  getProjectPlans(projectID: string) {
    const tempPlans = this.cacheService.getDataFromState(PROJECT_PLANS, {});
    const uniqueKey = 'PROJECT_PLANS_' + projectID;

    const tempData = tempPlans?.[uniqueKey];
    if (tempData) {
      return of(tempData);
    }

    return this.dataService.get(this.path + '/get-project-images/' + projectID).pipe(
      map((data: ImageItem[]) => {
        return data;
      }),
      tap((data) => {
          this.cacheService.setState(PROJECT_PLANS, {...tempPlans, [uniqueKey]: data});
      }),
      shareReplay(1), // for late subscribers...
    );
  }


  mapProject(project: Project, thumbs = false) {
    if (!project) {
      return null;
    }

    let data = {
      ...project,
      name: project.name[this.lang],
      title: project.title?.[this.lang],
      slug: project.slugs[this.lang],
      desc: project.desc[this.lang],
      summary: project.summary[this.lang],
      slogan: project?.slogan?.[this.lang],
    };

    if (thumbs) {
      data = {...data, thumb: this.getThumbMap(project.thumb as string), projectThumb: this.getThumbMap(data.projectThumb as string)};
    }

    return data;
  }


  getThumbMap(thumb: string) {
    return {
      256: this.sanitizer.bypassSecurityTrustStyle(`url(${getThumb(thumb as string, 256)})`),
      512: this.sanitizer.bypassSecurityTrustStyle(`url(${getThumb(thumb as string, 512)})`),
      1024: this.sanitizer.bypassSecurityTrustStyle(`url(${getThumb(thumb as string, 1024)})`),
      2048: this.sanitizer.bypassSecurityTrustStyle(`url(${getThumb(thumb as string, 2048)})`),
    };
  }

}


