import { Component, Input, ChangeDetectionStrategy, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class HorizontalMenuComponent implements OnInit {
  @Input() menuItems: [];
  ngOnInit() {
  }
}
