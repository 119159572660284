import { EditProjectService } from '../../edit-project.service';
import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class TagProjectImagesResolver implements Resolve<boolean> {
    constructor(
      private router: Router,
      private projectService: EditProjectService,
      private snackBar: MatSnackBar,
      ) { }


    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        const projectID = route.params.projectid;
        const imageuid = route.params.imageuid;
        // Return Data
        return this.projectService.getProjectImage(projectID, imageuid).pipe(map(result => {
            if (!result) {
                this.router.navigate(['../']);
                this.snackBar.open('Resim bulunamadı', 'ok', {duration: 3000});
            }
            return result;
        }));
    }
}
