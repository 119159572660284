import { BlogPostsService } from './../blog-posts.service';
import { getThumb } from './../../../common/helpers/get-thumb';
import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef, OnDestroy, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Subject, merge } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil, tap, debounceTime, take } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../../admin-shared/components/confirmation-dialog/confirmation-dialog.component';
import { BlogPost } from 'src/app/public/models/blog-posts.model';

@Component({
  selector: 'app-all-blog-posts',
  templateUrl: './all-blog-posts.component.html',
  styleUrls: ['./all-blog-posts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllBlogPostsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  columns: any[] = [
    {
      field: 'uid',
      string: 'ID',
      show: false,
    },
    {
      field: 'thumb',
      string: 'Kapak',
      show: true,
    },
    {
      field: 'title.tr',
      string: 'Başlık',
      show: true,
    },
    {
      field: 'status',
      string: 'Yayın Durumu',
      show: true,
    },
    {
      field: 'createdAt',
      string: 'Kayıt Tarihi',
      show: true,
    },
    {
      field: 'updatedAt',
      string: 'Son Güncelleme',
      show: false,
    },
    {
      field: 'controls',
      string: 'Aksiyonlar',
      show: true,
    },
  ];

  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<BlogPost>();
  loading = new BehaviorSubject<boolean>(null);
  destroyed = new Subject<boolean>();


  total: number;
  lastInResponse: string;
  getSearchResult = new Subject<string>();

  constructor(
    private blogPostsService: BlogPostsService,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.loading.next(true);
    this.setBlogPosts();
    this.setDisplayedColumns();
  }

  ngAfterViewInit(){
    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.destroyed)).subscribe(() => this.paginator.pageIndex = 0);

    // reset next page token on pagesize change
    merge(this.sort.sortChange, this.paginator.page)
    .pipe(
        takeUntil(this.destroyed),
        tap((val: any) => {
          this.setBlogPosts();
        })
    )
    .subscribe();

    // Observe Search
    this.getSearchResult.pipe(debounceTime(1000), takeUntil(this.destroyed)).subscribe(searchTerm => {
      this.loading.next(true);

      if (searchTerm) {
        this.blogPostsService.searchBlogPost(searchTerm).pipe(take(1)).subscribe(result => {
          this.dataSource.data = result.posts;
          this.loading.next(false);
        });
      } else {
        this.setBlogPosts(true);
      }
    });
  }

  setBlogPosts(fresh = false) {
    this.loading.next(true);

    const query = {
      size: this.paginator.pageSize ? this.paginator.pageSize : 10,
      orderBy: this.sort.active,
      orderDirection: this.sort.direction,
      startAfter: !fresh ?  this.lastInResponse : null
    };

    this.blogPostsService.getBlogPosts(query).pipe(
      takeUntil(this.destroyed)
    ).subscribe((result: {posts: BlogPost[], total: number}) => {
      const posts = result.posts;
      this.lastInResponse = posts.length < this.paginator.pageSize ? null : posts[posts.length - 1].uid;
      this.dataSource.data = posts;
      this.total = result.total;
      this.loading.next(false);
    });
  }

  setDisplayedColumns() {
    this.displayedColumns = this.columns.filter(col => col.show);
    this.displayedColumns = this.displayedColumns.map((col: any) => col.field);
  }

  showOrHideColumn(event: any, index: number) {
    this.columns[index] = {...this.columns[index], show: event.checked };
    this.setDisplayedColumns();
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  openDialog(templateRef, popupWidth: string) {
    this.dialog.open(templateRef, {
      width: popupWidth,
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.getSearchResult.next(filterValue);
  }

  removeBlogPost(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: {
        message: 'Gönderi silinecek',
        desc: 'Emin misiniz?'
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroyed)).subscribe(applied => {
      if (applied) {
        this.blogPostsService.deleteBlogPost(id).pipe(take(1)).subscribe(result => {
          if (result) {
            this.snackBar.open('Gönderi silindi', 'ok', {duration: 3000});
            this.setBlogPosts(true);
          }
        });
      }
    });
  }

  updateStatus(id: string, stat: boolean) {
    this.blogPostsService.updateStatus(id, stat).pipe(take(1)).subscribe(result => {
      if (result) {
        this.dataSource.data = this.dataSource.data.map(d => {
          if (d.uid === id) {
            return {...d, status: stat };
          }
          return d;
        });

        this.snackBar.open(stat ? 'Gönderi yayına alındı' : 'Gönderi yayından kaldırıldı', 'ok', {duration: 3000});
      }
    });
  }

  getThumbPath(path) {
    return getThumb(path, 64);
  }

  handleImageError(row: BlogPost) {
    this.dataSource.data = this.dataSource.data.map(data => {
      if (data.uid === row.uid) {
        return {...data, thumb: {...data.thumb, error: true}};
      }

      return data;
    });
  }

}
