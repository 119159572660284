<app-header [fixed]="true" [bgWhite]=true></app-header>
<div class="projects footerPadding" @fadeIn>
    <div class="projectsOuter">
        <div class="pageTitle">
            <div class="pageTitleInner">
                <h1>{{'public.projects.title' | translate }}</h1>
                <div class="titleBg"></div>
            </div>
        </div>

       <div class="pageDesc">
            <span innerHtml="{{'public.projects.desc' | translate}}"></span>
        </div>

        <div class="searchArea">
            <div class="searchOuter">
                <app-search-bar (searchAction)="applyFilter({name: $event})" [loaded]="loaded"></app-search-bar>
            </div>
            <div class="buttons">
                <app-filter-menu class="mr-1" (filterAction)="applyFilter($event)" [loaded]="loaded"></app-filter-menu>
                <app-map-button></app-map-button>
            </div>
        </div>

        <div class="projectListOuter">
            <div class="noResult" *ngIf="!(projects | async).length">
                    <mat-icon class="mr-1">warning</mat-icon>
                    {{'public.projects.noResult' | translate}}
            </div>
            <div class="projectList">
                <div @fadeIn class="projectUnit" *ngFor="let project of (projects | async); let i = index">
                    <div class="projectUnitInner" [routerLink]="getRouterLink('project/' + project.slug)">
                        <div class="projectImage">
                            <div class="shorters">
                                <p class="projectName">{{project.name}}</p>
                                <p class="yearOnRoad">
                                    <span>{{project.year}}</span> / 
                                    <ng-container [ngSwitch]="true">
                                        <ng-container *ngSwitchCase="project.onRoad === 'finished'">
                                            <span>{{'public.projects.onRoad.finished' | translate}}</span>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="project.onRoad === 'onProgress'">
                                            <span>{{'public.projects.onRoad.onProgress' | translate}}</span>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="project.onRoad === 'offPlan'">
                                            <span>{{'public.projects.onRoad.offPlan' | translate}}</span>
                                        </ng-container>
                                    </ng-container>
                                </p>
                            </div>
                            <div class="bg" [style.background-image]="project.thumb[512]"></div>
                        </div>
                        <div class="infos">
                            <p class="lead font-weight-bold pTitle">
                                <ng-container *ngIf="project?.title">{{project.title}}</ng-container>
                                <ng-container *ngIf="!project?.title">{{project.name}}</ng-container>
                            </p>
                            <p class="desc">
                                {{project.desc}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <app-more-button *ngIf="(moreEnabled | async )" @fadeIn (moreClicked)="getItems(false)"></app-more-button>
        </div>

    </div>
</div>
<app-footer></app-footer>
