<div @fadeIn>
    <h1>{{'public.magazine.title' | translate}}</h1>
    <p class="lead mb-3">{{'public.magazine.desc' | translate}}</p>
    
    <div class="postsContainer">
        <figure class="mb-3 cursor-pointer" *ngFor="let post of (posts | async)" [routerLink]="['./' +post.slug]">
            <div class="post">
                <h3>{{post.title}}</h3>
                <div class="postImage mb-3" [style.background-image]="post.thumb[512]" *ngIf="post.thumb"></div>
                <p>{{post.summary}}</p>
                <div class="date small">{{post.createdAt | localDate}}</div>
            </div>
        </figure>
    </div>
    
    <app-more-button *ngIf="(moreEnabled | async )" @fadeIn (moreClicked)="getPosts(false)"></app-more-button>
</div>

