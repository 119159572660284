import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { UsersService } from '../users.service';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';

@Component({
  selector: 'app-add-or-edit-user',
  templateUrl: './add-or-edit-user.component.html',
  styleUrls: ['./add-or-edit-user.component.scss'],
})
export class AddOrEditUserComponent implements OnInit, OnDestroy {
  userForm: FormGroup;
  onEdit: string | boolean = false;
  loading = new EventEmitter<boolean>(true);
  destroyed = new Subject<boolean>();
  constructor(
    private fb: FormBuilder,
    private userService: UsersService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.userForm = this.fb.group({
      displayName : ['',
      [
        Validators.required,
      ]],
      email : ['',
        [
          Validators.email, Validators.required
        ]],
      role : ['',
        [
          Validators.required
        ]],
      password : ['',
        [
          Validators.required,
        ]],
    });

    this.route.paramMap.subscribe( paramMap => {
      const userID = paramMap.get('userid');

      this.onEdit = userID ? userID : false;
      if (this.onEdit) {
        this.userService.getUser(this.onEdit).pipe(take(1)).subscribe(value => {
          if (!value) {
            this.snackBar.open('Üzgünüz, kullanıcı bulunamadı', 'ok', {duration: 3000});
            this.navigate();
          }
          this.userForm.removeControl('password');
          this.userForm.patchValue(value.user);
          this.loading.next(false);
        });
      } else {
        this.loading.next(false);
      }
    });
  }

  ngOnDestroy() {
    this.destroyed.next(true);
    this.destroyed.complete();
  }


  addOrEdit() {
    const userData = this.userForm.value;
    if (this.userForm.invalid) {
      return;
    }

    const method = this.onEdit ? this.userService.updateUser(this.onEdit as string, userData) : this.userService.addUser(userData);

    method.pipe(takeUntil(this.destroyed)).subscribe(data => {
      if (data) {
        this.navigate();
        this.snackBar.open(this.onEdit ? 'Kullanıcı başarıyla güncellendi' : 'Kullanıcı başarıyla eklendi', 'ok', {duration: 3000});
      }
    }, err => {
      if (err.status === 403) {
        this.snackBar.open('bUnU y@pM@mA|IYdIn!', '0-0', {duration: 3000});
      }
    });
  }

  navigate(){
    const path = this.onEdit ? '../../' : '../';
    this.router.navigate([path], {relativeTo: this.route});
  }

}
