import { AllPostsResolver } from './all-posts/all-posts.resolver';
import { SinglePostResolver } from './single-post/single-post.resolver';
import { SinglePostComponent } from './single-post/single-post.component';
import { AllPostsComponent } from './all-posts/all-posts.component';
import { MagazineComponent } from './magazine.component';
import {Routes, RouterModule} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';


const routes: Routes = [
    {
        path: '',
        component: MagazineComponent,
        children: [
            {
                path: '',
                component: AllPostsComponent,
                resolve: {pageData: AllPostsResolver},
                data : {
                    title: 'meta.magazine.title',
                    desc: 'meta.magazine.desc',
                    image: 'magazine'
                },
            },
            {
                path: ':slug',
                component: SinglePostComponent,
                resolve: {postData: SinglePostResolver},

            },
        ]
    },

];

export const MagazineRoutingModule: ModuleWithProviders = RouterModule.forChild(routes);
