import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-close-button',
  templateUrl: './close-button.component.html',
  styleUrls: ['./close-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CloseButtonComponent implements OnInit {
  @Output() closed = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit(): void {
  }

  onClose() {
    this.closed.next(true);
  }
}
