<div id="gmap" #gmap [style.height]="height" [style.width]="width"></div>

<ng-template #markerContent let-project="project">
    <div class="markerContent">
        <div class="name">{{project.name}}</div>
        <div *ngIf="address">
            <ng-container *ngFor="let str of address">
                <span>{{str}} </span>
            </ng-container>
            <div class="latLong mt-1">
                <span>{{project.location.latitude}} / </span>
                <span>{{project.location.longitude}}</span>
            </div>
        </div>
    </div>
</ng-template>
