import { ViewComponent } from './view.component';
import { AboutUsComponent } from './about-us/about-us.component';
import {Routes, RouterModule} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import { LandingComponent } from './landing/landing.component';
import { ViewResolver } from './view.resolver';


const routes: Routes = [
    {
        path: '',
        component: ViewComponent,
        resolve: {lang: ViewResolver},
        children: [
            {
                path: '',
                component: LandingComponent,
                data : {
                  title: 'meta.home.title',
                  desc: 'meta.home.desc',
                  image: 'home'
                },
            },
            {
                path: 'projects',
                loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule),
            },
            {
                path: 'project',
                loadChildren: () => import('./projects/single-project/single-project.module').then(m => m.SingleProjectModule),
            },
            {
                path: 'contact',
                loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule),
            },
            {
                path: 'about-us',
                component: AboutUsComponent,
                data : {
                    title: 'meta.aboutUs.title',
                    desc: 'meta.aboutUs.desc',
                    image: 'aboutUs'
                },
            },
            {
                path: 'magazine',
                loadChildren: () => import('./magazine/magazine.module').then(m => m.MagazineModule),
            },
        ]
    },
];

export const ViewRoutingModule: ModuleWithProviders = RouterModule.forChild(routes);
