import { AllProjectsComponent } from './all-projects/all-projects.component';
import { ProjectsComponent } from './projects.component';
import { MapComponent } from './map/map.component';
import {Routes, RouterModule} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import { AllProjectsResolver } from './all-projects/all-projects.resolver';


const routes: Routes = [
    {
        path: '',
        component: ProjectsComponent,
        children: [
            {
                path: '',
                component: AllProjectsComponent,
                resolve: {pageData: AllProjectsResolver},
                data : {
                    title: 'meta.projects.title',
                    desc: 'meta.projects.desc',
                    image: 'projects'
                },
            },
            {
                path: 'map',
                component: MapComponent,
                data : {
                    title: 'meta.projects.map.title',
                    desc: 'meta.projects.map.desc',
                    image: 'projects'
                },
            },
        ]
    },

];

export const ProjectsRoutingModule: ModuleWithProviders = RouterModule.forChild(routes);
