<h4 class="mb-1">Blog Yönetimi</h4>
<div class="topButtons">
    <button mat-raised-button [routerLink]="['../add']">
        <mat-icon>add</mat-icon>
        {{ 'Yeni Gönderi' | translate }}
    </button>
    <button class="openColumnSelector ml-1" mat-raised-button (click)="openDialog(columnSelectorTemplate, '250px')">
        <mat-icon>view_column</mat-icon>
        {{ 'Kolonlar' | translate }}
    </button>
</div>


<ng-template #columnSelectorTemplate>
    <div><strong>{{ 'Kolonlar' | translate }}</strong></div>
    <mat-slide-toggle class="w-100" *ngFor="let col of columns; let i = index" color="primary" [checked]="col.show"  (change)="showOrHideColumn($event, i)">
        {{ col.string | translate }}
    </mat-slide-toggle>

    <div mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>{{ 'kapat' | translate }}</button>
    </div>
</ng-template>

<mat-form-field>
    <mat-label>{{ 'Gönderi başlığı ile ara' | translate }}</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="{{ 'Gönderi başlığı ile ara' | translate }}" #input>
</mat-form-field>

<div class="dataTable">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="createdAt" matSortDirection="desc" matSortDisableClear>
        <ng-container *ngFor="let column of columns; let i = index; " [matColumnDef]="column.field">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="column.field === 'controls' || column.field === 'thumb'">
                {{ column.string }}
            </th>
            <td mat-cell *matCellDef="let row" [ngSwitch]="column.field">
                <ng-container *ngSwitchCase="'thumb'">
                    <div class="imageContainer">
                        <img *ngIf="row.thumb && !row.thumb?.error" [src]="getThumbPath(row.thumb.path)" width="64" (error)="handleImageError(row)">
                        <img *ngIf="!row.thumb" src="/assets/images/noimage_64.jpg" width="64">
                        <div class="flex-center" *ngIf="row.thumb?.error">
                            <app-dot-pulse></app-dot-pulse>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'uid'">
                    {{row[column.field]}}
                </ng-container>
                <ng-container *ngSwitchCase="'title.tr'">
                        {{row.title.tr}}
                </ng-container>
                <ng-container *ngSwitchCase="'status'">
                    <div class="text-center" [style.color]="row[column.field] ? 'green' : 'red'">
                        <mat-icon>fiber_manual_record</mat-icon>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'createdAt'">
                    {{row[column.field] | localDate}}
                </ng-container>
                <ng-container *ngSwitchCase="'updatedAt'">
                    {{row[column.field] | localDate}}
                </ng-container>
                <ng-container *ngSwitchCase="'controls'">
                    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="updateStatus(row.uid, !row.status)">
                            <span *ngIf="row.status">Gönderiyi yayından kaldır</span>
                            <span *ngIf="!row.status">Gönderiyi yayına al</span>
                          </button>
                        <button mat-menu-item (click)="removeBlogPost(row.uid)">
                          <span>Gönderiyi sil</span>
                        </button>
                      </mat-menu>
                </ng-container>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['../edit', row.uid]"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="9999" *ngIf="(loading | async) === false">
                <div class="tableWarn">
                    {{ 'Sonuç bulunamadı' | translate }}
                </div>
            </td>
        </tr>
    </table>

    <div class="tableWarn loading" *ngIf="(loading | async)"><mat-progress-bar mode="indeterminate"></mat-progress-bar></div>

</div>

<mat-paginator [length]="total" [pageSize]="10" [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
