import { Component,
  HostListener, ElementRef, Input, Optional, Host, SkipSelf, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, AbstractControl, ControlContainer } from '@angular/forms';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'app-image-upload-input',
  templateUrl: './image-upload-input.component.html',
  styleUrls: ['./image-upload-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR, // For Form Group Access
      useExisting: ImageUploadInputComponent,
      multi: true
    }
  ]
})
export class ImageUploadInputComponent implements ControlValueAccessor, OnInit {
  file: File | null = null;
  currentImage = '/assets/images/noimage_256.jpg';
  imageStyle: SafeStyle;
  @Input() formControlName: string; // for Access to FormControl
  @Input() bgValue: string; // for Access to FormControl
  @Input() mode: 'rounded' | 'cover' = 'rounded';
  control: AbstractControl; // for Access to FormControl

  onChange = (file: any) => {};

  constructor(
    private host: ElementRef<HTMLInputElement>,
    @Optional() @Host() @SkipSelf()
    private controlContainer: ControlContainer,
    private changeDetector: ChangeDetectorRef,
    private sanitizer: DomSanitizer
    ) { }

  ngOnInit() {
    // Access to FormControl
    if (this.controlContainer) {
      if (this.formControlName) {
        this.control = this.controlContainer.control.get(this.formControlName);
      } else {
        console.warn('Missing FormControlName directive from host element of the component');
      }
    } else {
      console.warn('Can\'t find parent FormGroup directive');
    }

    if (this.bgValue) {
      this.imageStyle = `url(${this.bgValue})`;
    } else {
      this.imageStyle = `url(${this.currentImage})`;
    }
  }

  @HostListener('change', ['$event.target.files']) emitFiles( event: FileList ) {
    this.goOn(event);
  }

  writeValue(obj: any): void {
    // clear file input
    this.host.nativeElement.value = '';
    this.file = null;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  onFileDropped(event) {
   this.goOn(event);
  }

  goOn(event: any) {
    const file = event && event.item(0);
    this.onChange(file);
    this.file = file;

    // Change Background Image
    if (!this.control.errors && this.file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.currentImage = reader.result.toString();
        this.imageStyle = this.sanitizer.bypassSecurityTrustStyle(`url(${reader.result.toString()})`);
        this.changeDetector.detectChanges();
      };
    } else {
      this.currentImage = '/assets/images/noimage_256.jpg';
      this.imageStyle = 'url("/assets/images/noimage_256.jpg")';
    }
  }
}
