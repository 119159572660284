import { fadeIn } from './../../../../../public/animations';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { takeUntil, take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { EditProjectService } from './../../edit-project.service';
import { ProjectStory } from './../../../../../public/models/projects.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { getThumb } from 'src/app/common/helpers/get-thumb';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/admin/admin-shared/components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-all-stories',
  templateUrl: './all-stories.component.html',
  styleUrls: ['./all-stories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeIn
  ]
})
export class AllStoriesComponent implements OnInit, OnDestroy {
  stories = new BehaviorSubject<ProjectStory[]>([]);
  projectID: string;
  loading = new BehaviorSubject<boolean>(true);
  destroyed = new Subject();
  constructor(
    private projectService: EditProjectService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.projectID = this.route.snapshot.params.projectid;
    this.projectService.getProjectStories(this.projectID).pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.stories.next(result.stories.map(s => this.mapProjectStory(s)));
      this.loading.next(false);
    });
  }

  mapProjectStory(story: ProjectStory) {
    if (!story) {
      return null;
    }

    return {
      ...story,
      thumb: story?.image ? this.getThumbMap(story.image.path as string) : null
    };
  }

  getThumbMap(thumb: string) {
    return {
      256: this.sanitizer.bypassSecurityTrustStyle(`url(${getThumb(thumb as string, 256)})`),
      512: this.sanitizer.bypassSecurityTrustStyle(`url(${getThumb(thumb as string, 512)})`),
      1024: this.sanitizer.bypassSecurityTrustStyle(`url(${getThumb(thumb as string, 1024)})`),
      2048: this.sanitizer.bypassSecurityTrustStyle(`url(${getThumb(thumb as string, 2048)})`),
    };
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  removeStory(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: {
        message: 'Hikaye silinecek',
        desc: 'Emin misiniz?'
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroyed)).subscribe(applied => {
      if (applied) {
        this.projectService.removeProjectStory(id).pipe(take(1)).subscribe(result => {
          if (result) {
            this.stories.next(this.stories.value.filter(s => s.uid !== id));
            this.snackBar.open('Hikaye silindi', 'ok', {duration: 3000});
          }
        });
      }
    });
  }

}
