import { MagazineService } from './../magazine.service';
import { BlogPost } from 'src/app/public/models/blog-posts.model';
import { ActivatedRoute } from '@angular/router';
import { fadeIn } from './../../../public/animations';
import { ChangeDetectionStrategy, Component, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-all-posts',
  templateUrl: './all-posts.component.html',
  styleUrls: ['./all-posts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeIn
  ]
})
export class AllPostsComponent implements OnInit, OnDestroy {
  destroyed = new Subject();
  posts = new BehaviorSubject<BlogPost[]>([]);
  moreEnabled = new BehaviorSubject<boolean>(false);
  itemPerPage = 6;
  constructor(
    private route: ActivatedRoute,
    private service: MagazineService
  ) { }

  ngOnInit(): void {
    this.route.data.pipe(takeUntil(this.destroyed)).subscribe((data: any) => {
      this.setPosts(data.pageData.posts, true, data.pageData.total);
    });
  }

  getPosts(fresh = true) {
    this.service.getPosts(this.itemPerPage,  !fresh ? this.posts.value.length : 0, {}).pipe(take(1))
    .subscribe((result: {posts: BlogPost[], total: number}) => {
      this.setPosts(result.posts, fresh, result.total);
    });
  }


  setPosts(posts: BlogPost[], fresh = false, total: number) {
    const mappedPosts = posts.map(p => this.service.mapPost(p, true));
    this.posts.next(fresh ? mappedPosts : [...this.posts.value, ...mappedPosts]);
    this.moreEnabled.next((this.posts.value.length < total));
  }


  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }
}
