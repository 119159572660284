<div class="postOuter" *ngIf="(post | async); let post;">
    <h1>{{post.title}}</h1>
    <p class="lead mb-3">{{post.summary}}</p>
    <div class="postThumb mb-3" *ngIf="post.thumb" [style.background-image]="post.thumb[1024]"></div>
    <div class="content">
        <div class="outBox mb-2">
            <app-share-content [permalink]="baseUrl"></app-share-content>
        </div>
        <div>
            <div class="infos s-secondary mb-2">
                <p class="date mb-0">{{'public.magazine.createdAt' | translate}}: <strong>{{post.createdAt | localDate}}</strong></p>
                <p class="averageTime mb-0" *ngIf="readingTime">{{'public.magazine.averageReadingTime' | translate}}: 
                    <strong>
                        {{ readingTime }} 
                        <ng-container *ngIf="readingTime === 1">{{'public.magazine.minute' | translate}}</ng-container>
                        <ng-container *ngIf="readingTime > 1">{{'public.magazine.minutes' | translate}}</ng-container>
                    </strong>
                </p>
            </div>
            <p class="mb-3" [innerHTML]="post.content"></p>
        </div>
    </div>

    <div class="keepGoing mb-6" #keepGoing>
        <div class="prev">
            <div class="inn" *ngIf="( previousPost | async ); let pp" [routerLink]="getRouterLink('/magazine/' + pp.slug)">
                <div class="title">
                    <mat-icon [inline]="true">chevron_left</mat-icon>
                    <span>{{pp.title}}</span>
                </div>
            </div>
        </div>
        <div class="next">
            <div class="inn" *ngIf="( nextPost | async ); let np" [routerLink]="getRouterLink('/magazine/' + np.slug)">
                <div class="title">
                    <span>{{np.title}}</span>
                    <mat-icon [inline]="true">chevron_right</mat-icon>
                </div>
            </div>
        </div>
    </div>

</div>
