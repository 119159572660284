import { AddProjectService } from './../add-project.service';
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Component({
  selector: 'app-project-progress',
  templateUrl: './project-progress.component.html',
  styleUrls: ['./project-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectProgressComponent implements OnInit, OnDestroy {
  destroyed = new Subject();
  progress: Observable<number>;
  constructor(
    private projectService: AddProjectService
  ) { }

  ngOnInit(): void {
    this.progress = this.projectService.progress;
  }

  ngOnDestroy(){
    this.destroyed.next(true);
    this.destroyed.complete();
  }

}
