import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromErrors from '../../common/errors';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  // All tokens coming from token interceptor :)
  public baseUrl = 'https://us-central1-oncu-insaat.cloudfunctions.net/api/';
  constructor(
    private http: HttpClient
  ) { }


  get(path, httpParams: {} = {}, head: {} = {}): Observable<any> {
    return this.http.get(this.baseUrl + path, {params: httpParams, headers: new HttpHeaders(head)}).pipe(
      map(result => {
        return result;
      })
    );
  }

  create(path: string, body: any, options: any = {}): Observable<any> {
    return this.http.post(this.baseUrl + path, body, options).pipe(
      map(result => {
        return result;
      }),
    );
  }

  edit(path: string, body: any): Observable<any> {
    return this.http.patch(this.baseUrl + path, body).pipe(
      map(result => {
        return result;
      })
    );
  }

  delete(path: string, body: any): Observable<any> {
    return this.http.delete(this.baseUrl + path, body).pipe(
      map(result => {
        return result;
      })
    );
  }

  HandleError(data: any) {
    // Status based error handling
    /*if (data.status === 401 ) { // Unauthoried
      return throwError(new UnauthorizedError(data));
    } */ // it will handle by token interceptor
    if (data.status === 404 ) { // Not Found
      return throwError(new fromErrors.NotFoundError(data));
    }
    if (data.status === 406 ) { // Not Acceptable
      return throwError(new fromErrors.NotAcceptable(data));
    }
    if (data.status === 409 ) { // Conflict
      return throwError(new fromErrors.ConflictError(data));
    }
    if (data.status === 422 ) { // Unprocessable Entity
      return throwError(new fromErrors.UnprocessableEntity(data));
    }
    if (data.status === 429 ) { // Too many requests
      return throwError(new fromErrors.TooManyRequestsError(data));
    }
    return throwError(new fromErrors.AppError(data));
  }

}
