import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignInComponent implements OnInit, OnDestroy {
  signInForm: FormGroup;
  failed = new Subject<boolean>();
  destroyed = new Subject();

  constructor(
    private fb: FormBuilder,
    public authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.authService.isLoggedIn$.pipe(takeUntil(this.destroyed)).subscribe(status => {
      if (status) {
        this.router.navigate(['/admin']);
      }
    });

    this.signInForm = this.fb.group({
      email : ['',
        [
          Validators.email
        ]],
      password : ['',
        [
          Validators.required,
        ]],
    });
  }

  signIn() {
    const { email, password } = this.signInForm.value;
    this.authService.signIn(email, password);
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

}
