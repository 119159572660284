import { MatDialog } from '@angular/material/dialog';
import { ImageTagItem } from './../../../../public/models/general.model';
import { LocaleLoaderService } from 'src/app/translations/locale-loader.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit, ChangeDetectionStrategy, Inject, TemplateRef, ViewChild } from '@angular/core';
import { fadeIn } from 'src/app/public/animations';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { ProjectsService } from '../../projects.service';
import { getOptimized } from 'src/app/common/helpers/get-thumb';
import { BehaviorSubject } from 'rxjs';
import { ImageItem } from 'src/app/public/models/general.model';
import { getFileLink } from 'src/app/common/helpers/get-file';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeIn]

})
export class PlansComponent implements OnInit {
  projectID: string;
  projectName: string;
  slug: string;
  lang: string;
  plans = new BehaviorSubject<ImageItem[]>([]);
  planUrl: string;

  @ViewChild('tags', { static: false }) tagTemplate: TemplateRef<any>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute,
    private router: Router,
    private projectService: ProjectsService,
    private sanitizer: DomSanitizer,
    private translateService: LocaleLoaderService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.lang = this.translateService.currentLang;
    this.route.data.pipe(take(1)).subscribe(data => {
      this.projectID = data.projectData?.uid;
      if (!this.projectID) {
        return this.goBack();
      }
      this.projectName = data.projectData.name;
      this.slug = data.projectData.slug;

      if (data.projectData.projectPlan) {
        this.planUrl = getFileLink(data.projectData.projectPlan);
      }

      this.getPlans();
      this.document.body.style.overflow = 'hidden';
    });
  }


  getPlans() {
    this.projectService.getProjectPlans(this.projectID).pipe(take(1)).subscribe((res: ImageItem[]) => {
      this.plans.next(res.map(image => this.mapImage(image)));
    });
  }

  mapImage(image: ImageItem) {
    return {
      ...image,
      title: image.title[this.lang],
      imageTags: image.imageTags ? image.imageTags.map(t => this.mapTag(t)) : [],
      url: this.sanitizer.bypassSecurityTrustUrl(getOptimized(image.path as string))
    };
  }

  mapTag(tag: ImageTagItem){
    return {
      ...tag,
      label: tag.label?.[this.lang],
    };
  }

  goBack() {
    this.document.body.style.overflow = null;
    return this.router.navigate(['../', this.slug], {relativeTo: this.route});
  }

  getTagLabel(tag: ImageTagItem) {
    if (tag.square) {
      return tag.label + ' / ' + tag.square + ' m2';
    }
    return tag.label;
  }

  openDialog(index: number) {
    this.dialog.open(this.tagTemplate, {
      width: '600px',
      data: {
        title: this.plans.value[index].title,
        tags: this.plans.value[index].imageTags
      }
    });
  }

}
