import { fadeIn } from './../../animations';
import { Observable } from 'rxjs';
import { BreakpointObserverService } from '../../services/breakpoint-observer.service';
import { ChangeDetectionStrategy, Component, Input, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    fadeIn
  ]
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @Input() fixed = false;
  @Input() bgWhite = true;

  windowMode: Observable<string>;
  constructor(
    private breakPointObserver: BreakpointObserverService,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.windowMode = this.breakPointObserver.size$;
  }

  getLogoScale(mode: string) {
    switch (mode) {
      case 'md':
        return '0.8';
      case 'sm':
      case 'xs':
          return '0.7';
      default:
        return '1';
    }
  }

  getHeaderHeight(mode: string) {
    switch (mode) {
      case 'md':
        return '80px';
      case 'sm':
      case 'xs':
          return '70px';
      default:
        return '100px';
    }
  }



}
