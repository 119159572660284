import { Injectable } from '@angular/core';
import { Project } from '../../../public/models/projects.model';
import { ProjectsService } from '../projects.service';
import { take } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EditProjectService extends ProjectsService {
  project = new BehaviorSubject<Project>(null);
  projectError = new Subject<boolean>();
  constructor(
    http: HttpClient
  ) {
    super(http);
  }


  setProject(projectID: string) {
    return this.getProject(projectID).pipe(take(1)).subscribe(result => {
      this.setProjectSuccess(result.project);

      if (!result.project) {
        this.projectError.next(true);
      }
    }, err => {
      this.projectError.next(true);
    });
  }

  updateProjectState(project: Partial<Project>) {
    this.project.next({...this.project.value, ...project});
  }

  setProjectSuccess(project: Project) {
    this.project.next(project);
  }

  refreshProject() {
    if (!this.project.value.uid) {
      return;
    }
    this.setProject(this.project.value.uid);
  }

  destroyProject() {
    this.project.next(null);
  }
}
