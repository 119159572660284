<div class="scene">
    <div id="iPhone_12_Pro_Max__1">
        <div data-type="Group" data-name="Group 4" id="Group_4">
            <div data-type="Group" data-name="Group 5" id="Group_5">
                <div data-type="Group" data-name="Group 3" id="Group_3">
                    <div data-type="Group" data-name="Group 2" id="Group_2">
                        <svg data-type="Line" data-name="Line 1" class="Line_1" viewBox="0 0 8 22.894">
                            <path id="Line_1" d="M 0 22.8936824798584 L 0 0">
                            </path>
                        </svg>
                        <svg data-type="Boolean Group" data-name="Union 2" class="Union_2" viewBox="0 0 215.201 131.257">
                            <path id="Union_2" d="M 100.7325057983398 131.2568969726563 C 82.18800354003906 131.2568969726563 67.15440368652344 116.2242050170898 67.15440368652344 97.67970275878906 L 40.44510269165039 97.67970275878906 C 18.10799980163574 97.67970275878906 0 79.57170104980469 0 57.23369979858398 C 0 34.89660263061523 18.10799980163574 16.78860092163086 40.44510269165039 16.78860092163086 L 90.06494903564453 16.78860092163086 C 90.46433258056641 7.448644638061523 98.16210174560547 0 107.6004028320313 0 C 117.038703918457 0 124.736457824707 7.448644638061523 125.1358337402344 16.78860092163086 L 174.7548065185547 16.78860092163086 C 197.0928039550781 16.78860092163086 215.2008056640625 34.89660263061523 215.2008056640625 57.23369979858398 C 215.2008056640625 79.57170104980469 197.0928039550781 97.67970275878906 174.7548065185547 97.67970275878906 L 148.0455017089844 97.67970275878906 C 148.0455017089844 116.2242050170898 133.0128021240234 131.2568969726563 114.4682998657227 131.2568969726563 L 100.7325057983398 131.2568969726563 Z">
                            </path>
                        </svg>
                    </div>
                    <div data-type="Group" data-name="Group 1" id="Group_1">
                        <svg data-type="Ellipse" data-name="Ellipse 1" class="Ellipse_1">
                            <ellipse id="Ellipse_1" rx="19" ry="19" cx="19" cy="19">
                            </ellipse>
                        </svg>
                        <svg data-type="Ellipse" data-name="Ellipse 2" class="Ellipse_2">
                            <ellipse id="Ellipse_2" rx="19" ry="19" cx="19" cy="19">
                            </ellipse>
                        </svg>
                    </div>
                </div>
                <svg data-type="Rectangle" data-name="Rectangle 6" class="Rectangle_6">
                    <rect id="Rectangle_6" rx="23" ry="23" x="0" y="0" width="46" height="164">
                    </rect>
                </svg>
                <svg data-type="Rectangle" data-name="Rectangle 7" class="Rectangle_7">
                    <rect id="Rectangle_7" rx="22.5" ry="22.5" x="0" y="0" width="45" height="164">
                    </rect>
                </svg>
                <svg data-type="Boolean Group" data-name="Subtraction 3" class="Subtraction_3" viewBox="0 -0.001 220.348 45.788">
                    <path id="Subtraction_3" d="M 197.4546203613281 45.78660202026367 L 22.893310546875 45.78660202026367 C 16.77839469909668 45.78660202026367 11.02942276000977 43.40534973144531 6.705450057983398 39.08148956298828 C 2.381366968154907 34.75751876831055 5.750868240284035e-06 29.0084342956543 5.750868240284035e-06 22.89329528808594 C 5.750868240284035e-06 16.77810096740723 2.381366968154907 11.02887916564941 6.705450057983398 6.704684257507324 C 11.02947807312012 2.380517721176147 16.77845001220703 -0.0008989868219941854 22.893310546875 -0.0008989868219941854 L 37.54975509643555 -0.0008989868219941854 C 39.65869903564453 3.538767576217651 42.22197723388672 6.827656745910645 45.1692008972168 9.774906158447266 C 49.76247787475586 14.36729526519775 55.10969924926758 17.97249031066895 61.06231307983398 20.49029541015625 C 67.22747802734375 23.09765625 73.77561950683594 24.41971206665039 80.52481079101563 24.41971206665039 L 133.1495819091797 24.41971206665039 C 139.8990936279297 24.41971206665039 146.4469299316406 23.09765625 152.6112060546875 20.49029541015625 C 158.564697265625 17.97249031066895 163.9119262695313 14.36729526519775 168.5043182373047 9.774906158447266 C 171.4510040283203 6.828184127807617 174.0145568847656 3.53912878036499 176.1237487792969 -0.0008989868219941854 L 197.4546203613281 -0.0008989868219941854 C 203.5698089599609 -0.0008989868219941854 209.3188934326172 2.380517721176147 213.6427764892578 6.704684257507324 C 217.9666442871094 11.02873992919922 220.347900390625 16.77796173095703 220.347900390625 22.89329528808594 C 220.347900390625 29.008544921875 217.9666442871094 34.75762939453125 213.6427764892578 39.08148956298828 C 209.3189544677734 43.40534973144531 203.5698699951172 45.78660202026367 197.4546203613281 45.78660202026367 Z">
                    </path>
                </svg>
                <div data-type="Group" data-name="Group 6" id="Group_6">
                    <svg data-type="Path" data-name="Path 1" class="Path_1" viewBox="0 0 8 8">
                        <path id="Path_1" d="M 0 0">
                        </path>
                    </svg>
                </div>
                <div data-type="Group" data-name="Group 7" id="Group_7">
                    <svg data-type="Boolean Group" data-name="Subtraction 1" class="Subtraction_1" viewBox="0 -0.001 152.625 183.256">
                        <path id="Subtraction_1" d="M 102.6252288818359 183.2553100585938 L 50.00040817260742 183.2553100585938 C 43.25054550170898 183.2553100585938 36.70254516601563 181.9333343505859 30.53827095031738 179.3261108398438 C 24.58427238464355 176.8077392578125 19.23699951171875 173.2025451660156 14.64504432678223 168.6106109619141 C 10.05304431915283 164.0186767578125 6.447771549224854 158.6714324951172 3.929385423660278 152.7174072265625 C 1.322044491767883 146.5531158447266 2.176180714741349e-05 140.0049896240234 2.176180714741349e-05 133.2549133300781 L 2.176180714741349e-05 13.47570133209229 C 2.176180714741349e-05 8.900042533874512 0.6182035803794861 4.365837574005127 1.837385416030884 -0.0009122813935391605 L 30.41731643676758 -0.0009122813935391605 C 30.41783905029297 10.20358753204346 34.40202331542969 19.80735969543457 41.63695526123047 27.04229164123535 C 48.87190628051758 34.27724838256836 58.47592926025391 38.26169967651367 68.67990875244141 38.26169967651367 L 83.94570159912109 38.26169967651367 C 105.0437698364258 38.26169967651367 122.2083206176758 21.0971565246582 122.2083206176758 -0.0009122813935391605 L 150.7873840332031 -0.0009122813935391605 C 152.0068817138672 4.367133140563965 152.6247253417969 8.901087760925293 152.6247253417969 13.47570133209229 L 152.6247253417969 133.2549133300781 C 152.6247253417969 140.0049743652344 151.3027496337891 146.5531158447266 148.6954956054688 152.7174072265625 C 146.1771850585938 158.6714477539063 142.572021484375 164.0187072753906 137.9801177978516 168.6106109619141 C 133.3882751464844 173.2025451660156 128.0411376953125 176.8077392578125 122.0872039794922 179.3261108398438 C 115.9230194091797 181.9333343505859 109.3750686645508 183.2553100585938 102.6252288818359 183.2553100585938 Z">
                        </path>
                    </svg>
                    <svg data-type="Polygon" data-name="Polygon 1" class="Polygon_1" viewBox="0 0 36.63 33.577">
                        <path id="Polygon_1" d="M 16.43720245361328 5.120894432067871 C 17.07839012145996 3.372275114059448 19.55149841308594 3.37227463722229 20.19268798828125 5.120893955230713 L 22.4253044128418 11.20958042144775 C 22.70795822143555 11.98041820526123 23.43299865722656 12.50021076202393 24.25377464294434 12.52043724060059 L 30.8005256652832 12.68177032470703 C 32.70447540283203 12.72868919372559 33.47146224975586 15.15835285186768 31.93952178955078 16.28989791870117 L 26.8640022277832 20.03885459899902 C 26.1808032989502 20.54349136352539 25.8908576965332 21.42460632324219 26.14089012145996 22.23633766174316 L 27.98641586303711 28.22785186767578 C 28.53954124450684 30.02357482910156 26.53534317016602 31.52190780639648 24.96951675415039 30.48328590393066 L 19.42046546936035 26.80256462097168 C 18.75045013427734 26.35813903808594 17.87944030761719 26.35813903808594 17.20942306518555 26.80256271362305 L 11.66037464141846 30.48328399658203 C 10.0945463180542 31.52190780639648 8.090351104736328 30.02357482910156 8.643474578857422 28.22785186767578 L 10.48900318145752 22.23633766174316 C 10.73903465270996 21.42460632324219 10.44909000396729 20.54348945617676 9.765889167785645 20.03885269165039 L 4.690371036529541 16.2898998260498 C 3.1584312915802 15.15835475921631 3.9254150390625 12.72869205474854 5.829365730285645 12.68177223205566 L 12.37611484527588 12.52044105529785 C 13.19689178466797 12.50021362304688 13.9219331741333 11.98042106628418 14.20458602905273 11.20958232879639 Z">
                        </path>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</div>