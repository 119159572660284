import { HttpClient } from '@angular/common/http';
import { DataService } from '../../public/services/data.service';
import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { TranslatableItem, LocationItem, ImageTagItem } from '../../public/models/general.model';
import { forkJoin, of } from 'rxjs';


interface EditRequest {
  title?: TranslatableItem;
  content?: TranslatableItem;
  summary?: TranslatableItem;
}

@Injectable({
  providedIn: 'root'
})
export class BlogPostsService extends DataService {
  path = 'blog-posts';

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }


  addBlogPost(requestBody: EditRequest, image: File) {
    return this.create('add-blog-post', requestBody).pipe(
      switchMap(result => {
        return forkJoin([of(result), of(image)]);
      }),
      switchMap(([result, img ]) => {
        // If there is an avatar wait for upload
        return forkJoin([of(result), img ? this.addImageToBlogPost(result.uid, img) : of(null)]);
      }),
    );
  }

  updateBlogPost(postID, body: EditRequest, image: File) {
    return this.edit(this.path + '/' + postID, body).pipe(
      switchMap(result => {
        return forkJoin([of(result), of(image)]);
      }),
      switchMap(([result, img ]) => {
        // If there is an avatar wait for upload
        return forkJoin([of(result), img ? this.addImageToBlogPost(postID, img) : of(null)]);
      }),
    );
  }

  getBlogPosts(body: {size: number, orderBy: string, orderDirection: string, startAfter?: string}) {
    return this.create(this.path + '/all', body).pipe(
      map(result => {
        return result;
      })
    );
  }

  searchBlogPost(searchTerm: string, length = 10) {
    return this.get(this.path + '/search', {s: searchTerm, size: length}).pipe(
      map(result => {
        return result;
      })
    );
  }

  deleteBlogPost(postID: string) {
    return this.delete(this.path + '/' + postID, {}).pipe(
      map(result => {
        return result;
      })
    );
  }

  updateStatus(id: string, stat: boolean) {
    return this.create(this.path + '/status/' + id, {status: stat}).pipe(
      map(result => {
        return result;
      })
    );
  }



  getBlogPost(postID: string) {
    return this.get(this.path + '/' + postID).pipe(
      map(result => {
        return result;
      })
    );
  }


  addImageToBlogPost(postID: string, image: File) {
    const formData = new FormData();
    formData.append('image', image, image.name);

    return this.create('add-image-to-post/' + postID, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

}
