import { LocaleLoaderService } from './../../../translations/locale-loader.service';
import { fadeIn } from '../../../public/animations';
import { ProjectsService } from './../projects.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Project } from '../../../public/models/projects.model';

import { ActivatedRoute } from '@angular/router';

interface FilterRequest {
  onRoad?: string[];
  year?: number[];
  name?: string;
}

@Component({
  selector: 'app-all-projects',
  templateUrl: './all-projects.component.html',
  styleUrls: ['./all-projects.component.scss'],
  animations: [
    fadeIn
  ]
})
export class AllProjectsComponent implements OnInit, OnDestroy {
  itemPerPage = 9;
  projects = new BehaviorSubject<Project[]>([]);
  moreEnabled = new BehaviorSubject<boolean>(true);
  subscription = new Subscription();

  loaded = new Subject<boolean>();

  filtered: FilterRequest = {};
  constructor(
    private projectsService: ProjectsService,
    private route: ActivatedRoute,
    private translate: LocaleLoaderService
  ) {}

  ngOnInit(): void {
    this.subscription.add(this.route.data.subscribe(data => {
      this.setProjects(data.pageData.projects, true, data.pageData.total);
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getRouterLink(link: string) {
    return this.translate.getRouterLink(link);
  }

  getItems(fresh = true) {
    const params = Object.keys(this.filtered).length ? this.filtered : {};
    this.projectsService.getProjects(this.itemPerPage, !fresh ? this.projects.value.length : 0, params).pipe(
      take(1),
      map(data => {
        return {
          ...data,
          projects: data.projects.map(p => {
            return {
              ...p,
              thumb: this.projectsService.getThumbMap(p.thumb as string),
              projectThumb: this.projectsService.getThumbMap(p.projectThumb as string),
            };
          })
        };
      })
    )
    .subscribe(data => {
      this.loaded.next(true);
      this.setProjects(data.projects, fresh, data.total);
    });
  }

  applyFilter(event: FilterRequest){
    this.filtered = {...this.filtered, ...event};
    this.getItems(true);
  }

  setProjects(projects: Project[], fresh = true, total: number) {
    this.projects.next(fresh ? projects : [...this.projects.value, ...projects]);
    this.moreEnabled.next((this.projects.value.length < total));
  }

}
