<app-header [fixed]="true"></app-header>
<div class="project footerPadding" *ngIf="(project | async); let project" #projectContainer [@routerFade]="( outletState | async )">
    <div class="projectBody">
        <div class="toppers" #toppers>
            <h1 class="projectTitle mb-1">{{project.title}}</h1>
            <p class="projectSlogan" *ngIf="project?.slogan">{{project.slogan}}</p>    
            <div class="imageGallery mb-5 mt-3">
                <app-image-gallery *ngIf="(images | async)?.length" [images]="images" countOnStart="3"></app-image-gallery>
            </div>
        </div>

        <div class="projectContent mb-6">
            <div class="cont1"> <!-- [ngClass]="{fixed: (makeFixed | async)}" -->
                <div class="infos mb-2">
                    <p><span>{{'public.projects.projectName' | translate}}</span> {{project.name}}</p>
                    <p><span>{{'public.projects.year' | translate}}</span> {{project.year}}</p>
                    <p>
                        <span>{{'public.projects.onRoadStatus' | translate }}</span>
                        <ng-container [ngSwitch]="true">
                            <ng-container *ngSwitchCase="project.onRoad === 'finished'">
                                {{'public.projects.onRoad.finished' | translate}}
                            </ng-container>
                            <ng-container *ngSwitchCase="project.onRoad === 'onProgress'">
                                {{'public.projects.onRoad.onProgress' | translate}}
                            </ng-container>
                            <ng-container *ngSwitchCase="project.onRoad === 'offPlan'">
                                {{'public.projects.onRoad.offPlan' | translate}}
                            </ng-container>
                        </ng-container>
                    </p>                    
                </div>

                <div>
                    <div class="plansButtonContainer" [routerLink]="[{ outlets: { subpage: ['plans'] } }]">
                        <div class="planIcon">
                            <mat-icon [inline]="true" class="color-pri">design_services</mat-icon>
                        </div>
                        <div class="planText">
                            {{'public.projects.goToPlans' | translate}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="cont2 pl-3"> 
                <div class="outBox">
                    <div class="mb-2">
                        <div *ngIf="project.yid" class="playButtonContainer" role="button" [routerLink]="[{ outlets: { subpage: ['promo'] } }]" matTooltip="{{'public.projects.showVideo' | translate}}" matTooltipPosition="above">
                            <div class="playIcon">
                                <mat-icon [inline]="true">play_circle_filled</mat-icon>
                            </div>
                        </div>
                    </div>
                    <app-share-content class="mb-1" [permalink]="baseUrl"></app-share-content>
                </div>           
                <p class="projectSummary lead mb-3">{{ project.summary}}</p>
                <p class="desc s-secondary" [innerHTML]="project.desc"></p>
            </div>

            <div class="clearfix"></div>
        </div>

        <div class="stories mb-3" *ngIf="project.stories > 0">
            <h2>{{ 'public.projects.updates' | translate }}</h2>
            <app-list-stories></app-list-stories>
        </div>



        <h2>{{ 'public.projects.onMap' | translate }}</h2>
        <div class="mapArea mb-6" #mapArea>
                <app-single-map></app-single-map>
        </div>


        <div class="keepGoing mb-6" #keepGoing>
            <div class="prev">
                <div class="inn" *ngIf="( previousProject | async ); let pp" [routerLink]="getRouterLink('/project/' + pp.slug)">
                    <div class="name">
                        <mat-icon [inline]="true">chevron_left</mat-icon>
                        <span>{{pp.name}}</span>
                    </div>
                </div>
            </div>
            <div class="next">
                <div class="inn" *ngIf="( nextProject | async ); let np" [routerLink]="getRouterLink('/project/' + np.slug)">
                    <div class="name">
                        <span>{{np.name}}</span>
                        <mat-icon [inline]="true">chevron_right</mat-icon>
                    </div>
                </div>
            </div>
        </div>

        <app-keep-contact></app-keep-contact>
    </div>
</div>


<app-footer></app-footer>
