<div class="text-center mb-1">
    <div class="text-info questionMark bounceIn">
        <div>?</div>
    </div>
    <ng-container *ngIf="data.message">
        <div class="lead mb-1">
            {{ data.message | translate }}
        </div>
    </ng-container>
    <ng-container *ngIf="data.desc">
        {{ data.desc | translate }}
    </ng-container>
</div>
<div mat-dialog-actions class="mt-3" align="center">
    <button mat-button (click)="onNoClick()">{{ 'hayır' | translate }}</button>
    <button mat-button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial>{{ 'evet' | translate }}</button>
</div>