import { isPlatformServer } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, AfterViewInit, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import { LoaderAnimationService } from './loader-animation.service';
import { Subject, Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { gsap } from 'gsap';
@Component({
  selector: 'app-loader-animation',
  templateUrl: './loader-animation.component.html',
  styleUrls: ['./loader-animation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderAnimationComponent implements OnInit, AfterViewInit, OnDestroy {
  destroyed = new Subject();
  started: Observable<boolean>;
  stopped: Observable<boolean>;
  constructor(
    private service: LoaderAnimationService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) { }

  ngOnInit(): void {
    this.started = this.service.getStartState();
    this.stopped = this.service.animationStopped;
    this.started.pipe(
      filter(val => val),
      takeUntil(this.destroyed)
    ).subscribe(() => {
      this.animate();
    });

    this.stopped.pipe(
      filter(val => val),
      takeUntil(this.destroyed)
    ).subscribe((val) => {
      this.animate('out');
    });
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  animate(state: 'in' | 'out' = 'in') {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    switch (state) {
      case 'out':
        return this.animateOut();
      default:
        return this.animateIn();
    }
  }

  animateIn() {
    gsap.fromTo('.loaderOuter', {y: '-100%', alpha: 0, duration: 1}, {y: 0, alpha: 1});
  }

  animateOut() {
  }
}
