import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, TemplateRef, ViewChild } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { EditProjectService } from '../edit-project.service';
import { takeUntil, filter, debounceTime, take } from 'rxjs/operators';
import { ImageItem } from '../../../../public/models/general.model';
import { MatDialog } from '@angular/material/dialog';
import { requiredFileTypes } from 'src/app/common/validators/required-file-types';
import { maxFileSize } from 'src/app/common/validators/max-file-size';

@Component({
  selector: 'app-update-project-images',
  templateUrl: './update-project-images.component.html',
  styleUrls: ['./update-project-images.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateProjectImagesComponent implements OnInit, OnDestroy {
  destroyed = new Subject();
  images = new BehaviorSubject<ImageItem[]>(null);
  endPoint: string;

  // actions
  deleteImage = new BehaviorSubject(null);
  updateFav = new BehaviorSubject(null);
  updateTitle = new BehaviorSubject(null);
  updateOrder = new BehaviorSubject(null);

  projectID: string;


  constructor(
    private projectService: EditProjectService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.projectService.project.pipe(takeUntil(this.destroyed)).subscribe(project => {
      if (project) {
        this.projectService.getProjectImages(project.uid).pipe(take(1)).subscribe(result => {
          this.projectID = project.uid;
          this.images.next(result.projectImages);
        });
      }
    });

    this.endPoint = this.projectService.baseUrl + 'upload-project-image';

    this.deleteImage.pipe(takeUntil(this.destroyed), filter(data => !!data))
    .subscribe(uid => {
      this.projectService.removeProjectImage(this.projectID, uid).pipe(take(1)).subscribe();
    });

    this.updateFav.pipe(takeUntil(this.destroyed), filter(data => !!data))
    .subscribe(uid => {
      this.projectService.updateProjectPrimaryImage(this.projectID, uid).pipe(take(1)).subscribe();
    });

    this.updateTitle.pipe(takeUntil(this.destroyed), debounceTime(1000), filter(data => !!data))
    .subscribe(data => {
      this.projectService.updateProjectImageTitle(this.projectID, data).pipe(take(1)).subscribe();
    });

    this.updateOrder.pipe(takeUntil(this.destroyed), debounceTime(300), filter(data => !!data))
    .subscribe(data => {
      this.projectService.updateProjectImageOrder(this.projectID, data).pipe(take(1)).subscribe();
    });
  }

  onDelete(data) {
    this.deleteImage.next(data);
  }

  onFav(data) {
    this.updateFav.next(data);
  }

  onTitle(data) {
    this.updateTitle.next(data);
  }

  onOrder(data) {
    this.updateOrder.next(data);
  }

  onCustom(event) {
    switch (event.buttonID) {
      case 'tagit':
        return this.router.navigate(['./', event.uid], {relativeTo: this.route});
      case 'pdf':
        return this.router.navigate(['./pdf'], {relativeTo: this.route});
    }
  }


  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }


}
