<div class="settingsContainer" (mouseover)="openMenu()"  (mouseleave)="closeMenu()">
    <div class="outer">
        <div class="set">
            <mat-icon [inline]="true">games</mat-icon>
        </div>
        <div class="things">
            <mat-icon [inline]="true" (click)="shuffle()">shuffle</mat-icon>
            <mat-icon [inline]="true" (click)="refresh()">refresh</mat-icon>
        </div>
    </div>
</div>