<app-image-handler
    *ngIf="(images | async)"
    [images]="(images | async)"
    [endPoint]="endPoint"
    [fields]="{projectID: projectID}"
    (imageDeleted)="onDelete($event)"
    (favChanged)="onFav($event)"
    (titleChanged)="onTitle($event)"
    (orderChanged)="onOrder($event)"
    [customTopButtons]="[{buttonID: 'video', label: 'Video'}]"
    (customTopClicked)="onCustom($event)"
></app-image-handler>


<ng-template #videoFormDialog>
    <mat-form-field appearance="fill">
        <mat-label>Youtube Video ID</mat-label>
        <input matInput placeholder="Youtube Video ID" #yidInput [value]="yid">
        <mat-hint>Video linkinizde "watch?v="'den sonraki benzersiz id'niz bulunur</mat-hint>
    </mat-form-field>

    <div mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>{{ 'kapat' | translate }}</button>
        <button mat-button mat-raised-button color="primary" (click)="updateVideo(yidInput.value)" >{{ 'Güncelle' | translate }}</button>
    </div>
</ng-template>