<h4 class="mb-0"><strong>Merhaba {{(user | async)?.claims.name}}, </strong></h4>
<p>Yönetim paneline hoşgeldin</p>

<div class="bigMenu">
    <div class="menuItem" [routerLink]="['./users']" *ngIf="(user | async)?.claims?.role === 'admin'">
        <div class="menuItemInner">
            <img src="/assets/images/admin/userSettings.svg">
            <div class="menuLabel">
                Kullanıcılar
            </div>
        </div>
    </div>
    <div class="menuItem" [routerLink]="['./projects']" *ngIf="['admin', 'manager'].includes((user | async)?.claims?.role)">
        <div class="menuItemInner">
            <img src="/assets/images/admin/projectSettings.svg">
            <div class="menuLabel">
                Projeler
            </div>
        </div>
    </div>
    <div class="menuItem" [routerLink]="['./blog-posts']" *ngIf="['admin', 'manager', 'editor'].includes((user | async)?.claims?.role)">
        <div class="menuItemInner">
            <img src="/assets/images/admin/blog.svg">
            <div class="menuLabel">
                Blog
            </div>
        </div>
    </div>
</div>

