import { AppComponent } from './app.component';
import { AppModule } from './app.module';
import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { BrowserStateInterceptor } from './interceptors/browser-state.interceptor';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserTranslateInitializerFactory } from './translations/browser-translate.initializer';
import { LocaleLoaderService } from './translations/locale-loader.service';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
     return new TranslateHttpLoader(http);
}
@NgModule({
     imports: [
            BrowserModule.withServerTransition({appId: 'oncubig'}),
            BrowserAnimationsModule,
            BrowserTransferStateModule,
            TranslateModule.forRoot({
               loader: {
                   provide: TranslateLoader,
                   useFactory: HttpLoaderFactory,
                   deps: [HttpClient]
               }
             }),
            AppModule,
     ],
     providers: [
          {
               provide: APP_INITIALIZER,
               useFactory: BrowserTranslateInitializerFactory, // browser app choosing language with that
               deps: [LocaleLoaderService, Injector],
               multi: true
          },
          /*{
               provide: HTTP_INTERCEPTORS,
               useClass: BrowserStateInterceptor,
               multi: true,
          },*/
     ],
     bootstrap: [AppComponent]
})
export class AppBrowserModule {}
