import { PdfUploadComponent } from './update-project-images/pdf-upload/pdf-upload.component';
import { TagProjectImagesResolver } from './update-project-images/tag-project-images/tag-project-images.resolver';
import { TagProjectImagesComponent } from './update-project-images/tag-project-images/tag-project-images.component';
import { UpdateProjectImagesComponent } from './update-project-images/update-project-images.component';
import { UpdateImagesComponent } from './update-images/update-images.component';
import { UpdateShortersComponent } from './update-shorters/update-shorters.component';
import { UpdateLabelsComponent } from './update-labels/update-labels.component';
import { EditProjectComponent } from './edit-project.component';
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

const routes: Routes = [
    {
        path: '',
        component: EditProjectComponent,
        children: [
            {
                path: '',
                component: UpdateLabelsComponent,
            },
            {
                path: 'shorters',
                component: UpdateShortersComponent,
            },
            {
                path: 'images',
                component: UpdateImagesComponent,
            },
            {
                path: 'project-images',
                component: UpdateProjectImagesComponent,
            },
            {
                path: 'project-images/pdf',
                component: PdfUploadComponent,
            },
            {
                path: 'project-images/:imageuid',
                component: TagProjectImagesComponent,
                resolve: {
                    imageData: TagProjectImagesResolver
                }
            },
            {
                path: 'stories',
                loadChildren: () => import('./stories/stories.module').then(m => m.StoriesModule)
            },
        ]
    },

];

export const EditProjectRoutingModule: ModuleWithProviders = RouterModule.forChild(routes);
