import { AddProjectService } from './../add-project.service';
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-labels',
  templateUrl: './labels.component.html',
  styleUrls: ['./labels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelsComponent implements OnInit, OnDestroy {
  project = new BehaviorSubject(null);
  destroyed = new Subject();
  constructor(
    private projectService: AddProjectService,
  ) { }

  ngOnInit(): void {
    this.projectService.project.pipe(takeUntil(this.destroyed)).subscribe(project => {
      this.project.next(project);
      this.projectService.setProgress(0);
    });
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  submit(data) {
    if (this.project.value) {
      this.editProject(data);
    } else {
      this.addProject(data);
    }
  }

  addProject(data) {
    this.projectService.addProject(data).pipe(take(1)).subscribe((result: any) => {
      if (result) {
        this.projectService.setProject(result.uid, 'shorters');
      }
    });
  }

  editProject(data) {
    this.projectService.updateProject(this.project.value.uid, data).pipe(take(1)).subscribe(result => {
      if (result) {
        this.projectService.updateProjectState({...data});
        this.projectService.jumpTo('shorters');
      }
    });
  }

}
