import { takeUntil, map } from 'rxjs/operators';
import { BreakpointObserverService } from '../../../public/services/breakpoint-observer.service';
import { Component,
  OnInit,
  AfterViewInit, ChangeDetectionStrategy, ViewChild, ElementRef, ChangeDetectorRef, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import {gsap} from 'gsap';
import { Subject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-project-ball',
  templateUrl: './project-ball.component.html',
  styleUrls: ['./project-ball.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectBallComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('ball', { read: ElementRef }) private ball: ElementRef<any>;

  destroyed = new Subject();
  windowMode: string;
  constructor(
    private cd: ChangeDetectorRef,
    private breakPointObserver: BreakpointObserverService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) { }
  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  ngOnInit(): void {
    this.breakPointObserver.size$.pipe(takeUntil(this.destroyed), map(size => {
      return ['xs', 'sm'].includes(size) ? 'small' : 'large';
    })).subscribe(size => {
      this.windowMode = size;
    });
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId) ) {
      this.animate();
    }
    this.cd.detach();
  }

  animate() {
    gsap.to('.ballOuter', {duration: 0.4, scale: 1, delay: 1});
  }

  ballOnHover() {
    if (this.windowMode !== 'large') {
      return;
    }
    gsap.to('.ball', {duration: 0.4, backgroundColor: '#5893d4', borderRadius: '20', color: '#fff', borderWidth: 0});
  }

  ballOnLeave() {
    if (this.windowMode !== 'large') {
      return;
    }
    gsap.to('.ball', {duration: 0.4, backgroundColor: '#fff', color: '#000', borderRadius: '50%', borderWidth: 2});
  }

  containerOnHover(event: MouseEvent) {
    if (this.windowMode !== 'large') {
      return;
    }
    const left = event.clientX - this.ball.nativeElement.getBoundingClientRect().left;
    const top = event.clientY - this.ball.nativeElement.getBoundingClientRect().top;

    gsap.to('.ball', {
      duration: 0.4,
      x: left / 6,
      y: top / 6,
    });
  }

  containerOnLeave(event: MouseEvent) {
    if (this.windowMode !== 'large') {
      return;
    }
    gsap.to('.ball', {duration: 0.4, x: 0, y: 0});
  }

}
