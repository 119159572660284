
<div class="outer">
    <div class="notFound">
        <div class="left">
            <div class="logoContainer">
                <app-logo class="dLogo" width='120px' height="'auto'"></app-logo>
            </div>
            <div class="lead mb-1"><strong>404</strong> {{'public.generals.notFound' | translate }}</div>
            <div><span innerHtml="{{'public.generals.notFoundMessage' | translate}}"></span></div>
        </div>
        <div class="right">
            <img src="/assets/images/404.svg">
        </div>
    </div>
</div>

