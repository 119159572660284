import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { EditProjectService } from '../edit-project.service';
import { takeUntil, filter, debounceTime, take } from 'rxjs/operators';
import { ImageItem } from '../../../../public/models/general.model';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-update-images',
  templateUrl: './update-images.component.html',
  styleUrls: ['./update-images.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateImagesComponent implements OnInit, OnDestroy {
  destroyed = new Subject();
  images = new BehaviorSubject<ImageItem[]>(null);
  endPoint: string;

  // actions
  deleteImage = new BehaviorSubject(null);
  updateFav = new BehaviorSubject(null);
  updateTitle = new BehaviorSubject(null);
  updateOrder = new BehaviorSubject(null);

  projectID: string;
  yid: string; // youtube video id
  @ViewChild('videoFormDialog', { static: true }) videoFormDialog: TemplateRef<any>;

  constructor(
    private projectService: EditProjectService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.projectService.project.pipe(takeUntil(this.destroyed)).subscribe(project => {
      if (project) {
        this.projectService.getShowCaseImages(project.uid).pipe(take(1)).subscribe(result => {
          this.projectID = project.uid;
          this.yid = project?.yid ? project.yid : '';
          this.images.next(result.images);
        });
      }
    });


    this.endPoint = this.projectService.baseUrl + 'upload-showcase-image';

    this.deleteImage.pipe(takeUntil(this.destroyed), filter(data => !!data))
    .subscribe(uid => {
      this.projectService.removeShowCaseImage(this.projectID, uid).pipe(take(1)).subscribe();
    });

    this.updateFav.pipe(takeUntil(this.destroyed), filter(data => !!data))
    .subscribe(uid => {
      this.projectService.updateShowCasePrimaryImage(this.projectID, uid).pipe(take(1)).subscribe();
    });

    this.updateTitle.pipe(takeUntil(this.destroyed), debounceTime(1000), filter(data => !!data))
    .subscribe(data => {
      this.projectService.updateShowCaseImageTitle(this.projectID, data).pipe(take(1)).subscribe();
    });

    this.updateOrder.pipe(takeUntil(this.destroyed), debounceTime(300), filter(data => !!data))
    .subscribe(data => {
      this.projectService.updateShowCaseImageOrder(this.projectID, data).pipe(take(1)).subscribe();
    });
  }

  onDelete(data) {
    this.deleteImage.next(data);
  }

  onFav(data) {
    this.updateFav.next(data);
  }

  onTitle(data) {
    this.updateTitle.next(data);
  }

  onOrder(data) {
    this.updateOrder.next(data);
  }

  onCustom(event) {
    switch (event.buttonID) {
      case 'video':
        this.openDialog(this.videoFormDialog, '600px');
    }
  }

  openDialog(templateRef, popupWidth: string) {
    this.dialog.open(templateRef, {
      width: popupWidth,
    });
  }

  updateVideo(videoID: string) {
    this.projectService.updateProject(this.projectID, {yid: videoID}).pipe(take(1)).subscribe(result => {
      if (result) {
        this.yid = videoID;
        this.snackBar.open('Videonuz başarıyla güncellendi', 'ok', {duration: 3000});
        this.dialog.closeAll();
      }
    }, err => {
      this.snackBar.open('Videonuz güncellenirken bir hata ile karşılaşıldı', 'ok', {duration: 3000});
    });
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }


}
