import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil, take } from 'rxjs/operators';
import { fadeIn } from '../../../../public/animations';
import { Subject } from 'rxjs';
import { Component, OnInit, ChangeDetectionStrategy, Inject, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-project-video',
  templateUrl: './project-video.component.html',
  styleUrls: ['./project-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeIn]
})
export class ProjectVideoComponent implements OnInit {
  videoID: string = null;
  slug: string;
  videoUrl: SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.data.pipe(take(1)).subscribe(data => {
      this.videoID = data.projectData?.yid;
      if (!this.videoID) {
        return this.goBack();
      }
      this.slug = data.projectData.slug;
      const url = 'https://www.youtube.com/embed/' + this.videoID + '?autoplay=1&showinfo=0&controls=3&color=white';
      this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);

      this.document.body.style.overflow = 'hidden';
    });
  }

  goBack() {
    this.document.body.style.overflow = null;
    return this.router.navigate(['../', this.slug], {relativeTo: this.route});
  }

}
