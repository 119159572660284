<app-header [fixed]="true"></app-header>
<div class="aboutUsContainer footerPadding">
    <div class="aboutUs">
        <div class="aboutUsHead t2-bottom">
            <div class="aLeft">
                <h1>Lorem Ipsum</h1>
                <p class="sech1 color-pri t2-bottom">Dolor Sit Amet</p>
                <p class="cont1 p-bottom">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
                <p class="cont2 p-bottom s-secondary">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
            </div>
            <div class="aRight" (mouseover)="animateLogo($event)" (mouseleave)="reverseLogo()" *ngIf="['xl', 'lg', 'md'].includes((windowMode | async))">
                <app-logo class="dLogo" width='70%' height="'auto'" [reverseAnimation]="onAnimation"></app-logo>
            </div>
        </div>
        <div class="fronter t2-bottom">
            <h2>We are partners with more than <span class="color-pri">150</span> great clients from 8 different countries around the globe to create more than <span class="color-pri">200</span> amazing products with our <span class="color-pri">22</span> passionate team members.</h2>
        </div>

        <div class="story">
            <h2>Our Story</h2>
            <div class="storyContainer">
                <p class="aLeft p-bottom">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    <span class="secondary p-bottom p-top">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </span>
                </p>
                <p class="aRight p-bottom s-secondary mat-h4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                </p>
            </div>
        </div>

        <div class="whatWeDo">
            <div class="wwInner">
                <div class="line1">
                    <span class="color-pri">WHAT WE DO?</span>
                    <span>YATIRIM</span>
                    <span class="brown">KAHVE</span>
                    <span>İNŞAAT</span>
                    <span>İÇ MİMARİ</span>
                </div>
                <div class="line2">
                    <span>PEYZAJ</span>
                    <span class="green">DOĞA SEVGİSİ</span>
                    <span>GİRİŞİM</span>
                    <span>FİKİR</span>
                    <span>PROJELENDİRME</span>
                </div>
            </div>
        </div>

        <div class="wwContent t2-bottom t2-top">
            <div class="wwContentInner">
                <p class="cont1 p-bottom">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
                <p class="cont2 p-bottom">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
            </div>
        </div>
    </div>

    <app-footer></app-footer>
</div>