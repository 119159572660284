<app-header [fixed]="true" [bgWhite]="false"></app-header>
<app-lang-switcher></app-lang-switcher>

<div class="landingOuter">
    <div class="landingInner" #gameContainer>
        <app-slogan *ngIf="['xl', 'lg', 'md'].includes((windowMode | async))"></app-slogan>
        <app-project-ball></app-project-ball>
        <canvas id="gameArea"></canvas>
        <app-settings-menu *ngIf="['xl', 'lg', 'md'].includes((windowMode | async))" (refreshed)="setPosition(100)" (shuffled)="setPosition(100, true)"></app-settings-menu>
    </div>
</div>