import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, AfterViewInit, PLATFORM_ID, Inject } from '@angular/core';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit, AfterViewInit {
  socials = [
    {
      icon: 'facebook',
      url: '#'
    },
    {
      icon: 'twitter',
      url: '#'
    },
    {
      icon: 'instagram',
      url: '#'
    },
  ];
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      gsap.registerPlugin(ScrollTrigger);
      this.animate();
    }
  }

  animate(){
      gsap.timeline({delay: 0.4, scrollTrigger: {trigger: '.footer'}})
      .fromTo('.footer', {alpha: 0, duration: 0.3}, {alpha: 1});
  }

}
