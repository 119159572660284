import { ProjectImagesComponent } from './project-images/project-images.component';
import { ImagesComponent } from './images/images.component';
import { ShortersComponent } from './shorters/shorters.component';
import { LabelsComponent } from './labels/labels.component';
import { AddProjectComponent } from './add-project.component';
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

const routes: Routes = [
    {
        path: '',
        component: AddProjectComponent,
        children: [
            {
                path: '',
                component: LabelsComponent,
            },
            {
                path: 'labels',
                component: LabelsComponent,
            },
            {
                path: 'shorters',
                component: ShortersComponent,
            },
            {
                path: 'images',
                component: ImagesComponent,
            },
            {
                path: 'project-images',
                component: ProjectImagesComponent,
            },
        ]
    },

];

export const AddProjectRoutingModule: ModuleWithProviders = RouterModule.forChild(routes);
