import { AdminSharedModule } from './../admin-shared/admin-shared.module';
import { PublicModule } from './../../public/public.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersComponent } from './users.component';
import { AddOrEditUserComponent } from './add-or-edit-user/add-or-edit-user.component';
import { AllUsersComponent } from './all-users/all-users.component';
import { UserRoutingModule } from './users.routing.module';



@NgModule({
  declarations: [
    UsersComponent,
    AddOrEditUserComponent,
    AllUsersComponent
  ],
  imports: [
    CommonModule,
    PublicModule,
    AdminSharedModule,
    UserRoutingModule
  ],
  providers: [
    UsersComponent
  ]
})
export class UsersModule { }
