import {Routes, RouterModule} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import { ProjectVideoComponent } from './project-video.component';


const routes: Routes = [
    {
        path: '',
        component: ProjectVideoComponent,
    },
];

export const ProjectVideoRoutingModule: ModuleWithProviders = RouterModule.forChild(routes);
