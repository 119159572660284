import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { UsersService } from '../users.service';
import { User } from '../../../public/models/user.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, merge, BehaviorSubject } from 'rxjs';
import { takeUntil, tap, take, debounceTime } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../admin-shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-all-users',
  templateUrl: './all-users.component.html',
  styleUrls: ['./all-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllUsersComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  columns: any[] = [
    {
      field: 'uid',
      string: 'ID',
      show: false,
    },
    {
      field: 'email',
      string: 'Eposta',
      show: true,
    },
    {
      field: 'displayName',
      string: 'Görünen Ad',
      show: true,
    },
    {
      field: 'role',
      string: 'Rol',
      show: true,
    },
    {
      field: 'creationTime',
      string: 'Kayıt Tarihi',
      show: false,
    },
    {
      field: 'controls',
      string: 'Aksiyonlar',
      show: true,
    },
  ];

  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<User>();
  loading = new BehaviorSubject<boolean>(null);
  destroyed = new Subject<boolean>();

  total: number;
  lastInResponse: string;

  getSearchResult = new Subject<string>();
  constructor(
    private userService: UsersService,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.loading.next(true);
    this.setUsers();
    this.setDisplayedColumns();
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.destroyed)).subscribe(() => this.paginator.pageIndex = 0);

    // reset next page token on pagesize change
    merge(this.sort.sortChange, this.paginator.page)
    .pipe(
        takeUntil(this.destroyed),
        tap((val: any) => {
          this.setUsers();
        })
    )
    .subscribe();

    // Observe Search
    this.getSearchResult.pipe(debounceTime(1000), takeUntil(this.destroyed)).subscribe(searchTerm => {
      this.loading.next(true);

      if (searchTerm) {
        this.userService.searchUser(searchTerm).pipe(take(1)).subscribe(result => {
          this.dataSource.data = result.users;
          this.loading.next(false);
        });
      } else {
        this.setUsers();
      }
    });
  }

  setUsers(fresh = false) {
    this.loading.next(true);

    const query = {
      size: this.paginator.pageSize ? this.paginator.pageSize : 10,
      orderBy: this.sort.active,
      orderDirection: this.sort.direction,
      startAfter: !fresh ?  this.lastInResponse : null
    };

    this.userService.getUsers(query).pipe(
      takeUntil(this.destroyed)
    ).subscribe((result: {users: User[], total: number}) => {
      const users = result.users;
      this.lastInResponse = users.length < this.paginator.pageSize ? null : users[users.length - 1].uid;
      this.dataSource.data = users;
      this.total = result.total;
      this.loading.next(false);
    });
  }

  setDisplayedColumns() {
    this.displayedColumns = this.columns.filter(col => col.show);
    this.displayedColumns = this.displayedColumns.map((col: any) => col.field);
  }

  showOrHideColumn(event: any, index: number) {
    this.columns[index] = {...this.columns[index], show: event.checked };
    this.setDisplayedColumns();
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  openDialog(templateRef, popupWidth: string) {
    this.dialog.open(templateRef, {
      width: popupWidth,
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.getSearchResult.next(filterValue);
  }

  sendResetPasswordLink(email: string) {
    this.userService.sendResetLink(email).pipe(take(1)).subscribe(result => {
      if (result) {
        this.snackBar.open('Şifre sıfırlama epostası gönderildi', 'ok', {duration: 3000});
      }
    });
  }

  removeUser(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: {
        message: 'Kullanıcı silinecek',
        desc: 'Emin misiniz?'
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroyed)).subscribe(applied => {
      if (applied) {
        this.userService.deleteUser(id).pipe(take(1)).subscribe(result => {
          if (result) {
            this.snackBar.open('Kullanıcı silindi', 'ok', {duration: 3000});
            this.setUsers(true);
          }
        });
      }
    });
  }
}
