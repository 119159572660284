<div class="mb-3" appDragAndDrop (FileDropped)="onFileDropped($event)">
    <div for="file-input"
        (click)="fileHandler.click()"
        [ngClass]="{ 'cover': mode === 'cover'}"
        [style.background-image]="imageStyle" 
        class="imageButton shadow-sm rounded-circle border p-3 cursor_pointer m-auto d-block"
    ></div>
    <input id="file-input" type="file" #fileHandler>
</div>
<div *ngIf="control.errors" class="text-center mb-3">
    <mat-error *ngIf="control.hasError('maxFileSize')">
        {{ 'Max dosya boyutu 5mb olmalıdır' }}
    </mat-error>
    <mat-error *ngIf="control.hasError('requiredFileTypes')">
        {{ 'Sadece resim dosyası yükleyebilirsiniz' }}
    </mat-error>
</div>
  