<div class="postForm">
    <form [formGroup]="othersForm">
        <app-image-upload-input mode="cover" formControlName="image" #fileUpload [bgValue]="thumb"></app-image-upload-input>
    </form>

    <mat-card>
        <div class="lang">
            <button mat-stroked-button [matMenuTriggerFor]="menu"><span>Dil: </span><span class="font-weight-bold">{{currentLang}}</span></button>
            <mat-menu #menu="matMenu">
                <ng-container *ngFor="let lang of langs">
                    <button mat-menu-item *ngIf="currentLang !== lang" (click)="changeLang(lang)">{{lang}}</button>
                </ng-container>
            </mat-menu>
        </div>
    
        <form [formGroup]="textForms[currentLang]">
            <mat-form-field>
                <mat-label>Başlık</mat-label>
                <input type="text" matInput formControlName="title"
                        placeholder="Ex. Teneli attık...">
                <mat-error *ngIf="!textForms[currentLang].controls.title.valid && textForms[currentLang].controls.title.touched">
                    Lütfen geçerli bir başlık girin
                </mat-error>
            </mat-form-field>
        
            <mat-form-field>
                <mat-label>Özet</mat-label>
                <textarea 
                cdkTextareaAutosize
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"
                matInput placeholder="Ex. It makes me feel because..." formControlName="summary"></textarea>
                <mat-error *ngIf="!textForms[currentLang].controls.summary.valid && textForms[currentLang].controls.summary.touched">
                    Lütfen gönderiniz için özet yazın...
                </mat-error>
            </mat-form-field>
    
            <p class="lead">İçerik</p>
            <ckeditor formControlName="content"></ckeditor>
        </form> 

        <div class="mt-3 small dates" *ngIf="mode === 'edit'">
            <div class="iconSide mr-2"><mat-icon [inline]="true" class="color-pri">schedule</mat-icon></div>
            <div class=textSide>
                <p class="mb-0">Son güncelleme tarihi: {{values.updatedAt | localDate}}</p>
                <p class="mb-0">Oluşturulma tarihi: {{values.createdAt | localDate}}</p>
            </div>
        </div>
    </mat-card>
</div>


<app-fixed-action-buttons
    actionText="{{mode === 'edit' ? 'Güncelle' : 'Ekle'}}"
    mode="single"
    cancelText="İptal et"
    (applyAction)="onSubmit()"
    (cancelAction)="onBack()"
    [disabledState]="!formsValid"
></app-fixed-action-buttons>