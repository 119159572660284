import { AuthService } from '../auth/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class BlogPostsGuard implements CanActivate {
  constructor(
        private authService: AuthService,
        private router: Router
  ) { }
    canActivate() {
        return this.authService.getCurrentUserDetails().pipe(map((details: any) => {
          const accessed = ['admin', 'manager', 'editor'].includes(details.claims?.role);

          if  (!accessed) {
            this.router.navigate(['admin']);
          }
          return accessed;
        }));
    }
}
