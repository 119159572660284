import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { LocaleLoaderService } from '../translations/locale-loader.service';
import {Location} from '@angular/common';

@Injectable()
export class ViewResolver implements Resolve<boolean> {
    constructor(
      private router: Router,
      private translate: LocaleLoaderService,
      private location: Location
      ) { }


    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        const langOnParam = route.params.lang;
        const languages = this.translate.availableLanguages.map(lang => lang.key).filter(lang => lang !== this.translate.defaultLang);
        const isValid = languages.includes(langOnParam);
        if ((langOnParam && !isValid)) {
            // skipLocationChange with navigateByUrl doesnt work expected
            // see this -> https://github.com/angular/angular/issues/16981
            this.router.navigate(['404']).then(() => this.location.replaceState(state.url));
        }

        return of(this.translate.currentLang);
    }
}
