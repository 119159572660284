<div class="topMenu" @fadeIn>
    <button [routerLink]="['./add-story']" mat-raised-button><mat-icon class="color-pri mr-1">add</mat-icon><span>{{'Yeni Hikaye' | translate }}</span></button>
</div>

<div class="storiesOuter maxMin">
    <div *ngIf="!(stories | async).length && !(loading | async);" class="text-center mt-3">Henüz hikaye eklemediniz</div>
    <div class="storiesInner">
        <div class="storyUnit mb-2" *ngFor="let story of (stories | async); let i=index;" @fadeIn>
            <div class="story" [routerLink]="['./update-story', story?.uid]">
                <div class="storyTopper">
                    <div class="bg" [style.background-image]="story.thumb ? story.thumb[512] : 'url(/assets/images/noimage_256.jpg)'"></div>
                </div>
                <div class="storyInfo">
                    <p class="lead font-weight-bold mb-0">{{story.title['tr']}}</p>
                    <p>{{story.desc['tr']}}</p>
                </div>
            </div>

            <div class="actions">
                <div class="storySettings">
                    <button class="iMenu" mat-icon-button [matMenuTriggerFor]="iMenu"><mat-icon>more_vert</mat-icon></button>
                    <mat-menu #iMenu="matMenu">
                            <button mat-menu-item (click)="removeStory(story?.uid)">{{'Hikayeyi Sil' | translate}}</button>
                            <button [routerLink]="['./update-story', story?.uid]" mat-menu-item>{{'Hikayeyi Güncelle' | translate}}</button>
                    </mat-menu>
                </div>
            </div>
        </div>
    </div>
</div>