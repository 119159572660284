import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './public/components/not-found/not-found.component';


const routes: Routes = [
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: '',
    loadChildren: () => import('./view/view.module').then(m => m.ViewModule),
  },
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () => import('./view/view.module').then(m => m.ViewModule),
      },
      {
        path: ':lang', // this is for language initalization
        loadChildren: () => import('./view/view.module').then(m => m.ViewModule),
      },
    ]
  },
  {
    path: '**',
    component: NotFoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled',
    paramsInheritanceStrategy: 'always',
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
