import { FormControl } from '@angular/forms';

export function requiredFileTypes( types: any ) {
    const validator = (control: FormControl) => {
      const file = control.value;
      if ( file ) {
        const extension = file.type.toLowerCase();
        if (!types.includes(extension)) {
          return {
            requiredFileTypes: true
          };
        }
        return null;
      }
      return null;
    };

    return validator;
}
