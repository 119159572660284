<app-image-handler
    *ngIf="(images | async)"
    [images]="(images | async)"
    [endPoint]="endPoint"
    [fields]="{projectID: projectID}"
    [customButtons]="[{buttonID: 'tagit', label: 'Etiketle'}]"
    [customTopButtons]="[{buttonID: 'pdf', label: 'PDF'}]"
    (imageDeleted)="onDelete($event)"
    (favChanged)="onFav($event)"
    (titleChanged)="onTitle($event)"
    (orderChanged)="onOrder($event)"
    (customClicked)="onCustom($event)"
    (customTopClicked)="onCustom($event)"
></app-image-handler>
