import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlogPostFormComponent } from './blog-post-form/blog-post-form.component';
import { AdminSharedModule } from '../../admin-shared/admin-shared.module';
import { PublicModule } from 'src/app/public/public.module';



@NgModule({
  declarations: [BlogPostFormComponent],
  imports: [
    CommonModule,
    PublicModule,
    AdminSharedModule
  ],
  exports: [
    BlogPostFormComponent,
  ]
})
export class BlogFormsModule { }
