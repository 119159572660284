import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-fixed-form-buttons',
  templateUrl: './fixed-form-buttons.component.html',
  styleUrls: ['./fixed-form-buttons.component.scss']
})
export class FixedFormButtonsComponent implements OnInit {
  @Input() actionText: string;
  @Input() form: FormGroup;
  @Output() formAction = new EventEmitter<boolean>();
  @Output() cancelAction = new EventEmitter<boolean>();
  @Input() parent: string;
  @Input() classes: string[] = ['maxMid'];

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute
  ) { }

  ngOnInit() {
  }

  runAction() {
    this.formAction.emit(true);
  }

  cancel(): void {
    this.router.navigate(['../' + this.parent + ''], { relativeTo: this.activeRoute.parent });
    this.cancelAction.emit(true);
  }

}
