import { AddProjectService } from './add-project.service';
import { ProjectProgressComponent } from './project-progress/project-progress.component';
import { ImagesComponent } from './images/images.component';
import { ProjectImagesComponent } from './project-images/project-images.component';
import { LabelsComponent } from './labels/labels.component';
import { ShortersComponent } from './shorters/shorters.component';
import { AddProjectComponent } from './add-project.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicModule } from '../../../public/public.module';
import { AdminSharedModule } from '../../admin-shared/admin-shared.module';
import { ProjectFormsModule } from '../project-forms/project-forms.module';
import { AddProjectRoutingModule } from './add-project.routing.module';

@NgModule({
  declarations: [
    AddProjectComponent,
    ShortersComponent,
    LabelsComponent,
    ProjectImagesComponent,
    ImagesComponent,
    ProjectProgressComponent
  ],
  imports: [
    CommonModule,
    CommonModule,
    PublicModule,
    AdminSharedModule,
    ProjectFormsModule,
    AddProjectRoutingModule,
  ],
  providers: [
    AddProjectService
  ]
})
export class AddProjectModule { }
