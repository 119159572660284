<div class="plansOverlay" aria-hidden="true">
    <div class="scrollArea" @fadeIn>
        <div class="buttons">
            <div class="buttonsInner">
                <a *ngIf="planUrl" class="pdfButton mr-1" target="_blank" [href]="planUrl" role="button" title="{{'public.generals.download' | translate}}" matTooltip="{{'public.generals.download' | translate}}" matTooltipPosition="left">
                    <mat-icon>file_download</mat-icon>
                </a>
                <app-close-button (closed)="goBack()"></app-close-button>
            </div>
        </div>

        <div class="plansContainer">
            <div class="titles mb-5">
                <h1 class="mb-0">{{projectName}}</h1>
                <p class="lead">{{'public.projects.plans' | translate }}</p>
            </div>
    
            <div class="plans">
                <div class="planOuter mb-5" *ngFor="let plan of (plans | async ); let i=index">
                    <h3 *ngIf="plan.title">{{plan.title}}</h3>
                    <div class="plan">
                        <img class="planImage" [src]="plan.url" [width]="plan.width" [height]="plan.height">
                        <div class="tagContainer" *ngIf="plan?.imageTags; let tags">
                            <div class="dotContainer" *ngFor="let tag of tags" [style.left]="tag.x + '%'" [style.top]="tag.y + '%'" >
                                <div class="dot" [matTooltip]="getTagLabel(tag)" matTooltipPosition="above">
                                    <div class="dotText">
                                        {{tag.indicator}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="plan?.imageTags.length" class="tagIcon cursor-pointer" matTooltip="{{'public.projects.showTags' | translate}}" matTooltipPosition="left" (click)="openDialog(i)">
                            <mat-icon>segment</mat-icon>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<ng-template #tags let-data>
    <div class="tagList">
        <app-close-button mat-dialog-close></app-close-button>
        <div *ngFor="let tag of data.tags" class="mb-1">
           <strong> {{tag.indicator}}</strong> {{tag.label}} 
           <ng-container *ngIf="tag.square">({{tag.square}} m2)</ng-container>
        </div>
    </div>
</ng-template>

