import { LocaleLoaderService } from 'src/app/translations/locale-loader.service';
import { AfterViewInit, ChangeDetectionStrategy, Component, NgZone, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import {gsap, Sine, Power1} from 'gsap';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent implements OnInit, AfterViewInit {
  menuMotion: any;
  constructor(
    private zone: NgZone,
    private translate: LocaleLoaderService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) { }

  ngOnInit(): void {
  }


  getRouterLink(link: string) {
    return this.translate.getRouterLink(link);
  }

  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      this.animate();
    });
  }

  animate() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.menuMotion = gsap.timeline()
    .to('.menu', { duration: 0.2, autoAlpha: 1}, 0)
    .to('.menuText .opened', {duration: 0.2, y: -30, ease: Power1.easeIn}, 0)
    .to('.menuText .closed', {duration: 0.2, y: -24, color: '#000', ease: Power1.easeIn}, 0)
    .to('.menuOuter', {duration: 0.2, backgroundColor: '#f3f3f3', ease: Power1.easeIn}, 0)
    .to('.moreDots .dot2', {duration: 0.2, x: -12, ease: Power1.easeIn}, 0)
    .to('.moreDots .dot3', {duration: 0.2, x: -24, ease: Power1.easeIn}, 0)
    .to('.moreDots div', {duration: 0.2, backgroundColor: '#ca0000', ease: Power1.easeIn}, 0)
    .to('.navigation li', {duration: 0.2, x: 0, stagger: {each: 0.2, ease: Sine.easeInOut}}, 0)
    .to('.navigation li', {duration: 0.2, marginBottom: 40, ease: Power1.easeInOut, delay: 0.8}, 0)
    .reverse();
  }

  toggleMenu() {
    if (!this.menuMotion) {
      return;
    }
    this.zone.runOutsideAngular(() => {
      this.menuMotion.reversed( !this.menuMotion.reversed() );
    });
  }
}
