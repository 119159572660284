import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Project } from 'src/app/public/models/projects.model';
import { EditProjectService } from './../../edit-project.service';
import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';
import { getFileLink } from 'src/app/common/helpers/get-file';
import { ConfirmationDialogComponent } from 'src/app/admin/admin-shared/components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-pdf-upload',
  templateUrl: './pdf-upload.component.html',
  styleUrls: ['./pdf-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfUploadComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('uploaderDialog', { static: false }) uploaderDialog: TemplateRef<any>;
  destroyed = new Subject();

  uploading = new BehaviorSubject<boolean>(false);
  uploadProgress = new BehaviorSubject<number>(0);
  project = new BehaviorSubject<Project>(null);
  constructor(
    private dialog: MatDialog,
    private projectService: EditProjectService,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.projectService.project.pipe(takeUntil(this.destroyed)).subscribe(project => {
      if (project) {
        this.project.next(project);
      }
    });
  }

  ngAfterViewInit(){
  }

  openDialog() {
    this.dialog.open(this.uploaderDialog, {
      width: '600px',
    });
  }

  onPdfSelect(files: FileList) {
    const fileForUpload = files && files.item(0);
    this.startUploading(fileForUpload);
  }


  startUploading(fileForUpload: File) {
    this.uploading.next(true);
    this.projectService.uploadProjectPlan(this.project.value.uid, fileForUpload).pipe(takeUntil(this.destroyed))
    .subscribe((event) => {
      if (event.type === HttpEventType.UploadProgress) {
        this.uploadProgress.next(Math.ceil(event.loaded / event.total * 100));
      } else if (event.type === HttpEventType.Response) {
        this.uploading.next(false);
        this.uploadProgress.next(0);
        this.dialog.closeAll();
        this.projectService.updateProjectState({projectPlan: event.body.file});
        this.snackBar.open('Pdf başarıyla yüklendi', 'ok', {duration: 3000});
      }
    }, err => {
      this.uploading.next(false);
      this.uploadProgress.next(0);
      this.snackBar.open('Pdf yüklenirken bir hata ile karşılaşıldı', 'ok', {duration: 3000});
    });
  }

  removePlan() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: {
        message: 'Pdf silinecek',
        desc: 'Emin misiniz?'
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroyed)).subscribe(applied => {
      if (applied) {
        this.projectService.removeProjectPlan(this.project.value.uid).pipe(take(1)).subscribe(result => {
          this.snackBar.open('Pdf kaldırıldı', 'ok', {duration: 3000});
          this.projectService.updateProjectState({projectPlan: null});
        }, err => {
          this.snackBar.open('Pdf silinirken bir hata ile karşılaşıldı', 'ok', {duration: 3000});
        });
      }
    });
  }

  get fileUrl() {
    return getFileLink(this.project?.value.projectPlan);
  }

  goBack() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }
  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

}
