import { Subject } from 'rxjs';
import { ChangeDetectionStrategy, Component, OnInit, AfterViewInit, NgZone, PLATFORM_ID, Inject, OnDestroy } from '@angular/core';
import {gsap, Sine, TimelineMax} from 'gsap';
import { isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-slogan',
  templateUrl: './slogan.component.html',
  styleUrls: ['./slogan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SloganComponent implements OnInit, AfterViewInit, OnDestroy {
  destroyed = new Subject();
  animateThis = true;
  constructor(
    private zone: NgZone,
    @Inject(PLATFORM_ID) private platformId: any,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (isPlatformServer(this.platformId) && this.animateThis) {
      return;
    }
    this.animate();
  }

  ngOnDestroy(): void {
    this.animateThis = false;
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  animate() {
    this.zone.runOutsideAngular(() => {
      const timeline1 = gsap.timeline({delay: 1});
      timeline1
      .to('.text0 .bg', {duration: 0.2, width: '100%', ease: Sine.easeInOut})
      .to('.text0 span', {duration: 0.2, opacity: 1})
      .to('.text0 .bg', {duration: 0.2, width: 0, ease: Sine.easeInOut});

      const timeline2 = new TimelineMax({delay: 2, repeat: -1, repeatDelay: 2, repeatRefresh: false});
      timeline2
      .to('.secondary .bg', {duration: 0.2, width: '100%', ease: Sine.easeInOut})
      .to('.text1 span', {duration: 0.2, opacity: 1})
      .to('.secondary .bg', {duration: 0.2, width: 0, ease: Sine.easeInOut})

      .to('.secondary .bg', {duration: 0.2, delay: 2, width: '100%', ease: Sine.easeInOut})
      .to('.text1 span', {duration: 0.2, opacity: 0})
      .to('.text2 span', {duration: 0.2, opacity: 1})
      .to('.secondary .bg', {duration: 0.2, width: 0, ease: Sine.easeInOut})

      .to('.secondary .bg', {duration: 0.2, delay: 2, width: '100%', ease: Sine.easeInOut})
      .to('.text2 span', {duration: 0.2, opacity: 0})
      .to('.text3 span', {duration: 0.2, opacity: 1})
      .to('.secondary .bg', {duration: 0.2, width: 0, ease: Sine.easeInOut});
    });
  }

}
