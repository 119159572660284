<div *ngIf="!(loading | async )">
    <h4 class="mb-0">
        <ng-container *ngIf="!onEdit">
            Yeni Kullanıcı
        </ng-container>
        <ng-container *ngIf="onEdit">
            Kullanıcıyı Güncelle
        </ng-container>
    </h4>
    
    <div class="formOuter">
        <mat-card class="formUnit">
            <mat-card-content>
                <form [formGroup]="userForm">
                    <mat-form-field>
                        <mat-label>Görünen Ad</mat-label>
                        <input type="text" matInput formControlName="displayName"
                                placeholder="Ex. Arya Stark">
                        <mat-error *ngIf="!userForm.controls.displayName.valid && userForm.controls.displayName.touched">
                            Lütfen geçerli bir isim girin
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Email</mat-label>
                        <input type="email" matInput formControlName="email"
                                placeholder="Ex. pat@example.com">
                        <mat-error *ngIf="!userForm.controls.email.valid && userForm.controls.email.touched">
                            Lütfen geçerli bir eposta adresi girin
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field *ngIf="!onEdit">
                        <mat-label>Şifre</mat-label>
                        <input type="text" matInput formControlName="password"
                                placeholder="******">
                        <mat-error *ngIf="!userForm.controls.password.valid && userForm.controls.password.touched">
                            Şifreye ihtiyacımız var
                        </mat-error>
                    </mat-form-field>
    
                    <mat-form-field>
                        <mat-label>Kullanıcı Rolü</mat-label>
                        <mat-select formControlName="role">
                            <mat-option value="admin">Admin</mat-option>
                            <mat-option value="manager">Manager</mat-option>
                            <mat-option value="editor">Editor</mat-option>
                        </mat-select>
                        <mat-hint *ngIf="userForm.controls.role.value === 'admin'">
                            Admin rolüne sahip kullanıcılar en geniş ekleme/düzenleme yetkisine sahiptir
                        </mat-hint>
                        <mat-hint *ngIf="userForm.controls.role.value === 'manager'">
                            Manager rolüne sahip kullanıcılar projeleri, blog gönderilerini ekleme/düzenleme yetkisine sahiptir
                        </mat-hint>
                        <mat-hint *ngIf="userForm.controls.role.value === 'editor'">
                            Editor rolüne sahip kullanıcılar blog gönderilerini ekleme/düzenleme yetkisine sahptir
                        </mat-hint>
                        <mat-error *ngIf="!userForm.controls.role.valid && userForm.controls.role.touched">
                            Kullanıcı rolü seçmelisiniz
                        </mat-error>
                    </mat-form-field>
                </form>
            </mat-card-content>
        </mat-card>
    </div>    

    <app-fixed-form-buttons [form]="userForm" (formAction)="addOrEdit()" [parent]="'users'" [actionText]="onEdit ? 'Güncelle' :  'Ekle'"></app-fixed-form-buttons>
</div>



