import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProjectsService } from '../projects.service';

@Injectable()
export class AllProjectsResolver implements Resolve<boolean> {
    constructor(
      private projectsService: ProjectsService
    ) { }


    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        // Return Data
        return this.projectsService.getProjects(9, null).pipe(
            map((data) => {
              return {
                ...data,
                projects: data.projects.map(p => {
                  return {
                    ...p,
                    thumb: this.projectsService.getThumbMap(p.thumb as string),
                    projectThumb: this.projectsService.getThumbMap(p.projectThumb as string),
                  };
                })
              };
            }),
        );
    }
}
