import { MatSnackBar } from '@angular/material/snack-bar';
import { AddProjectService } from './../add-project.service';
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs/internal/Subject';
import { BehaviorSubject } from 'rxjs';
import { ImageItem } from '../../../../public/models/general.model';
import { takeUntil, filter, take, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-project-images',
  templateUrl: './project-images.component.html',
  styleUrls: ['./project-images.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectImagesComponent implements OnInit, OnDestroy {
  destroyed = new Subject();
  images = new BehaviorSubject<ImageItem[]>(null);
  endPoint: string;
  imageCount: number;

  // actions
  deleteImage = new BehaviorSubject(null);
  updateFav = new BehaviorSubject(null);
  updateTitle = new BehaviorSubject(null);
  updateOrder = new BehaviorSubject(null);

  projectID: string;
  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private projectService: AddProjectService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.parent.params.pipe(take(1)).subscribe( params => {
      const id = params.projectid;
      if (!id) {
        this.projectService.jumpTo('labels');
      }
    });


    this.projectService.project.pipe(takeUntil(this.destroyed)).subscribe(project => {
      if (project) {
        this.projectService.getProjectImages(project.uid).pipe(take(1)).subscribe(result => {
          this.projectService.setProgress(75);
          this.projectID = project.uid;
          this.images.next(result.projectImages);
          this.imageCount = result.projectImages.length;
        });
      }
    });


    this.endPoint = this.projectService.baseUrl + 'upload-project-image';

    this.deleteImage.pipe(takeUntil(this.destroyed), filter(data => !!data))
    .subscribe(uid => {
      this.projectService.removeProjectImage(this.projectID, uid).pipe(take(1)).subscribe(() => {
        this.imageCount = this.imageCount - 1;
      });
    });

    this.updateFav.pipe(takeUntil(this.destroyed), filter(data => !!data))
    .subscribe(uid => {
      this.projectService.updateProjectPrimaryImage(this.projectID, uid).pipe(take(1)).subscribe();
    });

    this.updateTitle.pipe(takeUntil(this.destroyed), debounceTime(1000), filter(data => !!data))
    .subscribe(data => {
      this.projectService.updateProjectImageTitle(this.projectID, data).pipe(take(1)).subscribe();
    });

    this.updateOrder.pipe(takeUntil(this.destroyed), debounceTime(300), filter(data => !!data))
    .subscribe(data => {
      this.projectService.updateProjectImageOrder(this.projectID, data).pipe(take(1)).subscribe();
    });
  }

  onUpload(image) {
    this.imageCount = this.imageCount + 1;
  }

  onDelete(data) {
    this.deleteImage.next(data);
  }

  onFav(data) {
    this.updateFav.next(data);
  }

  onTitle(data) {
    this.updateTitle.next(data);
  }

  onOrder(data) {
    this.updateOrder.next(data);
  }


  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  submit() {
    this.projectService.updateStatus(this.projectID, true).pipe(take(1)).subscribe(result => {
      if (result) {
        this.projectService.setProgress(100);
        this.router.navigate(['admin/projects']);
        this.snackBar.open('Projeniz yayında', 'ok', {duration: 3000});
      } else {
        this.snackBar.open('Üzgünüz bir hata ile karşılaştık', 'ok', {duration: 3000});
      }
    }, err => {
      const warnings = err.error?.warnings;
      if (warnings) {
        this.snackBar.open('Bu projenin profilini henüz tamamlamadınız', 'ok', {duration: 3000});
      }
    });

  }

  goBack() {
    this.projectService.jumpTo('images');
  }
}
