import { ListStoriesComponent } from './list-stories/list-stories.component';
import { ViewProjectComponent } from './view-project/view-project.component';
import { SingleMapComponent } from './single-map/single-map.component';
import { SingleProjectComponent } from './single-project.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingleProjectResolver } from './single-projects.resolver';
import { PublicModule } from 'src/app/public/public.module';
import { SingleProjectRoutingModule } from './single-project.routing.module';

@NgModule({
  declarations: [
    SingleProjectComponent,
    SingleMapComponent,
    ViewProjectComponent,
    ListStoriesComponent
  ],
  imports: [
    CommonModule,
    PublicModule,
    SingleProjectRoutingModule
  ],
  providers: [
    SingleProjectResolver,
  ]
})
export class SingleProjectModule { }
