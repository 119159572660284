import { DataService } from './services/data.service';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, APP_INITIALIZER } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from './components/header/header.component';
import { ImageGalleryComponent } from './components/image-gallery/image-gallery.component';
import { LangSwitcherComponent } from './components/lang-switcher/lang-switcher.component';
import { LogoComponent } from './components/logo/logo.component';
import { MenuComponent } from './components/menu/menu.component';
import { BreakpointObserverService } from './services/breakpoint-observer.service';
import { ItIsSuccessComponent } from './components/it-is-success/it-is-success.component';
import { FooterComponent } from './components/footer/footer.component';
import { ShareContentComponent } from './components/share-content/share-content.component';
import { KeepContactComponent } from './components/keep-contact/keep-contact.component';
import { CacheService } from './services/cache-service';
import { SeoService } from './services/seo.service';
import {MatDialogModule} from '@angular/material/dialog';
import { DotPulseComponent } from './components/dot-pulse/dot-pulse.component';
import { MoreButtonComponent } from './components/more-button/more-button.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { CloseButtonComponent } from './components/close-button/close-button.component';
import { LocalDatePipe } from './pipes/localDate.pipe';
import {MatProgressBarModule} from '@angular/material/progress-bar';

const initSeo = (seo: SeoService) =>  {
  return () => seo.init();
};

@NgModule({
  declarations: [
    LogoComponent,
    LangSwitcherComponent,
    MenuComponent,
    HeaderComponent,
    ImageGalleryComponent,
    ItIsSuccessComponent,
    FooterComponent,
    ShareContentComponent,
    KeepContactComponent,
    DotPulseComponent,
    MoreButtonComponent,
    CloseButtonComponent,
    LocalDatePipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    TranslateModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatProgressBarModule,
  ],
  exports: [
    LogoComponent,
    LangSwitcherComponent,
    MenuComponent,
    TranslateModule,
    MatIconModule,
    HeaderComponent,
    FooterComponent,
    ReactiveFormsModule,
    ItIsSuccessComponent,
    ImageGalleryComponent,
    ShareContentComponent,
    KeepContactComponent,
    MatDialogModule,
    DotPulseComponent,
    MoreButtonComponent,
    MatTooltipModule,
    CloseButtonComponent,
    LocalDatePipe,
    MatProgressBarModule,
  ],
})
export class PublicModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: PublicModule,
      providers: [
        BreakpointObserverService,
        CacheService,
        SeoService,
        {
          provide : APP_INITIALIZER, // run seo service on app start...
          useFactory : initSeo,
          deps: [SeoService],
          multi : true
        },
        LocalDatePipe,
        DataService
      ]
    };
  }
}
