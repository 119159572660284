import { ContactPageComponent } from './contact-page/contact-page.component';
import { ContactSuccessComponent } from './contact-success/contact-success.component';
import { ContactComponent } from './contact.component';
import {Routes, RouterModule} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';


const routes: Routes = [
    {
        path: '',
        component: ContactComponent,
        data: {image: 'contactUs'},
        children: [
            {
                path: '',
                component: ContactPageComponent,
                data : {
                    title: 'meta.contact.title',
                    desc: 'meta.contact.desc'
                },
            },
            {
                path: 'success',
                component: ContactSuccessComponent,
                data : {
                    title: 'meta.contact.success.title',
                    desc: 'meta.contact.success.desc'
                },
            },
        ]
    },
];

export const ContactRoutingModule: ModuleWithProviders = RouterModule.forChild(routes);
