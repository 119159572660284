<div class="keepContact" [routerLink]="['/contact']" @fadeIn>
    <div class="inner">
        <div class="overlay"></div>
        <div class="content">
            <div class="contentInner">
                <h3>{{'public.contact.title' | translate }}</h3>
                <p>{{'public.contact.slogan' | translate }}</p>
            </div>
        </div>
    </div>
</div>