import { fadeIn } from './../../../public/animations';
import { ProjectsService } from './../projects.service';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  Renderer2,
  OnDestroy,
  ViewChild,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import {} from 'googlemaps'; // for this we have created index.d.ts file in src folder (declare module 'googlemaps';) ...
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { Project } from '../../../public/models/projects.model';
import { take, takeUntil, debounceTime, shareReplay } from 'rxjs/operators';

import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';

interface Coordinate {
  lat: number;
  lng: number;
  radius: number;
}

interface MapProject extends Project{
  slug: string;
}

interface FilterRequest {
  onRoad?: string[];
  year?: number[];
  location: Coordinate;
}


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    fadeIn
  ]
})
export class MapComponent implements OnInit, OnDestroy {
  s: any; // script
  destroyed = new Subject<boolean>();
  @ViewChild('gmap') gmapElement: any; // Map Container

  map: google.maps.Map;
  markers: google.maps.Marker[] = [];

  @ViewChild('markerContent', { static: false }) markerContentTemplate: TemplateRef<any>;

  viewChanged = new BehaviorSubject<Coordinate>(null);
  loaded = new Subject<boolean>();
  loading = new BehaviorSubject<boolean>(true);
  filtered: FilterRequest = {location: null};
  coordinates: Coordinate;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer2: Renderer2,
    private projectsService: ProjectsService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    // Load Google Script
    const url = 'https://maps.googleapis.com/maps/api/js?libraries=places,geometry&key=' + environment.mapsKey;
    this.loadScript(url).then(() => {
        this.generateMap();
    });

    // When view changed get projects according to lat long and radius
    this.viewChanged.pipe(takeUntil(this.destroyed), debounceTime(1000)).subscribe(() => {
      this.setProjects();
    });

  }

  // Generate Map
  generateMap() {
    const lat = 36.199249;
    const lng = 29.643909;

    const mapProp = {
      center: new google.maps.LatLng(lat, lng),
      zoom: 12,
      mapTypeId: google.maps.MapTypeId.TERRAIN,
    };

    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);

    google.maps.event.addListener(this.map, 'bounds_changed', () => {
      const bounds = this.map.getBounds();
      const center = this.map.getCenter();
      if (bounds && center) {
        const ne = bounds.getNorthEast();
        // Calculate radius (in meters).
        const mapRadius = google.maps.geometry.spherical.computeDistanceBetween(center, ne);
        const coordinates = {lat: center.lat(), lng: center.lng(), radius: Math.floor(mapRadius)};
        this.coordinates = coordinates;
        this.viewChanged.next(coordinates);
      }
    });
  }

  // Set Projects
  setProjects(){
    this.loading.next(true);
    const params = {...this.filtered, location: this.coordinates };
    this.projectsService.getProjects(100, 0, params, false).pipe(take(1))
    .subscribe(data => {
      this.setMarkers(data.projects);
      this.loaded.next(true);
      this.loading.next(false);
    });
  }

  applyFilter(event: FilterRequest){
    this.filtered = {...this.filtered, ...event};
    this.setProjects();
  }

  // Set Markers
  setMarkers(projects: MapProject[]) {
    // map location with necessary marker info.
    const locations = projects.map((project) => {
      return {
        ...project,
        animation: google.maps.Animation.DROP,
        thumb: this.projectsService.getThumbMap(project.thumb as string),
      };
    });

    // Clear Markers
    this.clearMarkers();

    // Add Markers
    for (const location of locations) {
      this.addMarker(location);
    }

    // Set map for markers
    this.setMapForMarkers(this.map);
  }

  // clear markers
  clearMarkers() {
    this.setMapForMarkers(null);
    this.markers = [];
  }

  // set map for markers
  setMapForMarkers(map: google.maps.Map | null) {
    for (const marker of this.markers) {
      marker.setMap(map);
    }
  }

  addMarker(project: MapProject) {
    const markerIcon = {
      url: 'assets/icons/cube-marker.svg',
      scaledSize: new google.maps.Size(30, 30),
      origin: new google.maps.Point(15, 30),
      anchor: new google.maps.Point(15, 30),
      labelOrigin: new google.maps.Point(15, 30)
    };

    const projectMarker = new google.maps.Marker({
      position: new google.maps.LatLng(project.location.latitude, project.location.longitude),
      icon: markerIcon,
      label: {
        text: (project.name as string).replace(' ', '').substring(0, 3).toUpperCase(),
        color: '#3d3d3d',
        fontSize: '15px',
        fontWeight: '800',
        fontFamily: 'Poppins',
        className: 'markerText basicButton'
      },
    });

    const infowindow =  new google.maps.InfoWindow({});

    google.maps.event.addListener(projectMarker, 'mouseover', ((marker, index) => {
      return () => {
        infowindow.setContent(this.getMarkerContent(project));
        infowindow.open(this.map, marker);
      };
    })(projectMarker, this.markers.length));

    google.maps.event.addListener(projectMarker, 'mouseout', ((marker, index) => {
      return () => {
        infowindow.close();
      };
    })(projectMarker, this.markers.length));

    google.maps.event.addListener(projectMarker, 'click', ((marker, index) => {
      return () => {
        this.router.navigate(['../', project.slug], {relativeTo: this.route});
      };
    })(projectMarker, this.markers.length));

    // Add it to the marker array
    this.markers = [...this.markers, projectMarker];
  }

  // This marker info window content
  getMarkerContent(location: any) {
    const view = this.markerContentTemplate.createEmbeddedView({project: location});
    view.detectChanges();
    return view.rootNodes[0];
  }

  // Load script only if necessary
  private loadScript(url) {
    return new Promise((resolve, reject) => {
      const scripts = this.document.querySelectorAll('script[src*=\'maps.googleapis.com\']');
      if (!scripts.length) {
        this.s = this.renderer2.createElement('script');
        this.s.type = 'text/javascript';
        this.s.src = url;
        this.s.text = ``;
        this.s.async = true;
        this.s.defer = true;
        this.s.onload = resolve;
        this.s.onerror = reject;
        this.renderer2.appendChild(this.document.body, this.s);
        return;
      }
      resolve(true);
    });
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }
}
