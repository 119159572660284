import { PlansRoutingModule } from './plans.routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicModule } from 'src/app/public/public.module';
import { PlansComponent } from './plans.component';

@NgModule({
  declarations: [PlansComponent],
  imports: [
    CommonModule,
    PublicModule,
    PlansRoutingModule
  ]
})
export class PlansModule { }
