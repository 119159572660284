import { BlogPost } from 'src/app/public/models/blog-posts.model';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { fadeIn } from 'src/app/public/animations';
import { LocaleLoaderService } from 'src/app/translations/locale-loader.service';
import { environment } from '../../../../environments/environment';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-single-post',
  templateUrl: './single-post.component.html',
  styleUrls: ['./single-post.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeIn
  ]
})
export class SinglePostComponent implements OnInit, OnDestroy {
  destroyed = new Subject();
  baseUrl: string;
  post = new BehaviorSubject<BlogPost>(null);
  nextPost =  new BehaviorSubject<string>(null);
  previousPost = new BehaviorSubject<string>(null);

  readingTime: number;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: LocaleLoaderService
  ) { }


  ngOnInit(): void {
    this.baseUrl = environment.url + this.router.url;
    this.route.data.pipe(takeUntil(this.destroyed)).subscribe(data => {
      this.post.next(data.postData);
      this.setReadingTime();
      this.nextPost.next(data.postData.next);
      this.previousPost.next(data.postData.prev);
    });
  }

  setReadingTime() {
    this.readingTime = this.getReadingTime(this.post.value.content as string);
  }

  getReadingTime(content: string) {
    const wpm = 225;
    const words = content.trim().split(/\s+/).length;
    const time = Math.ceil(words / wpm);

    return time;
  }

  getRouterLink(link: string) {
    return this.translate.getRouterLink(link);
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }
}
