import { FormControl } from '@angular/forms';

export function maxFileSize( maxSize: number ) {
    const validator = (control: FormControl) => {
        const file = control.value;
        if (file instanceof File && file.size > maxSize) {
                return {
                    maxFileSize: true
                };
        }
        return null;
    };

    return validator;
}
