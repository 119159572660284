<div class="searchBarOuter">
    <div class="searchBar">
        <div class="searchIcon">
            <mat-icon>search</mat-icon>
        </div>
        <input class="searchInput" #search type="text" (keyup)="applySearchTerm(search.value)" placeholder="{{'public.projects.searchByName' | translate }}">
        
        <app-dot-pulse *ngIf="(loading | async)"></app-dot-pulse>
        <div class="clearIcon" *ngIf="search.value && !(loading | async)" (click)="clearSearchTerm()">
            <mat-icon>clear</mat-icon>
        </div>
    </div>
</div>