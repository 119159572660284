import { EditProjectService } from './../edit-project.service';
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-update-labels',
  templateUrl: './update-labels.component.html',
  styleUrls: ['./update-labels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateLabelsComponent implements OnInit, OnDestroy {
  destroyed = new Subject();
  project = new BehaviorSubject(null);
  constructor(
    private projectService: EditProjectService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.projectService.project.pipe(takeUntil(this.destroyed)).subscribe(project => {
      this.project.next(project);
    });
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  submit(values) {
    this.projectService.updateProject(this.project.value.uid, values).pipe(take(1)).subscribe(result => {
      if (result) {
        this.projectService.updateProjectState(values);
        this.snackBar.open('Başarıyla güncellendi', 'ok', {duration: 3000});
      }
    });
  }

}
