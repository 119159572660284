import { BlogPostsRoutingModule } from './blog-posts.routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlogPostsComponent } from './blog-posts.component';
import { AllBlogPostsComponent } from './all-blog-posts/all-blog-posts.component';
import { EditBlogPostComponent } from './edit-blog-post/edit-blog-post.component';
import { AddBlogPostComponent } from './add-blog-post/add-blog-post.component';
import { PublicModule } from 'src/app/public/public.module';
import { AdminSharedModule } from '../admin-shared/admin-shared.module';
import { BlogPostsService } from './blog-posts.service';
import { BlogFormsModule } from './blog-forms/blog-forms.module';
import { EditBlogPostResolver } from './edit-blog-post/edit-blog-post.resolver';



@NgModule({
  declarations: [BlogPostsComponent, AllBlogPostsComponent, EditBlogPostComponent, AddBlogPostComponent],
  imports: [
    CommonModule,
    PublicModule,
    AdminSharedModule,
    BlogPostsRoutingModule,
    BlogFormsModule
  ],
  providers: [
    BlogPostsService,
    EditBlogPostResolver
  ]
})
export class BlogPostsModule { }
