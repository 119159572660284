import { HorizontalMenuComponent } from './components/horizontal-menu/horizontal-menu.component';
import { FixedFormButtonsComponent } from './components/fixed-form-buttons/fixed-form-buttons.component';
import { PublicModule } from './../../public/public.module';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminHeaderComponent } from './components/admin-header/admin-header.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {MatDividerModule} from '@angular/material/divider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MatCardModule} from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { DragScrollModule } from 'ngx-drag-scroll';
import { RouterModule } from '@angular/router';
import { AddressInputComponent } from './components/address-input/address-input.component';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { FixedActionButtonsComponent } from './components/fixed-action-buttons/fixed-action-buttons.component';
import { HelperMagicComponent } from './components/helper-magic/helper-magic.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { ImageUploadInputComponent } from './components/file-upload-input/image-upload-input.component';
import {MatButtonModule} from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [
    AdminHeaderComponent,
    FixedFormButtonsComponent,
    ConfirmationDialogComponent,
    HorizontalMenuComponent,
    AddressInputComponent,
    DragAndDropDirective,
    FixedActionButtonsComponent,
    HelperMagicComponent,
    ImageUploadInputComponent,
    FileUploaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    PublicModule,
    MatToolbarModule,
    FlexLayoutModule,
    MatMenuModule,
    MatDividerModule,
    MatSelectModule,
    MatCardModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    DragScrollModule,
    MatButtonToggleModule,
    DragDropModule,
    CKEditorModule,
    MatButtonModule,
    MatInputModule,
  ],
  exports: [
    AdminHeaderComponent,
    FlexLayoutModule,
    MatMenuModule,
    MatDividerModule,
    MatSelectModule,
    MatCardModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    FixedFormButtonsComponent,
    MatProgressSpinnerModule,
    ConfirmationDialogComponent,
    DragScrollModule,
    HorizontalMenuComponent,
    MatButtonToggleModule,
    AddressInputComponent,
    DragAndDropDirective,
    FixedActionButtonsComponent,
    HelperMagicComponent,
    ImageUploadInputComponent,
    DragDropModule,
    FileUploaderComponent,
    CKEditorModule,
    MatButtonModule,
    MatInputModule,
  ],
})
export class AdminSharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AdminSharedModule,
      providers: [
      ]
    };
  }
}
