import { TagProjectImagesResolver } from './update-project-images/tag-project-images/tag-project-images.resolver';
import { EditProjectComponent } from './edit-project.component';
import { UpdateProjectImagesComponent } from './update-project-images/update-project-images.component';
import { UpdateImagesComponent } from './update-images/update-images.component';
import { EditProjectService } from './edit-project.service';
import { UpdateShortersComponent } from './update-shorters/update-shorters.component';
import { UpdateLabelsComponent } from './update-labels/update-labels.component';
import { AdminSharedModule } from './../../admin-shared/admin-shared.module';
import { PublicModule } from './../../../public/public.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditProjectRoutingModule } from './edit-project.routing.module';
import { ProjectFormsModule } from '../project-forms/project-forms.module';
import { TagProjectImagesComponent } from './update-project-images/tag-project-images/tag-project-images.component';
import { PdfUploadComponent } from './update-project-images/pdf-upload/pdf-upload.component';



@NgModule({
  declarations: [
    EditProjectComponent,
    UpdateLabelsComponent,
    UpdateShortersComponent,
    UpdateImagesComponent,
    UpdateProjectImagesComponent,
    TagProjectImagesComponent,
    PdfUploadComponent,
  ],
  imports: [
    CommonModule,
    PublicModule,
    AdminSharedModule,
    EditProjectRoutingModule,
    ProjectFormsModule
  ],
  providers: [
    EditProjectService,
    TagProjectImagesResolver
  ]
})
export class EditProjectModule { }
