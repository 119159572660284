<div class="topper" @fadeIn>
    <div class="toTheBack" [routerLink]="['../']">
        <mat-icon>keyboard_backspace</mat-icon>
        <div class="txt">
            {{'public.projects.title' | translate }}
        </div>
        <div class="bg bg-pri"></div>
    </div>
</div>
<div class="mapOuter" @fadeIn>
    <div class="buttons">
        <button class="basicButton" *ngIf="(loading | async)">
            <app-dot-pulse></app-dot-pulse>
        </button>
        <app-filter-menu [ngClass]="{'d-none': (loading | async)}" (filterAction)="applyFilter($event)" [loaded]="loaded"></app-filter-menu>
    </div>
    <div id="gmap" #gmap></div>
</div>


<ng-template #markerContent let-project="project">
    <div class="markerContent">
        <div class="imageer" [style.background-image]="project.thumb[256]">
        </div>
        <div class="name">{{project.name}}</div>
    </div>
</ng-template>
