import { takeUntil } from 'rxjs/operators';
import { Inject, Injectable, NgZone, Optional, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LOCAL_STORAGE } from '@ng-toolkit/universal';
import { Subject } from 'rxjs';
import { User } from '../../public/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  public isLoggedIn$ = new Subject<boolean>();
  private destroyed$ = new Subject<boolean>();
  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    @Inject(LOCAL_STORAGE) private localStorage,
    private snackBar: MatSnackBar
  ) {
    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    this.afAuth.authState.pipe(takeUntil(this.destroyed$)).subscribe(async user => {
      if (user) {
        await this.setUserData(user);
        this.localStorage.setItem('user', JSON.stringify(user));
        this.isLoggedIn$.next(true);
      } else {
        this.localStorage.setItem('user', null);
        this.isLoggedIn$.next(false);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  // Sign in with email/password
  async signIn(email, password) {
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then(async (result) => {
        await this.setUserData(result.user);
        this.router.navigate(['/admin']);
        return result.user;
      }).catch((error) => {
        this.snackBar.open('Yanlış kullanıcı adı ya da şifre', 'ok', {duration: 3000});
        return error;
      });
  }

  get currentUser(): User {
    return JSON.parse(this.localStorage.getItem('user'));
  }

  getCurrentUserDetails() {
    return this.afAuth.idTokenResult;
  }
  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  setUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
    };
    return userRef.set(userData, {
      merge: true
    });
  }

  // Sign out
  signOut() {
    return this.afAuth.signOut().then(() => {
      this.localStorage.removeItem('user');
      this.isLoggedIn$.next(false);
      this.snackBar.open('Çıkış yaptınız', 'ok', {duration: 3000});
      this.router.navigate(['admin/sign-in']);
    });
  }
}
