import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProjectsService } from '../projects.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { Project } from '../../../public/models/projects.model';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AddProjectService extends ProjectsService {
  project = new BehaviorSubject<Project>(null);
  projectError = new Subject<boolean>();
  progress = new Subject<number>();
  constructor(
    http: HttpClient,
    private router: Router,
  ) {
    super(http);
  }


  setProject(projectID: string, jump: 'shorters' | 'images' | 'project-images' | 'labels' = null) {
    this.getProject(projectID).pipe(take(1)).subscribe((result) => {
      if (!result) {
        this.projectError.next(true);
        return;
      }

      this.setProjectSuccess(result.project);
      if (jump) {
        this.jumpTo(jump);
      }
    }, err => {
      this.projectError.next(true);
    });
  }

  updateProjectState(project: Partial<Project>) {
    this.project.next({...this.project.value, ...project});
  }

  setProjectSuccess(project: Project) {
    this.project.next(project);
  }

  refreshProject() {
    if (!this.project.value.uid) {
      return;
    }
    this.setProject(this.project.value.uid);
  }


  destroyProject() {
    this.project.next(null);
  }

  setProgress(progress: number) {
    if (progress > 100) {
      return;
    }
    this.progress.next(progress);
  }

  jumpTo(to: 'shorters' | 'images' | 'project-images' | 'labels') {
    const navigateTo = ['/admin/projects/add/' + (this.project.value ? this.project.value.uid + '/' + to : to)];
    this.router.navigate(navigateTo);
  }

}
