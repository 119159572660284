import { AllProjectsComponent } from './all-projects/all-projects.component';
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { ProjectsComponent } from './projects.component';

const routes: Routes = [
    {
        path: '',
        component: ProjectsComponent,
        children: [
            {
                path: '',
                component: AllProjectsComponent,
            },
            {
                path: 'edit/:projectid',
                loadChildren: () => import('./edit-project/edit-project.module').then(m => m.EditProjectModule)
            },
            {
                path: 'add',
                loadChildren: () => import('./add-project/add-project.module').then(m => m.AddProjectModule)
            },
            {
                path: 'add/:projectid',
                loadChildren: () => import('./add-project/add-project.module').then(m => m.AddProjectModule)
            },
        ]
    },

];

export const ProjectsRoutingModule: ModuleWithProviders = RouterModule.forChild(routes);
