import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-more-button',
  templateUrl: './more-button.component.html',
  styleUrls: ['./more-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MoreButtonComponent implements OnInit {
  @Output() moreClicked = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onClick(){
    this.moreClicked.next(true);
  }

}
