import { AddProjectService } from './add-project.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil, take, filter } from 'rxjs/operators';
import { routerFade } from '../../../public/animations';

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss'],
  animations: [
    routerFade,
  ]
})
export class AddProjectComponent implements OnInit, OnDestroy {
  projectID: string;
  destroyed = new Subject();
  pageTitle: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectService: AddProjectService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.route.paramMap.pipe(take(1)).subscribe( paramMap => {
      const id = paramMap.get('projectid');
      if (id) {
        this.projectID = id;
        this.projectService.setProject(this.projectID);
      }
    });

    this.projectService.project.pipe(takeUntil(this.destroyed)).subscribe(project => {
        if (project) {
          this.pageTitle = project?.name.tr;
          if (project.progress) {
            this.router.navigate(['/admin/projects/edit', project.uid]);
        }
      }
    });

    this.projectService.projectError.pipe(filter(data => !!data), take(1)).subscribe(err => {
      if (err) {
        this.router.navigate(['/admin/projects']);
        this.snackBar.open('Proje çağırılırken bir hata ile karşılaşıldı', 'ok', {duration: 3000});
      }
    });
  }

  ngOnDestroy(): void {
    this.projectService.destroyProject();
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  goBack() {
    this.router.navigate(['admin/projects']);
  }

  public getRouterOutletState(outlet: RouterOutlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }
}
