import { ContactPageComponent } from './contact-page/contact-page.component';
import { PublicModule } from './../../public/public.module';
import { ContactSuccessComponent } from './contact-success/contact-success.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactComponent } from './contact.component';
import { ContactService } from './contact.service';
import { ContactRoutingModule } from './contact.routing.module';


@NgModule({
  declarations: [ContactComponent, ContactSuccessComponent, ContactPageComponent],
  imports: [
    CommonModule,
    PublicModule,
    ContactRoutingModule
  ],
  providers: [
    ContactService
  ]
})
export class ContactModule { }
