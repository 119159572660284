import {Routes, RouterModule} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import { PlansComponent } from './plans.component';


const routes: Routes = [
    {
        path: '',
        component: PlansComponent,
    },
];

export const PlansRoutingModule: ModuleWithProviders = RouterModule.forChild(routes);
