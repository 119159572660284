<p class="lead mb-2">Proje Görselini Etiketleyin</p>

<div class="topMenu" @fadeIn>
    <button class="mr-1" [routerLink]="['../']" mat-raised-button><mat-icon class="color-pri mr-1">arrow_back</mat-icon><span>{{'Geri dön' | translate }}</span></button>
    <button (click)="changeScreenMode('full')" mat-raised-button><mat-icon class="color-pri mr-1">fullscreen</mat-icon><span>{{'Tam Ekran' | translate }}</span></button>
</div>

<div class="tagIt" @fadeIn>
    <div class="imageOuter mb-3">
        <div class="imageContainer" [ngClass]="{fullScreen: screenMode === 'full'}">
            <div class="exitFull" *ngIf="screenMode === 'full'">
                <button (click)="changeScreenMode('normal')" mat-raised-button><mat-icon class="color-pri mr-1">fullscreen_exit</mat-icon><span>{{'Küçült' | translate }}</span></button>
            </div>
            <div class="imageInner">
                <img #mainImage class="mainImage" [src]="imageData.url"  [width]="imageData.width" [height]="imageData.height">
                <div #tagContainer class="tagContainer" (click)="startAdding($event)">
                    <div class="dotContainer"
                    *ngFor="let tag of (tags | async); let i=index;" [style.left]="tag.x + '%'" [style.top]="tag.y + '%'"
                    (click)="$event.stopPropagation()" cdkDragBoundary=".tagContainer" cdkDrag (cdkDragEnded)="onDragFinished($event, i)"
                    >
                        <div class="dot" (dblclick)="startEditing(i);">
                            <div class="dotText">
                                {{tag.indicator}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="currentTags ml-2">
        <div class="font-weight-bold">Eklediğiniz etiketler</div>
        <div *ngIf="!(tags | async).length"> Henüz etiket eklemediniz</div>
        <div *ngIf="(tags | async).length">
            <div *ngFor="let tag of (tags | async); let i = index" class="tagListItem">
               <span class="font-weight-bold">{{tag.indicator}}</span>. {{tag.label['tr']}}
               <span *ngIf="tag.square">({{tag.square}} m²)</span>
               <mat-icon class="ml-1" (click)="removeTag(i)">delete</mat-icon>
            </div>
        </div>
    </div>
</div>


<ng-template #tagForm let-data>
    <div class="tagForm">
        <div class="dotContainer">
            <div class="dot">
                <div class="dotText">
                    <ng-container *ngIf="tagOnEdit?.indicator">{{tagOnEdit.indicator}}</ng-container>
                    <ng-container *ngIf="!tagOnEdit?.indicator">...</ng-container>
                </div>
            </div>
        </div>
        <div class="form">
            <mat-form-field class="mb-0">
                <input matInput #indicator placeholder="Belirteç" (keyup)="updateIndicator(indicator.value)" [value]="tagOnEdit?.indicator">
            </mat-form-field>
            <mat-form-field>
                <input matInput #label placeholder="Etiket" (keyup)="updateLabelOnEdit(label.value)" [value]="tagOnEdit?.label[currentLang]">
                <div class="lang" matSuffix>
                    <button mat-button [matMenuTriggerFor]="menu"><span>Dil: </span><span class="font-weight-bold">{{currentLang}}</span></button>
                    <mat-menu #menu="matMenu">
                        <ng-container *ngFor="let lang of langs">
                            <button mat-menu-item *ngIf="currentLang !== lang" (click)="changeLang(lang)">{{lang}}</button>
                        </ng-container>
                    </mat-menu>
                </div>
            </mat-form-field>
            <mat-form-field class="mb-0">
                <div class="lang" matSuffix>m²</div>
                <input type="number" matInput #square placeholder="Alan" (keyup)="updateSquareOnEdit(square.value)" [value]="tagOnEdit?.square">
            </mat-form-field>
            <button [disabled]="!isValidTag" mat-raised-button class="w-100" color="primary" (click)="tagAction(data)">
                {{data.action === 'edit' ? 'Güncelle' : 'Ekle'}}
            </button>
        </div>
    </div>
</ng-template>

