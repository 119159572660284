import { Observable } from 'rxjs';
import { BreakpointObserverService } from '../../services/breakpoint-observer.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-share-content',
  templateUrl: './share-content.component.html',
  styleUrls: ['./share-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareContentComponent implements OnInit {
  @Input() permalink: string;

  links: {icon: string, url: string }[];
  screenMode: Observable<string>;
  constructor(
    private translateService: TranslateService,
    private breakPointService: BreakpointObserverService
  ) { }

  ngOnInit(): void {
    this.setScreenMode();
    const subject = this.translateService.instant('public.generals.name');
    const message = this.translateService.instant('public.generals.interestYou');
    this.links = [
      {
        icon: 'facebook',
        url: 'https://www.facebook.com/sharer/sharer.php?u=' + this.permalink
      },
      {
        icon: 'twitter',
        url: 'https://twitter.com/intent/tweet?text=' + this.permalink
      },
      {
        icon: 'email',
        url: 'mailto:?subject=' + subject + '&body=' + message + ':' + this.permalink
      },
    ];
  }


  setScreenMode(){
    this.screenMode = this.breakPointService.size$.pipe(
      map(size => {
        return ['xs', 'sm'].includes(size) ? 'small' : 'large';
      })
    );
  }

}
