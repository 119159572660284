import { MapButtonComponent } from './map-button/map-button.component';
import { MapComponent } from './map/map.component';
import { ProjectsService } from './projects.service';
import { ProjectsComponent } from './projects.component';
import { PublicModule } from './../../public/public.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectsRoutingModule } from './projects.routing.module';
import { AllProjectsComponent } from './all-projects/all-projects.component';
import { AllProjectsResolver } from './all-projects/all-projects.resolver';
import { FilterMenuComponent } from './filter-menu/filter-menu.component';
import { SearchBarComponent } from './search-bar/search-bar.component';


@NgModule({
  declarations: [
    ProjectsComponent,
    MapComponent,
    MapButtonComponent,
    AllProjectsComponent,
    FilterMenuComponent,
    SearchBarComponent,
  ],
  imports: [
    CommonModule,
    PublicModule,
    ProjectsRoutingModule
  ],
  providers: [
    AllProjectsResolver,
    ProjectsService,
  ]
})
export class ProjectsModule { }
