import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Observable } from 'rxjs';
import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, OnDestroy, ViewChild, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-filter-menu',
  templateUrl: './filter-menu.component.html',
  styleUrls: ['./filter-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterMenuComponent implements OnInit, OnDestroy {
  @Output() filterAction = new EventEmitter<{onRoad: string[], year: number[]}>();
  @Input() loaded: Observable<boolean>;

  loading = new Subject<'filter' | 'clear'>();
  destroyed = new Subject();

  onRoadTypes = ['finished', 'onProgress', 'offPlan'];
  selectedTypes = [];

  years = [];
  selectedYears: number[] = [];

  filtered = false;
  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    // Available Years
    this.setYears();

    if (this.loaded) {
      this.loaded.pipe(takeUntil(this.destroyed)).subscribe(loaded => {
        if (loaded) {
          this.dialog.closeAll();
          this.loading.next(null);
        }
      });
    }

  }

  // Set Years
  setYears(){
    const currentYear = new Date().getFullYear();
    let minYear = 2017;
    while (minYear <= currentYear) {
      this.years = [...this.years, minYear];
      minYear++;
    }
  }

  // Select Type
  addOrRemoveType(type: string){
    const isSelected = this.selectedTypes.find(val => val === type);

    if (!isSelected){
      this.selectedTypes = [...this.selectedTypes, type];
    } else {
      this.selectedTypes = this.selectedTypes.filter(val => val !== type);
    }
  }

  // Select Year
  addOrRemoveYear(year: number){
    const isSelected = this.selectedYears.find(val => val === year);

    if (!isSelected){
      this.selectedYears = [...this.selectedYears, year];
    } else {
      this.selectedYears = this.selectedYears.filter(val => val !== year);
    }
  }

  // Apply Filter
  applyFilter() {
    if (this.loaded) {
      this.loading.next('filter');
    }
    this.filterAction.next({onRoad: this.selectedTypes, year: this.selectedYears});
    this.setFiltered(true);
  }

  clearFilter(){
    if (this.loaded) {
      this.loading.next('clear');
    }
    this.selectedYears = [];
    this.selectedTypes = [];
    this.filterAction.next({onRoad: null, year: null});
    this.setFiltered(false);
  }

  setFiltered(status) {
    const isFiltered = (!!this.selectedYears.length || !!this.selectedTypes.length) && status;
    this.filtered = isFiltered;
  }

  openDialog(templateRef, popupWidth: string) {
    this.dialog.open(templateRef, {
      width: popupWidth,
      data: {
        isFiltered : this.filtered
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

}
