import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-single-project',
  templateUrl: './single-project.component.html',
  styleUrls: ['./single-project.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class SingleProjectComponent {}
