
<div class="menuOuter">
    <div class="menuButton" (click)="toggleMenu()">
        <div class="menuText">
            <span class="opened">{{ 'public.generals.menu' | translate }}</span>
            <span class="closed">{{ 'public.generals.close' | translate }}</span>
        </div>
        <div class="moreDots">
            <div class="dot1"></div>
            <div class="dot2"></div>
            <div class="dot3"></div>
        </div>
    </div>
</div>

<div class="menu">
    <nav class="navigation">
        <ul>
            <li><a [routerLink]="getRouterLink('about-us')" routerLinkActive="font-weight-bold" [routerLinkActiveOptions]="{exact: true}" [href]="" (click)="toggleMenu()">{{ 'public.aboutUs.title' | translate }}</a></li>
            <li><a [routerLink]="getRouterLink('projects')" routerLinkActive="font-weight-bold" [routerLinkActiveOptions]="{exact: true}" [href]="" (click)="toggleMenu()">{{ 'public.projects.title' | translate }}</a></li>
            <li><a [routerLink]="getRouterLink('magazine')" routerLinkActive="font-weight-bold" [routerLinkActiveOptions]="{exact: true}" [href]="" (click)="toggleMenu()">{{ 'public.magazine.title' | translate }}</a></li>
            <li><a [routerLink]="getRouterLink('contact')" routerLinkActive="font-weight-bold" [routerLinkActiveOptions]="{exact: true}" [href]="" (click)="toggleMenu()">{{ 'public.contact.title' | translate }}</a></li>
        </ul>
    </nav>
    <div class="copyRight">{{ 'public.generals.copyright' | translate }}</div>
</div>
