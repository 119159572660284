import { CacheService } from './../public/services/cache-service';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

import { registerLocaleData } from '@angular/common';
import localeTr from '@angular/common/locales/tr';
import localeEn from '@angular/common/locales/en';
import { makeStateKey } from '@angular/platform-browser';
import {Location} from '@angular/common';

interface Lang {
    path: string;
    key: string;
}
const CURRENT_LANGUAGE = makeStateKey<any>('currentLanguage');

@Injectable({ providedIn: 'root' })
export class LocaleLoaderService {
    public availableLanguages: Lang[] = [
        {
            path: '',
            key: 'tr'
        },
        {
            path: 'en',
            key: 'en'
        },
    ];

    constructor(
        private translateService: TranslateService,
        private cacheService: CacheService,
        private location: Location,
    ) {}

    init(): Promise<boolean>{
        return new Promise<boolean>((resolve, reject) => {
            this.registerCulture(this.currentLang);
            this.setDefaultLang('tr');
            resolve(true);
        });
    }
    // is valid language...
    isValidLanguage(lang: string) {
        return !!this.availableLanguages.filter(lng => lng.key === lang).length;
    }

    // get url by lang
    getUrlByLang(lang: string) {
        const currentPath = this.location.path();
        const routerItems = this.getRouterItems(currentPath);
        const languagePath = this.getPathByLanguage(lang);
        const url = languagePath ? [languagePath, ...routerItems].join('/') : routerItems.join('/');
        return url ? '/' + url : '/';
    }

    // get translations of path
    getTranslations() {
        // remove current lang
       return this.availableLanguages.filter(lng => lng.key !== this.currentLang).map(lng => {
            return {
                url: this.getUrlByLang(lng.key),
                locale: this.getLocale(lng.key),
                locale2: this.getLocale(lng.key, '_')
            };
        });
    }

    // get language path
    getPathByLanguage(lang: string) {
        if (!this.isValidLanguage(lang)) {
            return null;
        }
        return this.availableLanguages.find(lng => lng.key === lang).path;
    }

    // get base path
    getBase(path) {
        return path.split('/')?.[1];
    }

    // get router link
    getRouterLink(link: string) {
        const base = this.currentBase;
        if (base) {
          return ['/' + base + '/' + link];
        }
        return ['/' + link];
    }

    // get current base
    get currentBase() {
        return this.getPathByLanguage(this.currentLang);
    }

    // get language from path
    getLangFromPath(path: string) {
        const basePath = this.getBase(path); // always get second as base path --> this can be locale
        // we deleted default language from available langs beacuse we want make empty path for default language.
        const availableLanguages = this.availableLanguages.map(lang => lang.key).filter(lang => lang !== this.defaultLang);
        const isValidLanguage = availableLanguages.includes(basePath); // check language
        return isValidLanguage ? basePath : this.defaultLang;
    }

    // get router items from path
    getRouterItems(path: string) {
        const lang = this.getLangFromPath(path);
        const items = path.split('/').slice(lang === this.defaultLang ? 1 : 2);
        return items.length ? items : [];
    }

    // core
    setDefaultLang(lang: string) {
        if (!this.isValidLanguage(lang)) {
            return;
        }
        this.translateService.setDefaultLang(lang);
    }

    // core
    get defaultLang() {
        return this.translateService.defaultLang;
    }

    // core
    get currentLang() {
        return this.translateService.currentLang;
    }

    // core
    get onLangChange() {
        return this.translateService.onLangChange;
    }

    // core
    instant(key: string | string[], interpolateParams?: object) {
        if (key) {
            return this.translateService.instant(key, interpolateParams);
        }

        return key;
    }

    // core use
    use(lang: string) {
        this.cacheService.setState(CURRENT_LANGUAGE, lang);
        return this.translateService.use(lang);
    }

    // this uses transfer state for choosing language
    // it running on browser side only by interceptor
    useByState() {
        let state = this.cacheService.getDataFromState(CURRENT_LANGUAGE, null);
        if (!state) {
            state = this.getLangFromPath(this.location.path());
        }

        return this.translateService.use(state);
    }

    // get locale for other libraries...
    getLocale(lang: string = null, format: '_' | '-' = '-'): string {
        switch (lang ? lang : this.currentLang) {
            case 'en':
                return 'en' + format + 'UK';
            default:
                return 'tr' + format + 'TR';
        }
    }

    // necessary for angular materials...
    registerCulture(culture: string) {
        if (!culture) {
            return;
        }
        // Register locale data since only the en-US locale data comes with Angular
        switch (culture) {
            case 'tr': {
                registerLocaleData(localeTr, 'tr');
                break;
            }
            case 'en': {
                registerLocaleData(localeEn, 'en');
                break;
            }
        }
    }
}
