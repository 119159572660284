<div [formGroup]="addressForm" class="w-100">
    <ng-container *ngIf="allowMaps">
        <div id="gmap" class="rounded shadow-sm mb-3 border" #gmap></div>
    </ng-container>
    
    <ng-container *ngIf="allowAddress">
        <mat-form-field class="w-100 form-group">
            <input matInput placeholder="{{ 'fullAddress' | translate }}" formControlName="fullAddress" #addresstext (change)= "onAddressChanged($event)">
            <mat-error *ngIf="aForm.fullAddress.invalid && aForm.fullAddress.touched">
                {{ 'thisAreaIsRequired' | translate }}
            </mat-error>
        </mat-form-field>
    
        <mat-form-field class="w-100 form-group">
            <input matInput placeholder="{{ 'country' | translate }}" formControlName="country">
            <mat-error *ngIf="aForm.country.invalid && aForm.country.touched">
                {{ 'thisAreaIsRequired' | translate }}
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100 form-group" floatPlaceholder="never">
            <input matInput placeholder="{{ 'state' | translate }}" formControlName="state">
            <mat-error *ngIf="aForm.state.invalid && aForm.state.touched">
                {{ 'thisAreaIsRequired' | translate }}
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100 form-group">
            <input matInput placeholder="{{ 'postalCode' | translate }}" formControlName="postalCode">
            <mat-error *ngIf="aForm.postalCode.invalid && aForm.postalCode.touched">
                {{ 'thisAreaIsRequired' | translate }}
            </mat-error>
        </mat-form-field>
    </ng-container>

</div>