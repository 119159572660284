import { fadeIn } from '../../../../public/animations';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageItem } from './../../../../public/models/general.model';
import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
  NgZone,
  QueryList,
  ViewChildren,
  Output,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { Subject } from 'rxjs';
import Sortable from 'sortablejs';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { getThumb } from '../../../../common/helpers/get-thumb';

interface ImageHandlerItem extends ImageItem {
  onProgress?: boolean;
  uploading?: boolean;
  progress?: number;
  fileForUpload: File;
  error?: Error;
  tempID?: number;
}

@Component({
  selector: 'app-image-handler',
  templateUrl: './image-handler.component.html',
  styleUrls: ['./image-handler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeIn
  ]
})
export class ImageHandlerComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() images: ImageHandlerItem[] = [];
  @Input() endPoint: string;
  @Input() fields: any;
  @Input() customButtons: {buttonID: string, label: string}[] = [];
  @Input() customTopButtons: {buttonID: string, label: string}[] = [];

  @Output() imageDeleted = new EventEmitter<string>();
  @Output() favChanged = new EventEmitter<string>();
  @Output() titleChanged = new EventEmitter<{uid: string, value: string, lang: string}>();
  @Output() orderChanged = new EventEmitter<{uid: string, order: number}[]>();
  @Output() imageUploaded = new EventEmitter<ImageHandlerItem>();
  @Output() imageUploading = new EventEmitter<boolean>();
  @Output() customClicked = new EventEmitter<{uid: string, buttonID: string}>();
  @Output() customTopClicked = new EventEmitter<{buttonID: string}>();

  langs = ['tr', 'en'];
  currentLang: 'tr' | 'en' = 'tr';
  destroyed = new Subject();
  favImage: string;
  deletedImages: string[] = [];

  // Drag and Drop
  @ViewChild('imageHandler', { static: true }) imageHandler: ElementRef<HTMLDivElement>;
  @ViewChildren('imageUnit') imageUnits: QueryList<ElementRef>;
  sortable: Sortable;

  // File Upload
  selectedImages: File[] = [];
  constructor(
    private zone: NgZone,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.setImages(this.images);
    // for input value language changes..
    this.titleChanged.pipe(takeUntil(this.destroyed), debounceTime(600)).subscribe(data => {
      this.images = this.images.map(img => {
        if (img.uid === data.uid) {
          return {
            ...img,
            title: img.title ? {...img.title, [this.currentLang]: data.value} : {[this.currentLang] : data.value}
          };
        }
        return img;
      });
    });
  }

  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      this.sortable = Sortable.create(this.imageHandler.nativeElement, {
        animation: 150,
        touchStartThreshold: 3,
        onEnd: (event) => { this.changeOrder(event); }
      });
    });
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  setImages(images: ImageHandlerItem[]) {
    // safe images...
    this.images = images.map(image => {
      return {
        ...image,
        path: this.sanitizer.bypassSecurityTrustStyle(`url(${getThumb(image.path as string, 512)})`)
      };
    });

    if (this.images.length) {
      const favDefault = this.images.find(image => image.primary);
      this.favImage = favDefault ? favDefault.uid : this.images[0].uid;
    }

  }

  changeLang(lang: 'tr' | 'en') {
    this.currentLang = lang;
  }

  changeFavImage(uid: string) {
    if (!uid) {
      return;
    }
    const index = this.images.findIndex((i) => i.uid === uid);
    this.images[index] = {...this.images[index], primary: true};
    this.favImage = uid;
    this.favChanged.next(uid);
  }

  deleteImage(uid: string) {
    if (!uid) {
      return;
    }

    if (uid === this.favImage) {
      return this.snackBar.open('Resmi silmeden önce başka bir kapak resmi seçmelisiniz ', 'ok', {duration: 3000});
    }
    this.deletedImages = [...this.deletedImages, uid];
    this.imageDeleted.next(uid);
  }

  changeTitle(id: string, val: string) {
    if (!id) {
      return;
    }

    const data = {
      uid: id,
      value: val,
      lang: this.currentLang,
    };

    this.titleChanged.next(data);
  }

  changeOrder(event: Sortable.SortableEvent) {
    const newOrder = this.sortable.toArray(); // getting by data-id attribute

    const newOrders = this.images.map(image => {
      return {
        uid: image.uid,
        order: newOrder.indexOf(image.uid)
      };
    });

    this.orderChanged.next(newOrders);
  }


  customClick(imageUid: string, id: string) {
    this.customClicked.next({uid: imageUid, buttonID: id});
  }

  customTopClick(id: string) {
    this.customTopClicked.next({buttonID: id});
  }

  openDialog(templateRef, popupWidth: string) {
    this.dialog.closeAll();
    this.dialog.open(templateRef, {
      width: popupWidth,
    });
  }

  onFileSelected(files: File[]) {
    const fileTypes = ['image/jpg', 'image/png', 'image/jpeg'];
    const maxFileSize = (5 * 1024 * 1024);
    for (const file of files) {
      if (file.size > maxFileSize) {
        return this.snackBar.open('Max resim boyutu 5mb olmalıdır', 'ok', {duration: 3000});
      }

      if (!fileTypes.includes(file.type)) {
        return this.snackBar.open('Sadece resim dosyası yükleyebilirsiniz', 'ok', {duration: 3000});
      }
    }

    this.selectedImages = [...this.selectedImages, ...files];
    this.addImages();
  }

  addImages() {
    if (!this.selectedImages.length) {
      return;
    }

    this.dialog.closeAll();
    this.imageUploading.next(true);
    let i = 0;
    for (const file of this.selectedImages) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        i++;
        const imageStyle = this.sanitizer.bypassSecurityTrustStyle(`url(${reader.result.toString()})`);
        this.images = [
          ...this.images,
          {
            path: imageStyle,
            onProgress: true,
            uploading: false,
            fileForUpload: file,
            title: null,
            tempID: Math.random() * 9999
          }
        ];

        if (i === this.selectedImages.length) {
          this.uploadImages();
          this.selectedImages = [];
        }

        this.cd.detectChanges();
      };
    }
  }

  uploadImages() {
    const imagesForUpload = this.images.filter(image => image.onProgress && !image.uploading);
    let doneCount = 0;
    if (imagesForUpload.length) {
        for (const image of imagesForUpload) {
          const indexOfImage = this.images.findIndex(i => i.tempID === image?.tempID);
          const file = image.fileForUpload;
          const formData = new FormData();
          formData.append('image', file, file.name);

          // Add additional fields
          if (this.fields) {
            for (const field of Object.keys(this.fields)) {
              const value = this.fields[field];
              formData.append(field, value);
            }
          }

          this.http.post(this.endPoint, formData, {
            reportProgress: true,
            observe: 'events',
          }).pipe(
            takeUntil(this.destroyed),
            ).subscribe(event => {
              if (event.type === HttpEventType.UploadProgress) {
               const prog = event.loaded / event.total * 100;
               this.images[indexOfImage] =  {...image, progress: prog, uploading: true };
              }
              if (event.type === HttpEventType.Response) {
                // const newPath = (event.body as any).url;
                doneCount++;
                const newUid = (event.body as any).uid;
                const isPrimary = this.images.find(i => i.primary);
                this.images[indexOfImage] =  {
                  ...image,
                  uploading: false,
                  onProgress: false,
                  uid: newUid,
                  primary: !isPrimary,
                  tempID: null
                };
                this.imageUploaded.next(this.images[indexOfImage]);
                if (imagesForUpload.length === doneCount) {
                  this.imageUploading.next(false);
                }
                if (!isPrimary) {
                  this.favImage = newUid;
                }
              }
              this.cd.detectChanges();
          }, err => {
            this.images[indexOfImage] =  {...image, error: err};
            doneCount++;
          });
        }
    }
  }
}
