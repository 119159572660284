import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { BlogPost } from 'src/app/public/models/blog-posts.model';
import { Subject } from 'rxjs';
import { BlogPostsService } from '../blog-posts.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-edit-blog-post',
  templateUrl: './edit-blog-post.component.html',
  styleUrls: ['./edit-blog-post.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditBlogPostComponent implements OnInit, OnDestroy {
  postID: string;
  post: BlogPost;
  destroyed = new Subject();

  constructor(
    private service: BlogPostsService,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute
  ) { }


  ngOnInit(): void {
    this.postID = this.route.snapshot.params.postid;
    this.post = this.route.snapshot.data.postData;
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  goBack() {
    this.router.navigate(['../../'], {relativeTo: this.route});
  }

  submit(values) {
    const image = values?.image;
    const requestBody = {
      title: values.title,
      summary: values.summary,
      content: values.content,
    };

    this.service.updateBlogPost(this.postID, requestBody, image).pipe(takeUntil(this.destroyed)).subscribe(([result, imageResponse ]) => {
      // if there is image wait for image upload...
      if ((image && imageResponse?.body) || (!image && result.message)) {
        this.snackBar.open('Gönderi başarıyla güncellendi', 'ok', {duration: 3000});
        return this.router.navigate(['../../'], {relativeTo: this.route});
      }

    }, err => {
      this.snackBar.open('Gönderi güncellenirken bir sorunla karşılaşıldı', 'ok', {duration: 3000});
    });
  }
}
