import { AddProjectService } from './../add-project.service';
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-shorters',
  templateUrl: './shorters.component.html',
  styleUrls: ['./shorters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShortersComponent implements OnInit, OnDestroy {
  destroyed = new Subject();
  project = new BehaviorSubject(null);
  constructor(
    private route: ActivatedRoute,
    private projectService: AddProjectService,
  ) { }

  ngOnInit(): void {
    this.route.parent.params.pipe(take(1)).subscribe( params => {
      const id = params.projectid;
      if (!id) {
        this.projectService.jumpTo('labels');
      }
    });

    this.projectService.project.pipe(takeUntil(this.destroyed)).subscribe(project => {
      this.project.next(project);
      this.projectService.setProgress(25);
    });
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  submit(values) {
    this.projectService.updateProject(this.project.value.uid, values).pipe(take(1)).subscribe(result => {
      if (result) {
        this.projectService.updateProjectState({...values});
        this.projectService.jumpTo('images');
      }
    });
  }

  goBack() {
    this.projectService.jumpTo('labels');
  }
}
