import { EditBlogPostResolver } from './edit-blog-post/edit-blog-post.resolver';
import { AddBlogPostComponent } from './add-blog-post/add-blog-post.component';
import { EditBlogPostComponent } from './edit-blog-post/edit-blog-post.component';
import { AllBlogPostsComponent } from './all-blog-posts/all-blog-posts.component';
import { BlogPostsComponent } from './blog-posts.component';
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

const routes: Routes = [
    {
        path: '',
        component: BlogPostsComponent,
        children: [
            {
                path: '',
                component: AllBlogPostsComponent,
            },
            {
                path: 'edit/:postid',
                component: EditBlogPostComponent,
                resolve: {
                    postData: EditBlogPostResolver
                },
            },
            {
                path: 'add',
                component: AddBlogPostComponent,
            },

        ]
    },

];

export const BlogPostsRoutingModule: ModuleWithProviders = RouterModule.forChild(routes);
