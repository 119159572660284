import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, ElementRef, ViewChild, OnDestroy, Input } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { skip, debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBarComponent implements OnInit, OnDestroy {
  @Output() searchAction = new EventEmitter<string>();
  @Input() loaded: Observable<boolean>;
  loading = new Subject<boolean>();

  searchApplied = new BehaviorSubject<string>(null);
  @ViewChild('search') searchInput: ElementRef;
  destroyed = new Subject();

  constructor() { }

  ngOnInit(): void {
    // Observe search value
    this.searchApplied.pipe(skip(1), debounceTime(1000), takeUntil(this.destroyed)).subscribe((val: string) => {
      this.searchAction.next(val);
    });

    // if it is loaded
    if (this.loaded) {
      this.loaded.pipe(takeUntil(this.destroyed)).subscribe(loaded => {
        if (loaded) {
          this.loading.next(false);
        }
      });
    }
  }

  // Apply Search Term
  applySearchTerm(val: string){
    this.loading.next(true);
    this.searchApplied.next(val);
  }

  // Clear Search Term
  clearSearchTerm() {
    this.loading.next(true);
    this.searchApplied.next(null);
    this.searchInput.nativeElement.value = null;
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

}
