import { Component, OnInit, OnDestroy } from '@angular/core';
import { BlogPostsService } from '../blog-posts.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-add-blog-post',
  templateUrl: './add-blog-post.component.html',
  styleUrls: ['./add-blog-post.component.scss']
})
export class AddBlogPostComponent implements OnInit, OnDestroy {
  destroyed = new Subject();

  constructor(
    private blogPostService: BlogPostsService,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  goBack() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

  submit(values) {
    const image = values.image;
    const requestBody = {
      title: values.title,
      summary: values.summary,
      content: values.content,
    };

    this.blogPostService.addBlogPost(requestBody, image).pipe(takeUntil(this.destroyed)).subscribe(([result, imageResponse ]) => {
      // if there is image wait for image upload...
      if ((image && imageResponse?.body) || (!image && result.uid)) {
        this.snackBar.open('Yeni gönderi başarıyla eklendi', 'ok', {duration: 3000});
        return this.router.navigate(['../'], {relativeTo: this.route});
      }

    }, err => {
      this.snackBar.open('Gönderi eklenirken bir sorunla karşılaşıldı', 'ok', {duration: 3000});
    });
  }
}
