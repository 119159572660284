<div class="footer">
    <div class="copyRight">
        <div class="topRow">
            <div class="footerLogo">
                <app-logo class="dLogo" width='75px' height="'auto'"></app-logo>
            </div>
    
            <div class="socials">
                <a *ngFor="let social of socials;" [title]="social.icon" [href]="social.url">
                    <img [src]="'/assets/icons/' + social.icon + '.svg'" [alt]="social.icon">
                </a>
            </div>
        </div>
    
        <div class="things">
            <div class="thing1">
                {{ 'public.generals.copyright' | translate }} | {{ 'public.generals.reserved' | translate }}
            </div>
            <div class="thing2">
                take a <strong>coffee</strong>.
            </div>
        </div>
    </div>
</div>

