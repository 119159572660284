import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { EditProjectService } from './edit-project.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { routerFade, fadeIn } from '../../../public/animations';

@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    routerFade,
    fadeIn
  ]
})
export class EditProjectComponent implements OnInit, OnDestroy {
  projectID: string;
  destroyed = new Subject();
  pageTitle = new Subject<string>();
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectService: EditProjectService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.destroyed)).subscribe( paramMap => {
      this.projectID = paramMap.get('projectid');
      this.projectService.setProject(this.projectID);
    });


    this.projectService.project.pipe(takeUntil(this.destroyed)).subscribe(project => {
      this.pageTitle.next(project?.name.tr);

      if (project && !project.progress) {
        this.router.navigate(['/admin/projects/add', project.uid]);
      }
    });

    this.projectService.projectError.pipe(takeUntil(this.destroyed)).subscribe(err => {
      if (err) {
        this.router.navigate(['/admin/projects']);
        this.snackBar.open('Proje çağırılırken bir hata ile karşılaşıldı', 'ok', {duration: 3000});
      }
    });
  }

  ngOnDestroy(): void {
    this.projectService.destroyProject();
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  get links() {
    return [
      {
        name: 'Açıklamalar',
        link: './'
      },
      {
        name: 'Detaylar',
        link: './shorters'
      },
      {
        name: 'Görseller',
        link: './images'
      },
      {
        name: 'Proje Görselleri',
        link: './project-images'
      },
      {
        name: 'Hikayeler',
        link: './stories'
      },
    ];
  }

  goBack() {
    this.router.navigate(['admin/projects']);
  }


  public getRouterOutletState(outlet: RouterOutlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

}
