import {trigger, transition, state, style, animate, keyframes} from '@angular/animations';


export let topIn = trigger('topIn', [
    state('void', style(
      {
        'margin-top' : 0,
        opacity : 0
      }
    )),
    transition(':enter, :leave', [
      animate( 400 ),
    ]),
  ]);

export const routerFade =
  // trigger name for attaching this animation to an element using the [@triggerName] syntax
  trigger('routerFade', [

    // route 'enter and leave (<=>)' transition
    transition('*<=>*', [

      // css styles at start of transition
      style({ opacity: 0 }),

      // animation and styles at end of transition
      animate(500, style({ opacity: 1 }))
    ]),
  ]);

export let fadeIn = trigger('fadeIn', [
    state('void', style(
      {
        opacity : 0
      }
    )),
    transition(':enter, :leave', [ // if you want leave animation add :leave
      animate( 500 ),
    ]),
]);


export const widthAnimate =
  // trigger name for attaching this animation to an element using the [@triggerName] syntax
  trigger('widthAnimate', [
    // ...
    state('*',
          style({ width: '{{width}}%' }),
          {params: {width: 100}}),
    transition('*=>*', animate('0.6s ease')),
    // ...
]);


export let growShadow = trigger('growShadow', [
    state('initial', style({})),
    state('final', style(
      {
        transform: 'scale(1.015) translateY(-1.8%)',
        'box-shadow': '0px 18px 24px -21px rgba(0,0,0,0.6)',
      }
    )),
    transition('initial=>final, final=>initial', animate(150)),
]);

