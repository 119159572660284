import { fadeIn } from './../../../../public/animations';
import { LocaleLoaderService } from 'src/app/translations/locale-loader.service';
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Input } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { takeUntil, take } from 'rxjs/operators';
import { Project, ProjectStory } from 'src/app/public/models/projects.model';
import { ProjectsService } from '../../projects.service';

interface StoryResult {
  total: number;
  stories: ProjectStory[];
}

@Component({
  selector: 'app-list-stories',
  templateUrl: './list-stories.component.html',
  styleUrls: ['./list-stories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeIn
  ]
})
export class ListStoriesComponent implements OnInit, OnDestroy {
  destroyed = new Subject();
  project = new BehaviorSubject<Project>(null);
  stories = new BehaviorSubject<ProjectStory[]>([]);
  loaded = new Subject<boolean>();
  total: number;

  moreEnabled = new BehaviorSubject<boolean>(false);

  lang: string;
  constructor(
    private route: ActivatedRoute,
    private projectsService: ProjectsService,
    private translate: LocaleLoaderService
  ) { }

  ngOnInit(): void {
    this.lang = this.translate.currentLang;
    this.route.data.pipe(takeUntil(this.destroyed)).subscribe((data: any) => {
      this.project.next(data.projectData);
      this.setStories(data.projectData.storyData);
    });

  }

  setStories(result: StoryResult) {
    if (!result) {
      return;
    }

    this.total = result?.total;
    this.stories.next([ ...this.stories.value , ...result.stories.map(s => this.mapStory(s))]);
    this.moreEnabled.next(this.total > this.stories.value.length);

    this.loaded.next(true);

  }

  mapStory(story: ProjectStory) {
    return {
      ...story,
      title: story.title[this.lang],
      desc: story.desc[this.lang],
      thumb: story?.image?.path ? this.projectsService.getThumbMap(story.image.path as string) : null,
    };
  }

  getStories() {
    this.projectsService.getProjectStories(this.project.value.uid, 5, this.stories.value?.[this.stories.value.length - 1].uid).pipe(take(1))
    .subscribe((result: StoryResult) => {
      this.setStories(result);
    });
  }



  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }
}
