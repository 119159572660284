import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-fixed-action-buttons',
  templateUrl: './fixed-action-buttons.component.html',
  styleUrls: ['./fixed-action-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FixedActionButtonsComponent implements OnInit {
  @Input() mode: 'stepper' | 'classic' | 'single' = 'stepper';
  @Input() classes: string[] = ['maxMid'];
  @Input() actionText: string;
  @Input() cancelText: string;
  @Input() disabledState: boolean;

  @Output() applyAction = new EventEmitter<boolean>();
  @Output() cancelAction = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  runAction() {
    this.applyAction.emit(true);
  }

  cancel(): void {
    this.cancelAction.emit(true);
  }

}
