import { shareReplay } from 'rxjs/operators';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderAnimationService {
    // tslint:disable-next-line: variable-name
    animationStarted = new Subject<boolean>();
    animationStopped = new Subject<boolean>();
    constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    ) {}


    startAnimation() {
        this.animationStopped.next(false);
        this.animationStarted.next(true);
    }

    stopAnimation() {
        this.animationStopped.next(true);
        this.animationStarted.next(false);
    }


    getStartState() {
        return this.animationStarted;
    }

}
