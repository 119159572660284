import { ContactService } from './../contact.service';
import { Component, OnInit, AfterViewInit, ChangeDetectionStrategy, PLATFORM_ID, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { gsap } from 'gsap';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-contact-success',
  templateUrl: './contact-success.component.html',
  styleUrls: ['./contact-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactSuccessComponent implements OnInit, AfterViewInit {

  constructor(
    private contactService: ContactService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: any,
  ) { }

  ngOnInit(): void {
    const isMessageSent = this.contactService.messageHasSent;

    if (!isMessageSent){
      // this.router.navigate(['../'], {relativeTo: this.route});
    }
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.animate();
    }
  }

  animate() {
    gsap.timeline()
    .fromTo('.title1', {y: '-100%', alpha: 0, duration: 0.3}, {y: 0, alpha: 1})
    .fromTo('.title2', {y: '-100%', alpha: 0, duration: 0.3}, {y: 0, alpha: 1})
    .fromTo('.title3', {y: '-100%', alpha: 0, duration: 0.3}, {y: 0, alpha: 1})
    .fromTo('.keepThis', {y: '-30%', alpha: 0, duration: 0.3}, {y: 0, alpha: 1});
  }
}
