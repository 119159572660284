<p class="lead mb-2">Detaylar</p>

<mat-card class="formUnit" *ngIf="(values | async )" @fadeIn>
    <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <mat-form-field>
                <mat-label>Yapım yılı</mat-label>
                <input type="number" matInput formControlName="year"
                        placeholder="Ex. 2021">
                <mat-error *ngIf="!form.controls.year.valid && form.controls.year.touched">
                    Lütfen geçerli bir yıl girin
                </mat-error>
            </mat-form-field>

            <mat-form-field class="mb-2 w-100">
                <mat-label>Proje Durumu</mat-label>
                <mat-select formControlName="onRoad">
                  <mat-option value="onProgress">Devam Ediyor</mat-option>
                  <mat-option value="finished">Tamamlandı</mat-option>
                  <mat-option value="offPlan">Off-plan</mat-option>
                </mat-select>
              </mat-form-field>

            <label>Konum</label>
            <app-address-input [formGroup]="form.controls.location"></app-address-input>
        </form>
    </mat-card-content>
</mat-card>

<app-fixed-action-buttons
    actionText="{{mode === 'edit' ? 'Güncelle' : 'İlerle'}}"
    mode="{{mode === 'edit' ? 'single' : 'stepper'}}"
    cancelText="{{mode === 'edit' ? 'İptal et' : 'Önceki Adım'}}"
    (applyAction)="onSubmit()"
    (cancelAction)="onBack()"
    [disabledState]="!(form.valid)"
></app-fixed-action-buttons>

