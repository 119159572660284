import { LOCATION_INITIALIZED } from '@angular/common';
import { Injector } from '@angular/core';
import { LocaleLoaderService } from './locale-loader.service';
import { Router, RoutesRecognized } from '@angular/router';
import { Type } from '@angular/core';
import { take } from 'rxjs/operators';

// Safe Language Initialization
export function BrowserTranslateInitializerFactory(translate: LocaleLoaderService, injector: Injector ) {
    return () => new Promise<any>((resolve: any) => {
        translate.init();
        const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
        locationInitialized.then(() => {
          translate.useByState().pipe(take(1)).subscribe((val) => { // use this language for both of server and browser sides...
            }, err => {
              console.error(`Problem with language initialization.'`);
            }, () => {
              resolve(null);
          });
        });
    });
}
