import { PublicModule } from './../../../../public/public.module';
import { AdminSharedModule } from './../../../admin-shared/admin-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddStoryComponent } from './add-story/add-story.component';
import { StoriesComponent } from './stories.component';
import { AllStoriesComponent } from './all-stories/all-stories.component';
import { StoriesRoutingModule } from './stories.routing.module';
import { UpdateStoriesComponent } from './update-stories/update-stories.component';
import { ProjectFormsModule } from '../../project-forms/project-forms.module';
import { UpdateStoriesResolver } from './update-stories/update-stories.resolver';



@NgModule({
  declarations: [AddStoryComponent, StoriesComponent, AllStoriesComponent, UpdateStoriesComponent],
  imports: [
    CommonModule,
    PublicModule,
    AdminSharedModule,
    StoriesRoutingModule,
    ProjectFormsModule,
  ],
  providers: [
    UpdateStoriesResolver
  ]
})
export class StoriesModule { }
