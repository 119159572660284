<div class="videoOverlay" aria-hidden="true">
    <div class="closeButton">
        <app-close-button (closed)="goBack()"></app-close-button>
    </div>


    <div class="videoContainer" @fadeIn>
        <div class="videoWrapper">
            <iframe width="560" height="315" [src]="videoUrl" frameborder="0" allowfullscreen
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
        </div>
    </div>
</div>
