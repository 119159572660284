import { fadeIn } from './../../../../../public/animations';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil } from 'rxjs/operators';
import { ProjectStory } from 'src/app/public/models/projects.model';
import { EditProjectService } from '../../edit-project.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-update-stories',
  templateUrl: './update-stories.component.html',
  styleUrls: ['./update-stories.component.scss'],
  animations: [
    fadeIn
  ]
})
export class UpdateStoriesComponent implements OnInit, OnDestroy {
  destroyed = new Subject();
  projectID: string;
  storyID: string;
  story: ProjectStory;

  constructor(
    private projectService: EditProjectService,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.projectID = this.route.snapshot.params.projectid;
    this.storyID = this.route.snapshot.params.storyid;
    this.story = this.route.snapshot.data.storyData;
  }


  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  goBack() {
    this.router.navigate(['../../'], {relativeTo: this.route});
  }

  submit(values) {
    const image = values?.image;
    const requestBody = {
      title: values.title,
      desc: values.desc,
      uid: this.storyID
    };

    this.projectService.updateStoryOfProject(requestBody, image).pipe(takeUntil(this.destroyed)).subscribe(([result, imageResponse ]) => {
      // if there is image wait for image upload...
      if ((image && imageResponse?.body) || (!image && result.message)) {
        this.snackBar.open('Hikaye başarıyla güncellendi', 'ok', {duration: 3000});
        return this.router.navigate(['../../'], {relativeTo: this.route});
      }

    }, err => {
      this.snackBar.open('Hikaye güncellenirken bir sorunla karşılaşıldı', 'ok', {duration: 3000});
    });
  }

}
