import { LocaleLoaderService } from 'src/app/translations/locale-loader.service';
import { fadeIn, routerFade } from './../../../../public/animations';
import { Project } from './../../../../public/models/projects.model';
import { Component,
  OnInit, OnDestroy, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { Subject, BehaviorSubject} from 'rxjs';
import { ImageItem } from '../../../../public/models/general.model';
import { environment } from '../../../../../environments/environment';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-view-project',
  templateUrl: './view-project.component.html',
  styleUrls: ['./view-project.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeIn,
    routerFade
  ]
})
export class ViewProjectComponent implements OnInit, OnDestroy {
  destroyed = new Subject();
  project = new BehaviorSubject<Project>(null);
  images =  new BehaviorSubject<ImageItem[]>([]);
  projectImages = new BehaviorSubject<ImageItem[]>([]);
  baseUrl: string;
  nextProject =  new BehaviorSubject<string>(null);
  previousProject = new BehaviorSubject<string>(null);

  @ViewChild('toppers', { static: false }) toppers: ElementRef<HTMLDivElement>;

  outletState = new Subject<boolean | string>();
  // makeFixed = new Subject();
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: LocaleLoaderService
  ) { }

  ngOnInit(): void {
    this.baseUrl = environment.url + this.router.url;
    this.route.data.pipe(takeUntil(this.destroyed)).subscribe(data => {
      this.project.next(data.projectData);
      this.images.next(data.projectData.images);
      this.nextProject.next(data.projectData.next);
      this.previousProject.next(data.projectData.prev);
      this.setOutletState();
    });
  }

  getRouterLink(link: string) {
    return this.translate.getRouterLink(link);
  }

  setOutletState() {
    this.outletState.next('');
    setTimeout(() => {
      this.outletState.next(true);
    }, 500);
  }

  public getRouterOutletState() {
    return true;
  }
  /*ngAfterViewInit(): void {
    // this.observeScroll();
  }*/


  // fixed container
 /* observeScroll(){
    const defineFixed = () => {
      const threshold = this.toppers.nativeElement.offsetHeight + 100;
      this.makeFixed.next(this.window.pageYOffset > threshold && this.window.innerWidth > 768);
    };
    const events = ['scroll', 'resize'];

    from(events).pipe(
      mergeMap(event => fromEvent(this.window, event)),
      takeUntil(this.destroyed)
    )
    .subscribe(() => {
      defineFixed();
    });
  }*/

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }
}
