import { Injectable } from '@angular/core';
import { TransferState, StateKey } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
  })
export class CacheService {
    constructor(
        private state: TransferState,
      ) {}


      // This saves in server memory so it accessable only from server
      /*saveInMemory(key: string, data: any) {
        if ( !isPlatformServer(this.platformId )) {
            return null;
        }
        this.zone.runOutsideAngular(() => {
          memoryCache.put(key, data, 6000 * 5);
        });
      }*/

      // getting data from server
     /* getDataFromMemory(key) {
        if ( !isPlatformServer(this.platformId )) {
          return null;
        }

        return this.zone.runOutsideAngular(() => {
          return memoryCache.get(key);
        });
      }*/

      // this sets transfer state to browser. With that we prevents double requests on browser and server
      // Server makes request sets data and passing to browser and browser using this data in first load.
      setState(key: StateKey<any>, data: any) {
        this.state.set(key, data);
      }

      // it can be usable on server and on browser. But it's usable on browser than other choices.
      getDataFromState(key: StateKey<any>, defaultValue: any) {
        return this.state.get(key, defaultValue);
      }
}
