import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ProjectsService } from '../projects.service';
import {Location} from '@angular/common';
import { getThumb } from 'src/app/common/helpers/get-thumb';

@Injectable()
export class SingleProjectResolver implements Resolve<boolean> {
    constructor(
      private router: Router,
      private projectService: ProjectsService,
      private location: Location
    ) { }


    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        const projectSlug = route.params.slug;
        // Return Data
        return this.projectService.getProjectWithSlug(projectSlug).pipe(
            switchMap(p => this.projectService.getProjectStories(p.uid, 5, null).pipe(map(data => {
                return {project: p, stry: data };
            }))),
            map(({project, stry}) => {
                if (!project) {
                    // skipLocationChange with navigateByUrl doesnt work  as expected
                    // see this -> https://github.com/angular/angular/issues/16981
                    this.router.navigate(['404']).then(() => this.location.replaceState(state.url));
                }
                return {
                    ...project,
                    thumb: this.projectService.getThumbMap(project.thumb as string),
                    projectThumb: this.projectService.getThumbMap(project.projectThumb as string),
                    image: getThumb(project.thumb as string, 2048),
                    storyData: stry
                };
            }),
            catchError((err) => {
                this.router.navigate(['404']).then(() => this.location.replaceState(state.url));
                return err;
            }),
        );
    }
}
