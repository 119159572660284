import { Injectable } from '@angular/core';
import { DomSanitizer, makeStateKey } from '@angular/platform-browser';
import { of } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { getThumb } from 'src/app/common/helpers/get-thumb';
import { BlogPost } from 'src/app/public/models/blog-posts.model';
import { CacheService } from 'src/app/public/services/cache-service';
import { DataService } from 'src/app/public/services/data.service';
import { LocaleLoaderService } from 'src/app/translations/locale-loader.service';

const TEMP_POSTS = makeStateKey<any>('magazineData');
const TEMP_POSTS_PAGE = makeStateKey<any>('magazinePageData');

interface PostParams {
  title?: string;
}

@Injectable({
  providedIn: 'root'
})
export class MagazineService {
  path = 'public';

  constructor(
    private sanitizer: DomSanitizer,
    private translationService: LocaleLoaderService,
    private cacheService: CacheService,
    private dataService: DataService
  ) {}

  get lang() {
    return this.translationService.currentLang;
  }

  getPostBySlug(slug: string) {
    const tempSavedPosts = this.cacheService.getDataFromState(TEMP_POSTS, {});

    const post = tempSavedPosts?.[slug];
    if (post) {
      return of(post);
    }

    return this.dataService.get(this.path + '/get-blog-post-with-slug/' + this.lang + '/' + slug).pipe(
      map(result => {
        const p = result.post;
        return p ? p : null;
      }),
      tap( p => {
        if (p) {
          this.cacheService.setState(TEMP_POSTS, {...tempSavedPosts, [slug]: p});
        }
      }),
      shareReplay(1),
    );
  }

  getPosts(length: number, startPoint: number, params: PostParams = {} ) {
    const tempPosts = this.cacheService.getDataFromState(TEMP_POSTS_PAGE, {});

    const uniqueKey = 'MAGAZINE_POSTS_PAGE_DATA_' + this.lang + '_' +
    String(length)  + String(startPoint) + Object.keys(params).reduce((all, current) => {
    return all + current + params[current];
    }, '');

    const tempData = tempPosts?.[uniqueKey];
    if (tempData) {
      return of(tempData);
    }

    const requestBody: any = {
      size: length,
      offset: startPoint,
      ...params
    };

    return this.dataService.create(this.path + '/get-blog-posts/', requestBody).pipe(
      map((data: {hits: BlogPost[], length: number, offset: number, nbHits: number}) => {
        return {posts: data.hits, length: data.length, offset: data.offset, total: data.nbHits};
      }),
      tap((data) => {
          this.cacheService.setState(TEMP_POSTS_PAGE, {...tempData, [uniqueKey]: data});
      }),
      shareReplay(1), // for late subscribers...
    );
  }


  mapPost(post: BlogPost, thumbs = false) {
    if (!post) {
      return null;
    }

    let data: any = {
      ...post,
      title: post.title[this.lang],
      summary: post.summary[this.lang],
      content: post.content[this.lang],
      slug: post.slugs[this.lang]
    };

    if (thumbs) {
      data = {...data, thumb: post.thumb ? this.getThumbMap(post.thumb.path as string) : null};
    }

    return data;
  }

  getThumbMap(thumb: string) {
    return {
      256: this.sanitizer.bypassSecurityTrustStyle(`url(${getThumb(thumb as string, 256)})`),
      512: this.sanitizer.bypassSecurityTrustStyle(`url(${getThumb(thumb as string, 512)})`),
      1024: this.sanitizer.bypassSecurityTrustStyle(`url(${getThumb(thumb as string, 1024)})`),
      2048: this.sanitizer.bypassSecurityTrustStyle(`url(${getThumb(thumb as string, 2048)})`),
    };
  }

}
