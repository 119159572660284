import { fadeIn } from '../../../../public/animations';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { EditRequest } from '../../projects.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-shorters-form',
  templateUrl: './shorters-form.component.html',
  styleUrls: ['./shorters-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeIn
  ]
})
export class ShortersFormComponent implements OnInit, OnDestroy {
  @Input() mode: 'edit' | 'add' = 'add';
  @Input() values: Observable<EditRequest>;
  @Output() formAction = new EventEmitter<any>();
  @Output() backAction = new EventEmitter<any>();

  form: FormGroup;
  destroyed = new Subject();
  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      year: ['', Validators.required],
      onRoad: [false],
      location: this.fb.group({
        longitude: ['',
          [Validators.required]
        ],
        latitude: ['',
          [Validators.required]
        ],
      })
    });

    // set values on fly
    this.values.pipe(takeUntil(this.destroyed)).subscribe(values => {
      if (values.year) {
        this.form.controls.year.setValue(values.year);
      }
      if (values.location) {
        this.form.controls.location.setValue(values.location);
      }

      this.form.controls.onRoad.setValue(values?.onRoad);
    });

    // we need to solve this problem... adress inputs cant update validity
    this.form.controls.location.valueChanges.pipe(takeUntil(this.destroyed)).subscribe(val => {
      this.form.updateValueAndValidity();
      this.cd.detectChanges();
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    const formValues = this.form.value;
    this.formAction.next(formValues);
  }

  onBack() {
    this.backAction.next(true);
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

}
