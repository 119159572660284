import { HttpClient } from '@angular/common/http';
import { DataService } from '../../public/services/data.service';
import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { TranslatableItem, LocationItem, ImageTagItem } from '../../public/models/general.model';
import { forkJoin, of } from 'rxjs';


export interface EditRequest {
  name?: TranslatableItem;
  desc?: TranslatableItem;
  summary?: TranslatableItem;
  year?: number;
  location?: LocationItem;
  onRoad?: boolean;
  yid?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProjectsService extends DataService {
  path = 'projects';

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  addProject(body: EditRequest) {
    return this.create('add-project', body).pipe(
      map(result => {
        return result;
      })
    );
  }

  pasteProject(projectID: string) {
    return this.create('paste-project/' + projectID, {} ).pipe(
      map(result => {
        return result;
      })
    );
  }


  getProjects(body: {size: number, orderBy: string, orderDirection: string, startAfter?: string}) {
    return this.create(this.path + '/all', body).pipe(
      map(result => {
        return result;
      })
    );
  }

  searcProject(searchTerm: string, length = 10) {
    return this.get(this.path + '/search', {s: searchTerm, size: length}).pipe(
      map(result => {
        return result;
      })
    );
  }

  deleteProject(projectID: string) {
    return this.delete(this.path + '/' + projectID, {}).pipe(
      map(result => {
        return result;
      })
    );
  }

  updateStatus(id: string, stat: boolean) {
    return this.create(this.path + '/status/' + id, {status: stat}).pipe(
      map(result => {
        return result;
      })
    );
  }

  updateProject(projectID: string, body: EditRequest) {
    return this.edit(this.path + '/' + projectID, body).pipe(
      map(result => {
        return result;
      })
    );
  }

  getProject(projectID: string) {
    return this.get(this.path + '/' + projectID).pipe(
      map(result => {
        return result;
      })
    );
  }

  getShowCaseImages(projectID: string) {
    return this.get('get-showcase-images/' + projectID).pipe(
      map(result => {
        return result;
      })
    );
  }

  removeShowCaseImage(pid, uid) {
    const body = { projectID: pid, imageUid: uid };
    return this.create('remove-showcase-image', body).pipe(
      map(result => {
        return result;
      })
    );
  }

  updateShowCasePrimaryImage(pid, uid) {
    const body = { projectID: pid, imageUid: uid };
    return this.create('update-showcase-primary-image', body).pipe(
      map(result => {
        return result;
      })
    );
  }

  updateShowCaseImageOrder(pid, data: {uid: string, order: number}[]) {

    const body = { projectID: pid, orderData: data };
    return this.create('update-showcase-image-order', body).pipe(
      map(result => {
        return result;
      })
    );
  }

  updateShowCaseImageTitle(pid, data: {uid: string, value: string, lang: string}) {
    const body = { projectID: pid, ...data };
    return this.create('update-showcase-image-title', body).pipe(
      map(result => {
        return result;
      })
    );
  }

  // Project Images

  uploadProjectPlan(projectID: string, pdf: File) {
    const formData = new FormData();
    formData.append('pdf', pdf, pdf.name);

    return this.create('upload-project-plan/' + projectID, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  removeProjectPlan(projectID: string) {
    return this.delete('remove-project-plan/' + projectID, {});
  }

  getProjectImages(projectID: string) {
    return this.get('get-project-images/' + projectID).pipe(
      map(result => {
        return result;
      })
    );
  }

  getProjectImage(projectID: string, imageUid) {
    return this.get('get-project-image/' + projectID + '/' + imageUid).pipe(
      map(result => {
        return result;
      })
    );
  }

  removeProjectImage(pid, uid) {
    const body = { projectID: pid, imageUid: uid };
    return this.create('remove-project-image', body).pipe(
      map(result => {
        return result;
      })
    );
  }

  updateProjectPrimaryImage(pid, uid) {
    const body = { projectID: pid, imageUid: uid };
    return this.create('update-project-primary-image', body).pipe(
      map(result => {
        return result;
      })
    );
  }

  updateProjectImageOrder(pid, data: {uid: string, order: number}[]) {
    const body = { projectID: pid, orderData: data };
    return this.create('update-project-image-order', body).pipe(
      map(result => {
        return result;
      })
    );
  }

  updateProjectImageTitle(pid, data: {uid: string, value: string, lang: string}) {
    const body = { projectID: pid, ...data };
    return this.create('update-project-image-title', body).pipe(
      map(result => {
        return result;
      })
    );
  }

  updateProjectImageTags(pid, imageUid, imageTags: ImageTagItem[]) {
    const body = { projectID: pid, uid: imageUid, tags: imageTags};
    return this.create('update-project-image-tags', body).pipe(
      map(result => {
        return result;
      })
    );
  }


  // Project Stories
  getProjectStory(storyUid: string) {
    return this.get('get-project-story/' + storyUid);
  }

  getProjectStories(projectID: string) {
    return this.get('get-project-stories/' + projectID).pipe(
      map(result => {
        return result;
      })
    );
  }

  addStoryToProject(requestBody: any, image: File) {
    return this.create('add-story-to-project', requestBody).pipe(
      switchMap(result => {
        return forkJoin([of(result), of(image)]);
      }),
      switchMap(([result, img ]) => {
        // If there is an avatar wait for upload
        return forkJoin([of(result), img ? this.addImageToStory(result.uid, img) : of(null)]);
      }),
    );
  }

  addImageToStory(storyUid: string, image: File) {
    const formData = new FormData();
    formData.append('image', image, image.name);

    return this.create('add-image-to-story/' + storyUid, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  updateStoryOfProject(requestBody: any, image: File) {
    return this.create('update-project-story', requestBody).pipe(
      switchMap(result => {
        return forkJoin([of(result), of(image)]);
      }),
      switchMap(([result, img ]) => {
        // If there is an avatar wait for upload
        return forkJoin([of(result), img ? this.addImageToStory(result.uid, img) : of(null)]);
      }),
    );
  }

  removeProjectStory(storyId: string) {
    const body = { uid: storyId };
    return this.create('remove-project-story', body).pipe(
      map(result => {
        return result;
      })
    );
  }

}
