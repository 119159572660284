import { NgModule, LOCALE_ID  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { LocaleLoaderService } from './locale-loader.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';

// Locales
import { registerLocaleData } from '@angular/common';
import localeTr from '@angular/common/locales/tr';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorLocaleService } from './paginator-locale.service';
registerLocaleData(localeTr);


@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [

    { provide: LOCALE_ID,
      deps: [ LocaleLoaderService ],
      useFactory: ( localeLoaderService ) => localeLoaderService.getLocale()
    },
    { provide: MAT_DATE_LOCALE,
        deps: [LocaleLoaderService],
        useFactory: (localeLoaderService) => localeLoaderService.getLocale()
    },
    {
      provide: MatPaginatorIntl,
      useFactory: (translate) => {
        const service = new PaginatorLocaleService();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslateService]
  },
  ]
})
export class TranslationsModule { }
