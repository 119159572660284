import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import {
  Component,
  OnInit,
  forwardRef,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnDestroy,
  Inject,
  Renderer2,
  ChangeDetectionStrategy,
  Optional,
} from '@angular/core';
import { ControlValueAccessor, FormGroup, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import {} from 'googlemaps'; // for this we have created index.d.ts file in src folder (declare module 'googlemaps';) ...
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressInputComponent),
      multi: true
    },
  ]
})

export class AddressInputComponent implements OnInit, ControlValueAccessor, OnDestroy  {
  constructor(
    private fb: FormBuilder,
    @Optional() @Inject(DOCUMENT) private document: Document,
    private renderer2: Renderer2,
  ) { }


  get aForm() { return this.addressForm.controls; }
  @Input() formGroup: FormGroup; // for Access to formGroup
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('gmap') gmapElement: any; // Map Container
  @ViewChild('addresstext') addresstext: any;

  destroyed = new Subject<boolean>();
  s: any; // script
  allowMaps: boolean;
  allowAddress: boolean;
  addressForm: FormGroup;

  marker: google.maps.Marker;
  onTouch: any = () => {};
  onChange: (_: any) => void = () => { };

  ngOnInit() {
    // prepare form group
    if (this.formGroup) {
      this.addressForm = this.formGroup;
    } else {
      this.addressForm = this.fb.group({
        fullAddress: [''],
        country: [''],
        state: [''],
        postalCode: [''],
      });
    }

    if (this.addressForm.get('longitude') && this.addressForm.get('latitude')) {
      this.allowMaps = true;
    }

    if (this.addressForm.get('fullAddress') && this.addressForm.get('state') &&
      this.addressForm.get('country') && this.addressForm.get('postalCode')) {
      this.allowAddress = true;
    }

    // Load Google Script
    const url = 'https://maps.googleapis.com/maps/api/js?libraries=places&key=' + environment.mapsKey;
    this.loadScript(url).then(() => {
      if (this.allowMaps) {
        const lat = !this.aForm.latitude.value ? 0 : this.aForm.latitude.value;
        const lng = !this.aForm.latitude.value ? 0 : this.aForm.longitude.value;
        this.generateMap(lat, lng);
      }

      if (this.allowAddress) {
        this.getPlaceAutocomplete();
      }
    });

    this.addressForm.valueChanges.pipe(takeUntil(this.destroyed)).subscribe(values => {
      this.onChange = values;
    });
  }


  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  writeValue(value: any): void {
    if (value) {
      this.addressForm.setValue(value);
    }
  }

  registerOnChange(fn: (value: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
        {
            types: ['establishment']  // 'establishment' / 'address' / 'geocode' / '(regions)'
        });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();

        this.invokeEvent(place);
    });
  }

  invokeEvent(place: any) {
    if (place.formatted_address) {
      const address = place.formatted_address;
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      const latlng = new google.maps.LatLng(latitude, longitude);

      this.geocodePosition(latitude, longitude);
      if (this.allowMaps) {
        this.generateMap(latitude, longitude);
      }
      this.setAddress.emit(place);
    }

  }

  geocodePosition(latitude, longitude) {
    const latlng = new google.maps.LatLng(latitude, longitude);
    const request = { location: latlng };
    this.updateLatLong(latitude, longitude);

    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(request, {} = (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            const postalCode = results[0].address_components[results[0].address_components.length - 1].long_name;
            const country = results[0].address_components[results[0].address_components.length - 2].long_name;
            const state = results[0].address_components[results[0].address_components.length - 3].long_name;
            // const city = results[0].address_components[results[0].address_components.length - 4].long_name;

            // Update others...
            if (this.allowAddress) {
              this.updateInputs(results[0].formatted_address, country, state, postalCode);
            }
          }
      }
  });
  }

  updateInputs(address, country, state, postalCode) {
    this.aForm.fullAddress.setValue(address);
    this.aForm.country.setValue(country);
    this.aForm.postalCode.setValue(postalCode);
    this.aForm.state.setValue(state);

    this.aForm.country.markAsDirty();
    this.aForm.postalCode.markAsDirty();
    this.aForm.state.markAsDirty();
    this.addressForm.markAsDirty();
    this.addressForm.markAsTouched();
  }

  updateLatLong(lat: number, lng: number) {
    if (this.allowMaps) {
      this.addressForm.patchValue({
        latitude: lat,
        longitude: lng
      });
    }
  }

  onAddressChanged($event) {
    // for placeholder bug on angular...
    this.aForm.country.setValue(' ');
    this.aForm.postalCode.setValue(' ');
    this.aForm.state.setValue(' ');
  }

  generateMap(lat: number, lng: number) {
    // decide to lat long
    let positioned = true;
    if (!lat || !lng ) {
      lat = 39.925533;
      lng = 32.866287;
      positioned = false;
    }
    const mapProp = {
      center: new google.maps.LatLng(lat, lng),
      zoom: 11,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    const gMap = new google.maps.Map(this.gmapElement.nativeElement, mapProp);

    const markerIcon = {
      url: 'assets/icons/marker.svg',
      scaledSize: new google.maps.Size(45, 45),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(22.5, 48),
      // labelOrigin: new google.maps.Point(23, 20)
    };
    // Marker
    this.marker = new google.maps.Marker({
      map: gMap,
      draggable: true,
      animation: google.maps.Animation.DROP,
      position: gMap.getCenter(),
      icon: markerIcon,
    });

    const $this = this;
    google.maps.event.addListener(this.marker, 'dragend', {} = (event) => {
      $this.geocodePosition(event.latLng.lat(), event.latLng.lng() );
    });

    // observe form value changes
    this.addressForm.valueChanges.pipe(takeUntil(this.destroyed)).subscribe(t => {
      if (t.latitude && t.longitude) {
        this.marker.setPosition(new google.maps.LatLng(t.latitude, t.longitude));
      }
    });


    // Try HTML5 geolocation.
    if (navigator.geolocation && !positioned) {
      navigator.geolocation.getCurrentPosition({} = (position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        gMap.setCenter(pos);
        this.marker.setPosition(pos);
      }, {} = () => {
        // location error
         // we can provide a message
      });
    } else {
      // Browser doesn't support Geolocation
      // we can provide a message
    }
  }


  private loadScript(url) {
    return new Promise((resolve, reject) => {
      const scripts = this.document.querySelectorAll('script[src*=\'maps.googleapis.com\']');
      if (!scripts.length) {
        this.s = this.renderer2.createElement('script');
        this.s.type = 'text/javascript';
        this.s.src = url;
        this.s.text = ``;
        this.s.async = true;
        this.s.defer = true;
        this.s.onload = resolve;
        this.s.onerror = reject;
        this.renderer2.appendChild(this.document.body, this.s);
        return;
      }
      resolve(true);
    });
  }
}

