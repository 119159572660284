import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { routerFade, fadeIn } from '../public/animations';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    routerFade,
    fadeIn
  ]
})
export class AdminComponent implements OnInit, OnDestroy {
  isLoggedIn: boolean;
  destroyed = new Subject();
  constructor(
    private authService: AuthService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.authService.isLoggedIn$.pipe(takeUntil(this.destroyed)).subscribe(status => {
      this.isLoggedIn = status;
      this.cd.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  public getRouterOutletState(outlet: RouterOutlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }
}
