import { UpdateStoriesResolver } from './update-stories/update-stories.resolver';
import { AddStoryComponent } from './add-story/add-story.component';
import { AllStoriesComponent } from './all-stories/all-stories.component';
import { StoriesComponent } from './stories.component';

import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { UpdateStoriesComponent } from './update-stories/update-stories.component';

const routes: Routes = [
    {
        path: '',
        component: StoriesComponent,
        children: [
            {
                path: '',
                component: AllStoriesComponent,
            },
            {
                path: 'update-story/:storyid',
                resolve: {
                    storyData: UpdateStoriesResolver
                },
                component: UpdateStoriesComponent,
            },
            {
                path: 'add-story',
                component: AddStoryComponent,
            },
        ]
    },

];

export const StoriesRoutingModule: ModuleWithProviders = RouterModule.forChild(routes);
