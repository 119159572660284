<button class="basicButton" type="button" (click)="openDialog(filterPage, '600px')" [ngClass]="{'color-pri': filtered}">
    <mat-icon class="mr-1">filter_list</mat-icon>
    {{'public.projects.filters' | translate }}
</button>

<ng-template #filterPage let-data>
    <div class="filterPage mb-2">
        <div class="closeButton">
            <app-close-button [mat-dialog-close]="true"></app-close-button>
        </div>
        <p class="lead font-weight-bold mb-3">
            {{'public.projects.filters' | translate }}
        </p>
    
        <div class="filters">
            <div class="onRoadTypes mb-2">
                <p>{{'public.projects.onRoadStatus' | translate }}</p>
                <div class="typeList">
                    <div class="projectType" *ngFor="let type of onRoadTypes" [ngClass]="{selected: selectedTypes.includes(type)}">
                        <button class="basicButton" type="button" (click)="addOrRemoveType(type)">
                            {{'public.projects.onRoad.' + type | translate}}
                        </button>
                    </div>
                </div>
            </div>
            <div class="yearSelector mb-2">
                <p>{{'public.projects.year' | translate }}</p>
                <div class="yearList">
                    <div class="projectYear" *ngFor="let year of years" [ngClass]="{selected: selectedYears.includes(year)}">
                        <button class="basicButton" type="button" (click)="addOrRemoveYear(year)">
                            {{year}}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <mat-dialog-actions align="end">
            <button class="basicButton mr-2" (click)="clearFilter()" *ngIf="data.isFiltered">
                <app-dot-pulse *ngIf="(loading | async) === 'clear'"></app-dot-pulse>
                <ng-container *ngIf="(loading | async) !== 'clear'">{{'public.generals.clear' | translate}}</ng-container>
            </button>
            <button class="basicButton" (click)="applyFilter()" cdkFocusInitial>
                <app-dot-pulse *ngIf="(loading | async) === 'filter'"></app-dot-pulse>
                <ng-container *ngIf="(loading | async) !== 'filter'">{{'public.generals.apply' | translate}}</ng-container>
            </button>
        </mat-dialog-actions>
    </div>

</ng-template>
