import { getThumb } from './../../../common/helpers/get-thumb';
import { ProjectsService } from './../projects.service';
import { Project } from '../../../public/models/projects.model';
import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef, OnDestroy, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Subject, merge } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil, tap, debounceTime, take } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../../admin-shared/components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-all-projects',
  templateUrl: './all-projects.component.html',
  styleUrls: ['./all-projects.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllProjectsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  columns: any[] = [
    {
      field: 'uid',
      string: 'ID',
      show: false,
    },
    {
      field: 'thumb',
      string: 'Kapak',
      show: true,
    },
    {
      field: 'name.tr',
      string: 'Adı',
      show: true,
    },
    {
      field: 'year',
      string: 'Yapım Yılı',
      show: true,
    },
    {
      field: 'progress',
      string: 'Profil',
      show: true,
    },
    {
      field: 'status',
      string: 'Yayın Durumu',
      show: true,
    },
    {
      field: 'onRoad',
      string: 'Tamamlandı',
      show: true,
    },
    {
      field: 'createdAt',
      string: 'Kayıt Tarihi',
      show: true,
    },
    {
      field: 'updatedAt',
      string: 'Son Güncelleme',
      show: false,
    },
    {
      field: 'controls',
      string: 'Aksiyonlar',
      show: true,
    },
  ];

  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<Project>();
  loading = new BehaviorSubject<boolean>(null);
  destroyed = new Subject<boolean>();


  total: number;
  lastInResponse: string;
  getSearchResult = new Subject<string>();

  constructor(
    private projectsService: ProjectsService,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.loading.next(true);
    this.setProjects();
    this.setDisplayedColumns();
  }

  ngAfterViewInit(){
    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.destroyed)).subscribe(() => this.paginator.pageIndex = 0);

    // reset next page token on pagesize change
    merge(this.sort.sortChange, this.paginator.page)
    .pipe(
        takeUntil(this.destroyed),
        tap((val: any) => {
          this.setProjects();
        })
    )
    .subscribe();

    // Observe Search
    this.getSearchResult.pipe(debounceTime(1000), takeUntil(this.destroyed)).subscribe(searchTerm => {
      this.loading.next(true);

      if (searchTerm) {
        const size = this.paginator.pageSize ? this.paginator.pageSize : 10;
        this.projectsService.searcProject(searchTerm, size).pipe(take(1)).subscribe(result => {
          this.dataSource.data = result.projects;
          this.loading.next(false);
        });
      } else {
        this.setProjects();
      }
    });
  }


  pasteProject(id: string) {
    this.projectsService.pasteProject(id).pipe(take(1)).subscribe((result: any) => {
      const currentData = this.dataSource.data;
      const project = this.dataSource.data.find(p => p.uid === id);
      const currentProjectIndex = currentData.findIndex(val => val.uid === project.uid);
      const newProject: Project = {...project, uid: result.uid, name: {...project.name, tr: project.name.tr + ' yeni'}};
      const newData =     [
        // part of the array before the specified index
        ...currentData.slice(0, currentProjectIndex),
        // inserted item
        newProject,
        // part of the array after the specified index
        ...currentData.slice(currentProjectIndex)
      ];

      this.dataSource.data = newData;
      this.total = this.total + 1;
      this.snackBar.open('Proje kopyalandı', 'ok', {duration: 3000});
    }, err => {
        this.snackBar.open('Proje kopyalanamadı', 'ok', {duration: 3000});
    });

  }


  setProjects(fresh = false) {
    this.loading.next(true);

    const query = {
      size: this.paginator.pageSize ? this.paginator.pageSize : 10,
      orderBy: this.sort.active,
      orderDirection: this.sort.direction,
      startAfter: !fresh ?  this.lastInResponse : null
    };


    this.projectsService.getProjects(query).pipe(
      takeUntil(this.destroyed)
    ).subscribe((result: {projects: Project[], total: number}) => {
      const projects = result.projects;
      this.lastInResponse = projects.length < this.paginator.pageSize ? null : projects[projects.length - 1].uid;
      this.dataSource.data = projects;
      this.total = result.total;
      this.loading.next(false);
    });
  }

  setDisplayedColumns() {
    this.displayedColumns = this.columns.filter(col => col.show);
    this.displayedColumns = this.displayedColumns.map((col: any) => col.field);
  }

  showOrHideColumn(event: any, index: number) {
    this.columns[index] = {...this.columns[index], show: event.checked };
    this.setDisplayedColumns();
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  openDialog(templateRef, popupWidth: string) {
    this.dialog.open(templateRef, {
      width: popupWidth,
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.getSearchResult.next(filterValue);
  }

  removeProject(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: {
        message: 'Proje silinecek',
        desc: 'Emin misiniz?'
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroyed)).subscribe(applied => {
      if (applied) {
        this.projectsService.deleteProject(id).pipe(take(1)).subscribe(result => {
          if (result) {
            this.snackBar.open('Proje silindi', 'ok', {duration: 3000});
            this.setProjects(true);
          }
        });
      }
    });
  }

  updateStatus(id: string, stat: boolean) {
    this.projectsService.updateStatus(id, stat).pipe(take(1)).subscribe(result => {
      if (result) {
        this.dataSource.data = this.dataSource.data.map(d => {
          if (d.uid === id) {
            return {...d, status: stat };
          }
          return d;
        });

        this.snackBar.open(stat ? 'Proje yayına alındı' : 'Proje yayından kaldırıldı', 'ok', {duration: 3000});
      }
    }, err => {
      const warnings = err.error?.warnings;
      if (warnings) {
        this.snackBar.open('Bu projenin profilini henüz tamamlamadınız', 'ok', {duration: 3000});
      }
    });
  }

  getThumbPath(path) {
    return getThumb(path, 64);
  }

  handleImageError(row: Project) {
    this.dataSource.data = this.dataSource.data.map(data => {
      const errors = data?.errors ? data.errors : [];
      if (data.uid === row.uid) {
        return {...data, errors: [...errors, 'thumbError' ]};
      }
      return data;
    });
  }
}
