import { fadeIn } from './../../animations';
import { Component } from '@angular/core';

@Component({
  selector: 'app-keep-contact',
  templateUrl: './keep-contact.component.html',
  styleUrls: ['./keep-contact.component.scss'],
  animations: [
    fadeIn
  ]
})
export class KeepContactComponent {

}
