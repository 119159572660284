import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { EditRequest } from '../../projects.service';

@Component({
  selector: 'app-labels-form',
  templateUrl: './labels-form.component.html',
  styleUrls: ['./labels-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelsFormComponent implements OnInit, OnDestroy {
  @Input() mode: 'edit' | 'add' = 'add';
  @Input() values: Observable<EditRequest>;
  @Output() formAction = new EventEmitter<any>();
  @Output() backAction = new EventEmitter<any>();

  destroyed = new Subject();
  forms: {
    [lang: string]: FormGroup;
  };
  currentLang = 'tr';
  langs = ['tr', 'en'];
  fields = ['name', 'desc', 'summary', 'title', 'slogan'];
  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    // we generates form for each language....
    this.forms = this.langs.reduce((forms, lang) => {
      const form = this.fb.group({
        name : ['',
        [
          Validators.required,
        ]],
        desc : ['',
          [
            Validators.required, // Validators.minLength(155)
          ]],
        title : ['',
          [
            Validators.required,
          ]],
        slogan : ['',
          [
            Validators.required,
          ]],
        summary : ['',
          [
            Validators.required, // Validators.minLength(155)
          ]],
      });
      forms = {...forms, [lang]: form };
      return forms;
    }, {});

    // set values on fly
    this.values.pipe(takeUntil(this.destroyed)).subscribe(values => {
      if (values) {
        for (const field of this.fields) {
          for (const lang of this.langs) {
            this.forms[lang].controls[field].setValue(values[field]?.[lang]);
          }
        }
      }
    });

  }


  onSubmit() {
    const formValues = this.fields.reduce((value, field) => {
      const fieldValue = this.langs.reduce((val, lang) => {
        return { ...val, [lang]: this.forms[lang].controls[field].value };
      }, {});
      return {...value, [field]: fieldValue};
    }, {});

    this.formAction.next(formValues);
  }

  onBack() {
    this.backAction.next(true);
  }

  changeLang(lang: string) {
    this.currentLang = lang;
  }


  get formsValid() {
    return Object.values(this.forms).every((form: FormGroup) => {
      return form.valid;
    });
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

}
