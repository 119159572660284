<div class="storiesContainer">
    <figure class="mb-3" *ngFor="let story of (stories | async)">
        <h3>{{story.title}}</h3>
        <div class="storyImage mb-3" [style.background-image]="story.thumb[512]" *ngIf="story.thumb"></div>
        <p>{{story.desc}}</p>
        <div class="date small">{{story.createdAt | localDate}}</div>
    </figure>
</div>

<app-more-button *ngIf="(moreEnabled | async )" @fadeIn (moreClicked)="getStories()"></app-more-button>
