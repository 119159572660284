<div class="sloganContainer">
    <div class="text0">
        <div class="bg"></div>
        <span>{{'public.generals.slogan.weConstruct' | translate }}</span>
    </div>
    <div class="secondary">
        <div class="bg"></div>
        <div class="text1">
            <span>{{'public.generals.slogan.future' | translate }}</span>
        </div>
        <div class="text2">
            <span>{{'public.generals.slogan.information' | translate }}</span>
        </div>
        <div class="text3">
            <span>{{'public.generals.slogan.life' | translate }}</span>
        </div>
    </div>
</div>