import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicModule } from 'src/app/public/public.module';
import { ProjectVideoComponent } from './project-video.component';
import { ProjectVideoRoutingModule } from './project-video.routing.module';

@NgModule({
  declarations: [ProjectVideoComponent],
  imports: [
    CommonModule,
    PublicModule,
    ProjectVideoRoutingModule
  ]
})
export class ProjectVideoModule { }
