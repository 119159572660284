import { AddOrEditUserComponent } from './add-or-edit-user/add-or-edit-user.component';
import { AllUsersComponent } from './all-users/all-users.component';
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { UsersComponent } from './users.component';

const routes: Routes = [
    {
        path: '',
        component: UsersComponent,
        children: [
            {
                path: '',
                component: AllUsersComponent,
            },
            {
                path: 'user/:userid',
                component: AddOrEditUserComponent,
            },
            {
                path: 'add-user',
                component: AddOrEditUserComponent,
            },
        ]
    },

];

export const UserRoutingModule: ModuleWithProviders = RouterModule.forChild(routes);
