<div class="contact">
  <div class="inner">
      <div class="t1-bottom topper">
        <div class="contactTitle">
          <h1 class="t1-bottom">{{'public.contact.title' | translate }}</h1>
          <div class="bg"></div>
        </div>
        <div class="corpInfo t1-bottom">
          <div class="infoItem">
            {{'public.contact.ourAddress' | translate }} : <strong>{{'public.contact.address' | translate }}</strong>
          </div>
          <div class="infoItem">
            {{'public.contact.ourPhone' | translate }} : <strong><a href="tel:+9000000000" title="{{'public.contact.ourPhone' | translate }}">+9000000000</a></strong>
          </div>
          <div class="infoItem">
            {{'public.contact.ourEmail' | translate }} : <strong><a href="mailto:oncuplus@gmail.com" title="{{'public.contact.ourEmail' | translate }}">oncuplus@gmail.com</a></strong>
          </div>
        </div>
      </div>

      <div class="title2 p-top">
        <h2>{{'public.contact.slogan' | translate }}</h2>
      </div>
      <form class="contactForm" [formGroup]="contactForm" (ngSubmit)="submit()">
        <div class="contactFormInner">
          <div class="form-group">
            <input class="form-control" placeholder="{{'public.contact.name' | translate }}" type="text" formControlName="name">
            <div class="warn" *ngIf="!contactForm.controls.name.valid && contactForm.controls.name.touched ">
              {{'public.contact.weNeedName' | translate }}
            </div>
          </div>
          <div class="form-group">
            <input class="form-control" placeholder="{{'public.contact.email' | translate }}" type="email" formControlName="email">
            <div class="warn" *ngIf="!contactForm.controls.email.valid && contactForm.controls.email.touched ">
              {{'public.contact.weNeedEmail' | translate }}
            </div>
          </div>
          <div class="form-group">
              <textarea  class="form-control" placeholder="{{'public.contact.message' | translate }}" formControlName="content"></textarea>
              <div class="warn" *ngIf="!contactForm.controls.content.valid && contactForm.controls.content.touched ">
                {{'public.contact.weNeedMessage' | translate }}
              </div>
          </div>
          <div class="form-group" *ngIf="(failed | async)">
            <div class="err">{{'public.contact.messageProblem' | translate }}  <mat-icon>sentiment_very_dissatisfied</mat-icon></div>
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-warning btn-block" [disabled]="!contactForm.valid">
              <ng-container *ngIf="!(sending | async)">{{'public.contact.send' | translate }}</ng-container>
              <ng-container *ngIf="(sending | async)">{{'public.contact.sending' | translate }}</ng-container>
            </button>
          </div>
        </div>
      </form>
  </div>
</div>
