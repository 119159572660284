import { ActivatedRoute } from '@angular/router';
import { Component,
  OnInit,
  ChangeDetectionStrategy,
  Input, OnDestroy, Renderer2, ViewChild, Inject, Output, EventEmitter, Optional, PLATFORM_ID, TemplateRef } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { DOCUMENT, isPlatformServer } from '@angular/common';

import { environment } from '../../../../../environments/environment';
import { Project } from 'src/app/public/models/projects.model';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-single-map',
  templateUrl: './single-map.component.html',
  styleUrls: ['./single-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleMapComponent implements OnInit, OnDestroy {
  project = new BehaviorSubject<Project>(null);
  @Input() height = '400px';
  @Input() width = '100%';
  @Output() addressReady = new EventEmitter<string[]>();
  @ViewChild('gmap') gmapElement: any; // Map Container
  s: any; // script
  marker: google.maps.Marker;
  @ViewChild('markerContent', { static: false }) markerContentTemplate: TemplateRef<any>;

  destroyed = new Subject();
  address: string[];
  map: google.maps.Map;

  constructor(
    private renderer2: Renderer2,
    @Optional() @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if (!isPlatformServer(this.platformId)) {
      this.route.data.pipe(takeUntil(this.destroyed)).subscribe(data => {
        this.project.next(data.projectData);
        const url = 'https://maps.googleapis.com/maps/api/js?libraries=places&key=' + environment.mapsKey;
        this.loadScript(url).then(() => {
            this.generateMap(this.project.value.location.latitude, this.project.value.location.longitude);
        });
      });

    }
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  generateMap(lat: number, lng: number) {
    // decide to lat long
    let positioned = true;
    if (!lat || !lng ) {
      lat = 39.925533;
      lng = 32.866287;
      positioned = false;
    }
    const mapProp = {
      center: new google.maps.LatLng(lat, lng),
      zoom: 13,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);

    const markerIcon = {
      url: 'assets/icons/cube-marker.svg',
      scaledSize: new google.maps.Size(30, 30),
      origin: new google.maps.Point(15, 30),
      anchor: new google.maps.Point(15, 30),
      labelOrigin: new google.maps.Point(15, 30)
    };

    // Marker
    this.marker = new google.maps.Marker({
      map: this.map,
      animation: google.maps.Animation.DROP,
      position: new google.maps.LatLng(this.project.value.location.latitude, this.project.value.location.longitude),
      icon: markerIcon,
      label: {
        text: (this.project.value.name as string ).replace(' ', '').substring(0, 3).toUpperCase(),
        color: '#3d3d3d',
        fontSize: '15px',
        fontWeight: '800',
        fontFamily: 'Poppins',
        className: 'markerText basicButton'
      },
    });

    const infowindow =  new google.maps.InfoWindow({});

    this.geoCode();

    google.maps.event.addListener(this.marker, 'click', ((marker, index) => {
      return () => {
        infowindow.setContent(this.getMarkerContent(this.project.value));
        infowindow.open(this.map, marker);
      };
    })(this.marker, 1));

  }

  // This marker info window content
  getMarkerContent(location: any) {
    const view = this.markerContentTemplate.createEmbeddedView({project: location});
    view.detectChanges();
    return view.rootNodes[0];
  }


  geoCode(){
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({location: {lat: this.project.value.location.latitude, lng: this.project.value.location.longitude}},     (
      results: google.maps.GeocoderResult[],
      status: google.maps.GeocoderStatus
    ) => {
      if (status === 'OK') {
        const result = results[0];
        if (result) {
          const address = result.address_components;
          this.address = [
            address[1]?.long_name,
            address[2]?.long_name,
            address[3]?.long_name,
          ];
          this.addressReady.emit(this.address);
        }
      }
    });
  }


  private loadScript(url) {
    return new Promise((resolve, reject) => {
      const scripts = this.document.querySelectorAll('script[src*=\'maps.googleapis.com\']');
      if (!scripts.length) {
        this.s = this.renderer2.createElement('script');
        this.s.type = 'text/javascript';
        this.s.src = url;
        this.s.text = ``;
        this.s.async = true;
        this.s.defer = true;
        this.s.onload = resolve;
        this.s.onerror = reject;
        this.renderer2.appendChild(this.document.body, this.s);
        return;
      }
      resolve(true);
    });
  }

}
