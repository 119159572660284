import { BreakpointObserverService } from './../../public/services/breakpoint-observer.service';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { WINDOW } from '@ng-toolkit/universal';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { DragControls } from 'three/examples/jsm/controls/DragControls.js';
import {gsap} from 'gsap';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingComponent implements OnInit,  OnDestroy, AfterViewInit {
  destroyed = new Subject<boolean>();
  windowMode: Observable<string>;

  // three
  canvas: HTMLCanvasElement;
  camera: THREE.PerspectiveCamera;
  scene: THREE.Scene;
  renderer: THREE.WebGLRenderer;
  objects: THREE.Mesh[] = [];
  orbitControls: OrbitControls;
  dragControls: DragControls;
  ground: THREE.Mesh;
  animationFrame: any;
  gameStarted = false;
  refresh = new Subject<boolean>();
  positionsOnStart: THREE.Vector3[];
  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private breakPointService: BreakpointObserverService,
    private zone: NgZone,
  ) { }

  ngOnInit(): void {
    this.windowMode = this.breakPointService.size$;
  }

  ngAfterViewInit(){
    this.zone.runOutsideAngular(() => {
      if (isPlatformBrowser(this.platformId)) {
        this.initGame();
      }
    });
  }

  initGame() {
      // for performance
      const pixelRatio = this.window.devicePixelRatio;
      let AA = true;
      if (pixelRatio > 1) {
        AA = false;
      }

      // set canvas
      this.canvas = this.document.querySelector('#gameArea');
      this.renderer = new THREE.WebGLRenderer({canvas: this.canvas, alpha: true, antialias: AA, powerPreference: 'high-performance'});
      this.renderer.shadowMap.enabled = true;
      this.scene = new THREE.Scene();

      this.setCamera();
      this.setLights();
      this.setGround();
      this.setObjects();
      this.setOrbitControls();
      this.animate();
  }

  setCamera(){
    let FOV;
    let FAR;
    const NEAR = 0.1;

    // Mobile camera
    if (this.window.innerWidth <= 768) {
      FOV = 50;
      FAR = 300;
      // 769px - 1080px screen width camera
    } else if (this.window.innerWidth >= 769 && window.innerWidth <= 1080) {
      FOV = 45;
      FAR = 350;
      // > 1080px screen width res camera
    } else {
      FOV = 40;
      FAR = 400;
    }


    this.camera =  new THREE.PerspectiveCamera(FOV, this.window.innerWidth / this.window.innerHeight, NEAR, FAR );
    this.camera.position.set( 240, 100, 10 );
  }

  setLights() {
    const light = new THREE.AmbientLight(0xffffff, 0.7);
    this.scene.add(light);

    const light2 = new THREE.PointLight(0xffffff, 0.3, 100);
    light2.position.set(-40, 80, 0);
    light2.castShadow = true;
    light2.shadow.radius = 20;
    light2.shadow.mapSize.width = 2048;
    light2.shadow.mapSize.height = 2048;
    this.scene.add(light2);


    const light3 = new THREE.PointLight(0xffffff, 0.3, 100);
    light3.position.set(40, 80, 0);
    light3.castShadow = true;
    light3.shadow.radius = 20;
    light3.shadow.radius = 20;
    light3.shadow.mapSize.width = 2048;
    light3.shadow.mapSize.height = 2048;
    this.scene.add(light3);
  }

  setGround() {
    const planeGeometry = new THREE.PlaneGeometry( 200, 200 );
    planeGeometry.rotateX( - Math.PI / 2 );

    const planeMaterial = new THREE.ShadowMaterial({});
    planeMaterial.opacity = 0.1;

    this.ground = new THREE.Mesh( planeGeometry, planeMaterial );
    this.ground.receiveShadow = true;
    this.camera.lookAt(this.ground.position);
    this.scene.add( this.ground );
  }

  getColorOfObject(name: string) {
    switch (name) {
      case 'balcony':
        return 0xcfae93;
      case 'roof':
        return 0xb84f29;
      default:
        return 0xffffff;
    }
  }

  setObject(path, scale: number[], position: number[]) {
    const gltfLoader = new GLTFLoader();

    const texture = new THREE.TextureLoader().load('/assets/3d/wall.jpg');
    texture.wrapS = THREE.MirroredRepeatWrapping;
    texture.wrapT = 2;
    texture.repeat.set(0.5, 0.5);

    const setNode = (node: THREE.Mesh, posY: number) => {
      const name = node.name;
      let materialOptions: THREE.MeshPhongMaterialParameters = {
        color: this.getColorOfObject(name),
        shininess: 14,
        emissive: 0x0,
        specular: 0xc9c9c9,
        flatShading: true,
      };

      switch (name) {
        case 'wallLeft':
        case 'wallRight':
            materialOptions = {
              ...materialOptions,
              map: texture,
            };
            break;
      }

      const material = new THREE.MeshPhongMaterial(materialOptions);
      node.material = material;
      node.geometry.computeVertexNormals();
      node.geometry.normalizeNormals();

      //  node.material = material;
      node.castShadow = true;

      if (posY) {
        node.position.y = posY;
      }
    };

    // Load gltf
    let object;
    gltfLoader.load(path, (gltf) => {
      object = gltf.scene;
      object.scale.set(...scale );
      object.position.set(...position );

      object.traverse((node) => {
        if (node.isMesh) {
          setNode(node, 1000);
        }

        if (node.name === 'Tree') {
          console.log(node.position.y);
          node.position.y = 1000;
          node.traverse((treeNode) => {
            if (node.isMesh) {
              setNode(treeNode, 0);
            }
          });
        }
      });

      this.objects = [...this.objects, ...object.children];

      this.scene.add( object );
      if (this.window.innerWidth > 768) {
        this.setDragControls();
      }
    });
  }

  dumpObject(obj, lines = [], isLast = true, prefix = '') {
    const localPrefix = isLast ? '└─' : '├─';
    lines.push(`${prefix}${prefix ? localPrefix : ''}${obj.name || '*no-name*'} [${obj.type}]`);
    const newPrefix = prefix + (isLast ? '  ' : '│ ');
    const lastNdx = obj.children.length - 1;
    obj.children.forEach((child, ndx) => {
      const $isLast = ndx === lastNdx;
      this.dumpObject(child, lines, $isLast, newPrefix);
    });
    return lines;
  }

  setObjects(){
    this.setObject('/assets/3d/whole.gltf', [0.12, 0.12, 0.12], [0, 0, 0]);
  }

  setOrbitControls() {
    this.orbitControls = new OrbitControls(this.camera, this.canvas);
    this.orbitControls.target.set(0, 5, 0);
    this.orbitControls.minDistance = 150;
    this.orbitControls.maxDistance = 200;
    this.orbitControls.maxPolarAngle = Math.PI / 2;
    this.orbitControls.autoRotate = true;
    this.orbitControls.update();
  }

  setDragControls() {
    this.dragControls = new DragControls(this.objects, this.camera, this.renderer.domElement);
    this.dragControls.addEventListener( 'dragstart', ( event ) => {
      this.gameStarted = true;
      this.refresh.next(true);
      this.orbitControls.enableRotate = false;
      event.object.material.color.setHex(0x5893d4);
    });

    this.dragControls.addEventListener( 'dragend', ( event ) => {
      this.gameStarted = false;
      this.orbitControls.enableRotate = true;
      event.object.material.color.setHex(this.getColorOfObject(event.object.name));
    } );

  }

  animate() {
    const animate = () => {
      if (this.resizeRendererToDisplaySize()) {
        const canvas = this.renderer.domElement;
        this.camera.aspect = canvas.clientWidth / canvas.clientHeight;
        this.camera.updateProjectionMatrix();
      }

      if (!this.gameStarted) {
        this.orbitControls.update();
      }

      this.render();
      this.animationFrame = requestAnimationFrame( animate );
    };

    animate();
    setTimeout(() => {
      this.setPosition(5);

      if (this.window.innerWidth > 768) {
        setTimeout(() => {
          this.setPosition(100, true);
        }, 4000);
      }
    }, 500);
  }

  render(){
    const composer = new EffectComposer( this.renderer );
    const renderPass = new RenderPass( this.scene, this.camera );
    composer.addPass( renderPass );
    composer.render();
  }


  setPosition(speed, divide = false){
    let i = 0;
    const objects = this.objects.reverse();
    while (i < objects.length) {
      const object = objects[i];
      let coords = {x: 0, y: 0, z: 0};
      if (divide) {
        const name = object.name;
        switch (name) {
          case 'wallLeft':
            coords = {x: 0, y: 0, z: 120};
            break;
          case 'wallRight':
            coords = {x: 0, y: 0, z: -120};
            break;
          case 'roof':
            coords = {x: 0, y: 150, z: 0};
            break;
          case 'front2':
            coords = {x: 0, y: 120, z: 0};
            break;
          case 'back2':
            coords = {x: 0, y: 120, z: 0};
            break;
          case 'floor':
            coords = {x: 0, y: 90, z: 0};
            break;
          case 'balcony':
            coords = {x: 70, y: 90, z: 0};
            break;
          case 'Tree':
            coords = {x: 0, y: -1000, z: 0};
            break;
        }
      }
      gsap.to(object.position, {duration: 1, ...coords, delay: i / speed});
      i++;
    }

    this.refresh.next(false);
  }

  resizeRendererToDisplaySize() {
    const canvas = this.renderer.domElement;
    const pixelRatio = this.window.devicePixelRatio;
    const width  = canvas.clientWidth * pixelRatio;
    const height = canvas.clientHeight * pixelRatio;
    const needResize = canvas.width !== width || canvas.height !== height;
    if (needResize) {
      this.renderer.setSize(width, height, false);
    }
    return needResize;
  }

  getObjectByName(name: string): THREE.Mesh {
    return this.objects.find(obj => obj.name === name);
  }

  getRandom() {
    const random = Math.random();
    return random < 0.5 ? -random : random;
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      cancelAnimationFrame( this.animationFrame );
      this.camera = null;
      this.canvas = null;
      this.dragControls = null;
      this.gameStarted = false;
      this.orbitControls = null;
    }

    this.destroyed.next(true);
    this.destroyed.complete();
  }

}
