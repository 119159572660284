<div @fadeIn>
    <p class="lead mb-2">Hikayeyi Güncelle</p>

    <mat-card class="formUnit">
        <app-story-form  *ngIf="story" mode="edit" (formAction)="submit($event)" (backAction)="goBack()" [values]="story"></app-story-form>
        <div class="mt-3 small dates">
            <div class="iconSide mr-2"><mat-icon [inline]="true" class="color-pri">schedule</mat-icon></div>
            <div class=textSide>
                <p class="mb-0">Son güncelleme tarihi: {{story.updatedAt | localDate}}</p>
                <p class="mb-0">Oluşturulma tarihi: {{story.createdAt | localDate}}</p>
            </div>
        </div>
    </mat-card>
</div>
