import { RouterModule } from '@angular/router';
import { PublicModule } from './../../public/public.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingComponent } from './landing.component';
import { ProjectBallComponent } from './project-ball/project-ball.component';
import { SloganComponent } from './slogan/slogan.component';
import { SettingsMenuComponent } from './settings-menu/settings-menu.component';

@NgModule({
  declarations: [
    LandingComponent,
    ProjectBallComponent,
    SloganComponent,
    SettingsMenuComponent
  ],
  imports: [
    CommonModule,
    PublicModule,
    RouterModule
  ],
  exports: [
    LandingComponent
  ]
})
export class LandingModule { }
