import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploaderComponent implements OnInit {
  files: FileList | null = null;
  @Output() filesSelected = new EventEmitter<FileList>(null);
  @Input() mimeTypes: string[] = [];
  @Input() extensions: string[] = [];
  @Input() maxMb = 5;
  @Input() multiple = false;
  maxFileSize: number;

  constructor(
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.maxFileSize = this.maxMb * 1024 * 1024;
  }

  onChange(event) {
    this.onFileDropped(event.target.files);
  }

  onFileDropped(fileList: FileList) {
    const isError = !!this.checkErrors(fileList).length;

    if (isError) {
      return;
    }
    this.files = fileList;
    this.filesSelected.next(fileList);
  }

  checkErrors(fileList: FileList){
    let errors: string[] = [];
    let i = 0;
    while (i < fileList.length) {
      const file = fileList.item(i);
      if (file.size > this.maxFileSize) {
        this.snackBar.open('Max dosya boyutu ' + this.maxMb + ' mb olmalıdır', 'ok', {duration: 3000} );
        errors = [...errors, 'maxFileSize'];
      }

      if (!this.mimeTypes.includes(file.type)) {
        this.snackBar.open('Desteklenmeyen dosya türü', 'ok', {duration: 3000} );
        errors = [...errors, 'typeIsInvalid'];
      }
      i++;
    }

    return errors;
  }


  getExtensions() {
    return this.extensions.join(',');
  }

}
