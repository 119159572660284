import { TranslationsModule } from './translations/translations.module';
import { NgModule } from '@angular/core';
import { NotFoundComponent } from './public/components/not-found/not-found.component';
import { PublicModule } from './public/public.module';
import { AngularFireModule } from '@angular/fire';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';
import { AuthTokenHttpInterceptorProvider } from './interceptors/token.interceptor';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { CommonModule } from '@angular/common';
import { NgtUniversalModule } from '@ng-toolkit/universal';

@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslationsModule,
    PublicModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AppRoutingModule,
    LoadingBarHttpClientModule,
    NgtUniversalModule, // this is for window object in ssr...
  ],
  providers: [
    AuthTokenHttpInterceptorProvider
  ],
})
export class AppModule { }
