import { MagazineService } from './../magazine.service';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AllPostsResolver implements Resolve<boolean> {
    constructor(
      private service: MagazineService
    ) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        // Return Data
        return this.service.getPosts(6, 0).pipe(
            map((data) => {
              return data;
            }),
        );
    }
}
