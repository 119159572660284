import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { WINDOW } from '@ng-toolkit/universal';
import { fromEvent, Observable, of } from 'rxjs';
import { startWith, map, distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

const QUERY: Map<string, number> = new Map([
  ['xl', 1200],
  ['lg', 992],
  ['md', 768],
  ['sm', 576],
  ['xs', 0],
]);

@Injectable({
  providedIn: 'root'
})
export class BreakpointObserverService {
  // tslint:disable-next-line: variable-name
  private _size$: Observable<string>;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(WINDOW) private window: Window,
  ) {
    this.setSize();
  }

  private setSize() {
    if (!isPlatformBrowser(this.platformId)) {
      return this._size$ = of('md');
    }
    return this._size$ = fromEvent(this.window, 'resize')
    .pipe(
      startWith(this._getScreenSize()),
      map((event: Event) => {
        return this._getScreenSize();
      }),
      distinctUntilChanged(),
      shareReplay(1)
    );
  }

  public get size$(): Observable<string> {
    return this._size$;
  }

  private _getScreenSize(): string {
    if (!isPlatformBrowser(this.platformId)) {
      return null;
    }
    const sizes = Array.from(QUERY).reverse();
    return sizes.reduce((prev, current) => {
      const label = current[0];
      const size = current[1];

      if (this.window.innerWidth >= size) {
        prev = label;
      }

      return prev;
    }, '');
  }
}
