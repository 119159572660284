import { fadeIn } from './../public/animations';
import { Component, ChangeDetectionStrategy } from '@angular/core';
@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeIn
  ]
})
export class ViewComponent {
  constructor(
  ) { }

}
