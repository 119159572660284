import { EditProjectService } from './../../edit-project.service';
import { fadeIn } from './../../../../../public/animations';
import { getOptimized } from './../../../../../common/helpers/get-thumb';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ImageItem, ImageTagItem } from './../../../../../public/models/general.model';
import { ActivatedRoute } from '@angular/router';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  TemplateRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { take, takeUntil, debounceTime, skip } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

interface ImageWithUrl extends ImageItem {
  url: SafeUrl;
}

@Component({
  selector: 'app-tag-project-images',
  templateUrl: './tag-project-images.component.html',
  styleUrls: ['./tag-project-images.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeIn
  ]
})
export class TagProjectImagesComponent implements OnInit, OnDestroy {
  destroyed = new Subject<boolean>();
  imageData: ImageWithUrl;
  projectID: string;
  imageUid: string;
  tags = new BehaviorSubject<ImageTagItem[]>([]);
  @ViewChild('mainImage', { static: true }) mainImage: ElementRef<HTMLDivElement>;
  @ViewChild('tagContainer', { static: true }) tagContainer: ElementRef<HTMLDivElement>;
  @ViewChild('tagForm', { static: false }) tagForm: TemplateRef<any>;
  tagOnEdit: ImageTagItem;

  currentLang = 'tr';
  langs = ['tr', 'en'];
  screenMode: 'normal' | 'full' = 'normal';
  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private projectService: EditProjectService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.imageData = this.mapImage(this.route.snapshot.data.imageData);
    this.imageUid = this.route.snapshot.params.imageuid;
    this.projectID = this.route.snapshot.params.projectid;

    if (this.imageData.imageTags) {
      this.tags.next(this.imageData.imageTags);
    }
    this.tags.pipe(skip(1), takeUntil(this.destroyed), debounceTime(300)).subscribe(tags => {
      this.writeToDatabase();
    });
  }

  // map image
  mapImage(image: ImageItem): ImageWithUrl {
    return {
      ...image,
      url: this.sanitizer.bypassSecurityTrustUrl(getOptimized(image.path as string))
    };
  }

  // change lang
  changeLang(lang: string) {
    this.currentLang = lang;
  }

  // change screen mode
  changeScreenMode(mode: 'normal' | 'full') {
    this.screenMode = mode;
  }

  onDragFinished(event, index) {
    const xInPercentDiff = 100 / (this.mainImageSizes.width / event.distance.x);
    const yInPercentDiff = 100 / (this.mainImageSizes.height / event.distance.y);

    this.tags.next(
      this.tags.value.map((val, i) => {
        if (i === index) {
          return {
            ...val,
            x: this.regulizeValue(val.x + xInPercentDiff),
            y: this.regulizeValue(val.y + yInPercentDiff),
          };
        }
        return val;
      })
    );
  }

  // start tagging
  startAdding(event: MouseEvent) {
    const xPos = event.pageX - this.mainImage.nativeElement.getBoundingClientRect().left - 5;
    const yPos = event.pageY - this.mainImage.nativeElement.getBoundingClientRect().top - 5;
    const xInPercent = this.regulizeValue((100 / (this.mainImageSizes.width / xPos)));
    const yInPercent = this.regulizeValue((100 / (this.mainImageSizes.height / yPos)));
    this.tagOnEdit = {x: xInPercent, y: yInPercent, square: null, indicator: null, label: {tr: '', en: ''}};
    this.openTagForm('add');
  }

  regulizeValue(val: number): number {
    if (val > 95) {
      return 95;
    } else if (val < 5) {
      return 5;
    }

    return +val.toFixed(3);
  }

  get mainImageSizes() {
    const imageWidth = this.mainImage.nativeElement.offsetWidth;
    const imageHeight = this.mainImage.nativeElement.offsetHeight;

    return {
      height: imageHeight,
      width: imageWidth
    };
  }

  startEditing(index: number) {
    this.tagOnEdit = this.tags.value[index];
    this.openTagForm('edit', index);
  }

  // create tag form
  openTagForm(mode: 'add' | 'edit' = 'add', index: number = null) {
    this.dialog.closeAll();
    this.dialog.open(this.tagForm, {
      width: '600px',
      data: {
        tag: mode === 'add' ? this.tagOnEdit : this.tags.value[index],
        action: mode,
        forEdit: index,
      }
    });
  }

  // clear current tag
  clearCurrentTag() {
    this.tagOnEdit = {x: null, y : null, label: {tr: '', en: ''}, indicator: null, square: null };
  }

  // remove tag
  removeTag(i: number) {
    const newArray = this.tags.value.filter((tag, index) => index !== i);
    this.tags.next(newArray);
  }

  updateLabelOnEdit(val: string) {
    this.tagOnEdit = {...this.tagOnEdit, label: {...this.tagOnEdit.label, [this.currentLang]: val} };
  }

  updateSquareOnEdit(val: number) {
    this.tagOnEdit = {...this.tagOnEdit, square: val};
  }

  updateIndicator(val: string) {
    this.tagOnEdit = {...this.tagOnEdit, indicator: val};
  }

  get isValidTag() {
    return Object.values(this.tagOnEdit.label).every(val => !!val) && !!this.tagOnEdit.indicator;
  }


  tagAction(data: {tag: ImageTagItem, action: 'edit' | 'add', forEdit: number}) {
    if (!this.isValidTag) {
      return;
    }

    this.dialog.closeAll();

    switch (data.action) {
      case 'edit':
        this.editTag(data.forEdit);
        break;
      case 'add':
        this.addTag();
        break;
    }

    this.clearCurrentTag();
  }


  editTag(index: number) {
    this.tags.next(
      this.tags.value.map((val, i) => {
        if (i === index) {
          return this.tagOnEdit;
        }
        return val;
      })
    );
  }

  addTag() {
    this.tags.next([...this.tags.value, this.tagOnEdit]);
  }

  writeToDatabase() {
    this.projectService.updateProjectImageTags(this.projectID, this.imageUid, this.tags.value).pipe(
      take(1)
    ).subscribe(val => {}, err => {
      this.snackBar.open('Bir sorun ile karşılaşıldı. Lütfen daha sonra tekrar deneyin', 'ok', {duration: 3000});
    });
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

}
