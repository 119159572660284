import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject } from '@angular/core';
import { gsap } from 'gsap';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { LocaleLoaderService } from 'src/app/translations/locale-loader.service';
import {Location} from '@angular/common';
import { WINDOW } from '@ng-toolkit/universal';
@Component({
  selector: 'app-lang-switcher',
  templateUrl: './lang-switcher.component.html',
  styleUrls: ['./lang-switcher.component.scss']
})
export class LangSwitcherComponent implements OnInit, AfterViewInit {
  constructor(
    private translate: LocaleLoaderService,
    @Inject(PLATFORM_ID) private platformId: any,
    private location: Location,
    @Inject(WINDOW) private window: Window,
  ) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.animate();
    }
  }

  get langForChange(){
    const current = this.translate.currentLang;
    switch (current) {
      case 'tr':
        return {string: 'en', locale: 'en-UK'};
      case 'en':
        return {string: 'tr', locale: 'tr-TR'};
    }
  }

  get translatedHref() {
    return this.translate.getUrlByLang(this.langForChange.string);
  }

  animate() {
    gsap.to('.langSwitcher', {x: 0, duration: 0.2, delay: 1});
  }
}
