import { ChangeDetectionStrategy,
  Component, OnInit, AfterViewInit, NgZone, Output,
  EventEmitter, PLATFORM_ID, Inject } from '@angular/core';
import {gsap, Power1} from 'gsap';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-settings-menu',
  templateUrl: './settings-menu.component.html',
  styleUrls: ['./settings-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsMenuComponent implements OnInit, AfterViewInit {
  @Output() refreshed = new EventEmitter<boolean>();
  @Output() shuffled = new EventEmitter<boolean>();
  menuMotion: any;
  over: boolean;
  constructor(
    private zone: NgZone,
    @Inject(PLATFORM_ID) private platformId: any,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.animate();
    }
  }

  animate(){
    this.zone.runOutsideAngular(() => {
      this.menuMotion = gsap.timeline()
      .to('.set', {duration: 0.2, x: 80, ease: Power1.easeIn}, 0)
      .to('.set mat-icon', {duration: 0.2, rotation: '+=360', ease: Power1.easeIn}, 0)
      .to('.things', {duration: 0.2, x: 0, ease: Power1.easeIn}, 0)
      .reverse();
    });
  }

  openMenu() {
    if (this.over) {
      return;
    }
    this.over = true;

    this.toggleMenu();
  }

  closeMenu() {
    if (!this.over) {
      return;
    }
    this.over = false;
    this.toggleMenu();
  }

  toggleMenu() {
    this.zone.runOutsideAngular(() => {
      this.menuMotion.reversed( !this.menuMotion.reversed() );
    });
  }

  refresh() {
    this.refreshed.next(true);
  }

  shuffle() {
    this.shuffled.next(true);
  }

}
