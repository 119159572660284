<div class="outer">
    <div class="inner">
        <app-it-is-success></app-it-is-success>
        <div class="message">
            <div class="title1 mat-h4">{{'public.contact.messageSentSucces' | translate }} :)</div>
            <div class="title2 lead">{{'public.contact.weWillReturn' | translate }}.</div>
        </div>

        <div class="title3 lead">{{'public.contact.whileWe' | translate }}</div>
        <div class="keepThis">
            <div class="cursor-pointer unit" [routerLink]="['/projects']">
                <div class="unitInner">
                    <div class="iconUnit">
                        <img class="projects" src="/assets/icons/projects.svg" alt=" {{'public.contact.checkProjects' | translate }}">
                    </div>
                    <div class="line">
                        {{'public.contact.checkProjects' | translate }} <mat-icon class="color-pri ml-1">trending_flat</mat-icon>
                    </div>
                </div>

            </div>
            <div class="cursor-pointer unit" [routerLink]="['/about-us']">
                <div class="unitInner">
                    <div class="iconUnit">
                    <img class="coffee" src="/assets/icons/coffee.svg" alt=" {{'public.contact.meetUs' | translate }}">
                    </div>
                    <div class="line">
                        {{'public.contact.meetUs' | translate }} <mat-icon class="color-pri ml-1">trending_flat</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
