import { fadeIn } from './public/animations';
import { Component, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import ('default-passive-events'); // this makes mouse events passive by default
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    fadeIn
  ]
})
export class AppComponent {
  title = 'oncubig';
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    public loader: LoadingBarService,
  ){}
  isServer() {
    return isPlatformServer(this.platformId);
  }

}

