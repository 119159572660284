import { DataService } from './../../public/services/data.service';
import { User } from '../../public/models/user.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends DataService {
  path = 'users';

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }


  getUsers(body: {size: number, orderBy: string, orderDirection: string, startAfter?: string}) {
    return this.create(this.path + '/all', body).pipe(
      map(result => {
        return result;
      })
    );
  }

  searchUser(searchTerm: string) {
    return this.get(this.path + '/search', {s: searchTerm}).pipe(
      map(result => {
        return result;
      })
    );
  }

  addUser(body: User) {
    return this.create(this.path, body).pipe(
      map(result => {

        return result;
      })
    );
  }

  updateUser(userID: string, body: User) {
    return this.edit(this.path + '/' + userID, body).pipe(
      map(result => {
        return result;
      })
    );
  }


  getUser(userID: string) {
    return this.get(this.path + '/' + userID).pipe(
      map(result => {
        return result;
      })
    );
  }

  deleteUser(userID: string) {
    return this.delete(this.path + '/' + userID, {}).pipe(
      map(result => {
        return result;
      })
    );
  }

  sendResetLink(email: string) {
    return this.create('send-reset/' + email, {}).pipe(
      map(result => {
        return result;
      })
    );
  }
}
