import { Observable, Subject } from 'rxjs';
import { ChangeDetectionStrategy, Component, OnInit, AfterViewInit, NgZone, Inject, Optional, PLATFORM_ID } from '@angular/core';
import { WINDOW } from '@ng-toolkit/universal';
import { gsap, Power4, Power1 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { BreakpointObserverService } from '../../public/services/breakpoint-observer.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboutUsComponent implements OnInit, AfterViewInit {
  onAnimation = new Subject();
  windowMode: Observable<string>;

  constructor(
    private breakPointService: BreakpointObserverService,
    @Optional() @Inject(WINDOW) private window: Window,
    private zone: NgZone,
    @Inject(PLATFORM_ID) private platformId: any,
  ) { }

  ngOnInit(): void {
    this.windowMode = this.breakPointService.size$;
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      gsap.registerPlugin(ScrollTrigger);
      this.animateWhatWeDo();
      this.animate();
    }
  }

  animateLogo(event: MouseEvent) {
    this.zone.runOutsideAngular(() => {
      this.onAnimation.next(true);
      const xPos = (event.clientX / this.window.innerWidth) - 0.5;
      const yPos = ( event.clientY / this.window.innerHeight) - 0.5;

      gsap.to('.dLogo', {
        rotationY: xPos * 100,
        rotationX: -yPos * 100,
        ease: Power4.easeOut,
      });
    });
  }

  reverseLogo() {
    this.onAnimation.next(false);
    gsap.to('.dLogo', {
      rotationY: 0,
      rotationX: 0,
      ease: Power4.easeOut,
    });
  }

  animateWhatWeDo() {
    gsap.to('.wwInner .line1', {
      xPercent: -160,
      ease: Power1.easeInOut,
      scrollTrigger: {
        trigger: '.whatWeDo',
        scrub: true,
      }
    });
    gsap.to('.wwInner .line2', {
      xPercent: 160,
      ease: Power1.easeInOut,
      scrollTrigger: {
        trigger: '.whatWeDo',
        scrub: true,
      }
    });
  }

  animate() {
    gsap.timeline({scrollTrigger: {trigger: '.aboutUsHead'}})
    .to('.aboutUsHead .aLeft h1', {x: 0, duration: 0.2})
    .to('.aboutUsHead .aLeft .sech1', {x: 0, duration: 0.2})
    .to('.aboutUsHead .aLeft .cont1', {x: 0, duration: 0.2})
    .to('.aboutUsHead .aLeft .cont2', {x: 0, duration: 0.2})
    .to('.aboutUsHead .aRight .dLogo', {x: 0, alpha: 1, duration: 0.2});

    gsap.timeline({scrollTrigger: {trigger: '.fronter'}})
    .to('.fronter h2', {y: 0, duration: 0.2});

    gsap.timeline({scrollTrigger: {trigger: '.story'}})
    .to('.story h2', { x: 0, duration: 0.4})
    .to('.story .aLeft', { x: 0, duration: 0.4})
    .to('.story .aRight', { x: 0, duration: 0.4});

    gsap.timeline({scrollTrigger: {trigger: '.wwContent'}})
    .to('.wwContent .wwContentInner', { y: 0, duration: 0.4});
  }

}
