import { environment } from '../../../environments/environment';

export const getThumb = (path: string, size: number) => {
    return size ? environment.storagePath + '@thumb' + size + '_' + path : environment.storagePath + path;
};


export const getOptimized = (path: string) => {
    return environment.storagePath + '@optimized_' + path;
};
