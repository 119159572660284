import { UsersGuard } from './users/users.guard';
import { AuthService } from './auth/auth.service';
import { SignInComponent } from './sign-in/sign-in.component';
import { AdminSharedModule } from './admin-shared/admin-shared.module';
import { AdminRoutingModule } from './admin.routing.module';
import { AdminComponent } from './admin.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicModule } from '../public/public.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashBoardGuard } from './dashboard/dashboard.guard';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ProjectsGuard } from './projects/projects.guard';
import { BlogPostsGuard } from './blog-posts/blog-posts.guard';


@NgModule({
  declarations: [AdminComponent, SignInComponent, DashboardComponent],
  imports: [
    CommonModule,
    PublicModule,
    AdminRoutingModule,
    AdminSharedModule,
    AngularFireAuthModule,
  ],
  providers: [
    AuthService,
    DashBoardGuard,
    UsersGuard,
    ProjectsGuard,
    BlogPostsGuard
  ]
})
export class AdminModule { }
