import { ProjectsService } from './projects.service';
import { ProjectsRoutingModule } from './projects.routing.module';
import { AdminSharedModule } from './../admin-shared/admin-shared.module';
import { PublicModule } from './../../public/public.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllProjectsComponent } from './all-projects/all-projects.component';
import { ProjectsComponent } from './projects.component';

@NgModule({
  declarations: [
    AllProjectsComponent,
    ProjectsComponent,
  ],
  imports: [
    CommonModule,
    PublicModule,
    AdminSharedModule,
    ProjectsRoutingModule
  ],
  providers: [
    ProjectsService
  ]
})
export class ProjectsModule { }
