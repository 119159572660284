import { ViewResolver } from './view.resolver';
import { LandingModule } from './landing/landing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicModule } from '../public/public.module';
import { AboutUsComponent } from './about-us/about-us.component';
import { ViewRoutingModule } from './view.routing.module';
import { ViewComponent } from './view.component';
import { LoaderAnimationComponent } from './loader-animation/loader-animation.component';

@NgModule({
  declarations: [
    AboutUsComponent,
    ViewComponent,
    LoaderAnimationComponent
  ],
  imports: [
    CommonModule,
    PublicModule,
    LandingModule,
    ViewRoutingModule
  ],
  providers: [
    ViewResolver,
  ]
})
export class ViewModule { }
