<p class="lead mb-2">{{'Proje görselleri' | translate }}</p>
<app-image-handler
    *ngIf="(images | async)"
    [images]="(images | async)"
    [endPoint]="endPoint"
    [fields]="{projectID: projectID}"
    (imageDeleted)="onDelete($event)"
    (favChanged)="onFav($event)"
    (titleChanged)="onTitle($event)"
    (orderChanged)="onOrder($event)"
    (imageUploaded)="onUpload($event)"
></app-image-handler>


<app-fixed-action-buttons
    actionText="Tamamla"
    cancelText="Önceki Adım"
    mode="stepper"
    (applyAction)="submit()"
    (cancelAction)="goBack()"
    [disabledState]="imageCount < 3"
></app-fixed-action-buttons>