<div class="fixedActionButtons" [ngClass]="{stepper: mode === 'stepper'}">
    <div [ngClass]="classes">
        <button class="cancel" *ngIf="cancelText" mat-stroked-button color="primary" (click)="cancel()">
            {{cancelText | translate }}
        </button>
        <button class="run ml-1" (click)="runAction()" mat-flat-button [disabled]="disabledState" color="primary">
            {{actionText | translate }}
        </button>
    </div>

</div>