<div class="outliner">
	<svg version="1.1" id="check" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="978.51px" height="997.835px" viewBox="0 0 978.51 997.835" enable-background="new 0 0 978.51 997.835"
	 xml:space="preserve">
	<path data-llp-duration="400" fill="none" stroke="#00b925" stroke-width="40" stroke-miterlimit="20" d="M951.699,506.601L489.257,969.044L221.961,701.75"
		/>
	</svg>

	<svg version="1.1" id="home" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="978.51px" height="997.835px" viewBox="0 0 978.51 997.835" enable-background="new 0 0 978.51 997.835"
	 xml:space="preserve">
	 <path fill="none" data-llp-duration="1100" stroke="#000000" stroke-width="40" stroke-miterlimit="20" d="M650.319,189.857l301.38,301.377v477.81H26.811
	 v-477.81L489.253,28.791L650.319,189.857"/>
	</svg>
</div>