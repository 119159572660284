import { ProjectStory } from 'src/app/public/models/projects.model';
import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { requiredFileTypes } from 'src/app/common/validators/required-file-types';
import { maxFileSize } from 'src/app/common/validators/max-file-size';
import { getThumb } from 'src/app/common/helpers/get-thumb';

@Component({
  selector: 'app-story-form',
  templateUrl: './story-form.component.html',
  styleUrls: ['./story-form.component.scss']
})
export class StoryFormComponent implements OnInit, OnDestroy {
  @Input() mode: 'edit' | 'add' = 'add';
  @Input() values: ProjectStory;
  @Output() backAction = new EventEmitter<any>();
  @Output() formAction = new EventEmitter<any>();

  textForms: {
    [lang: string]: FormGroup;
  };
  textFields = ['title', 'desc'];

  currentLang = 'tr';
  langs = ['tr', 'en'];

  othersForm: FormGroup;

  destroyed = new Subject();

  thumb: string;
  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    // we generates form for each language....
    this.textForms = this.langs.reduce((forms, lang) => {
      const form = this.fb.group({
        title : ['',
        [
          Validators.required,
        ]],
        desc : ['',
          [
            Validators.required, // Validators.minLength(155)
          ]],
      });
      forms = {...forms, [lang]: form };
      return forms;
    }, {});

    // this fields are not translatable...
    this.othersForm = this.fb.group({
      image : [null,
        [
          requiredFileTypes(['image/jpg', 'image/png', 'image/jpeg']),
          maxFileSize(5 * 1024 * 1024),
        ]],
    });

    // set values on fly
    if (this.values) {
      if (this.values.image) {
        this.thumb = getThumb(this.values.image.path, 512);
      }
      /* if (this.values.progress) {
        this.othersForm.controls.progress.setValue(this.values.progress);
        this.othersForm.markAsPristine();
      } */

      for (const field of this.textFields) {
        for (const lang of this.langs) {
          this.textForms[lang].controls[field].setValue(this.values[field][lang]);
          this.textForms[lang].markAsPristine();
        }
      }
    }
  }

  onSubmit() {
    const textValues = this.textFields.reduce((value, field) => {
      const fieldValue = this.langs.reduce((val, lang) => {
        return { ...val, [lang]: this.textForms[lang].controls[field].value };
      }, {});
      return {...value, [field]: fieldValue};
    }, {});

    this.formAction.next({...this.othersForm.value, ...textValues});
  }

  onBack() {
    this.backAction.next(true);
  }

  changeLang(lang: string) {
    this.currentLang = lang;
  }


  get formsValid() {
    const forms = [this.othersForm, ...Object.values(this.textForms)];
    const isTouched = !!forms.filter(f => !f.pristine).length;
    return isTouched && Object.values(forms).every((form: FormGroup) => {
      return form.valid;
    });
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

}
