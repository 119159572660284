<div class="outer">
    <mat-card>
        <mat-card-content>
            <app-logo></app-logo>
            <div class="formOuter">
                <form [formGroup]="signInForm" (ngSubmit)="signIn()">
                    <mat-form-field>
                        <mat-label>Email</mat-label>
                        <input type="email" matInput formControlName="email"
                                placeholder="Ex. pat@example.com">
                        <mat-error *ngIf="!signInForm.controls.email.valid && signInForm.controls.email.touched">
                            Lütfen geçerli bir eposta adresi girin
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Şifre</mat-label>
                        <input type="password" matInput formControlName="password"
                                placeholder="******">
                        <mat-error *ngIf="!signInForm.controls.password.valid && signInForm.controls.password.touched">
                            Şifrenize ihtiyacımız var
                        </mat-error>
                    </mat-form-field>
                    <button [disabled]="!signInForm.valid" type="submit" mat-raised-button color="primary">Giriş yap</button>
                  </form>
            </div>
        </mat-card-content>
    </mat-card>
</div>