<div class="shareThis {{screenMode | async}}">
    <div class="shareThisInner">
        <mat-icon *ngIf="(screenMode | async) === 'large'" [inline]="true" class="shareIcon">share</mat-icon>
        <span class="text" *ngIf="(screenMode | async) === 'small'">{{'public.generals.share' | translate }}: </span>
        <div class="shareButtons">
            <div class="shareItem" *ngFor="let link of links">
                <a [href]="link.url"  [title]="link.icon"><img [src]="'/assets/icons/' + link.icon + '.svg'" [alt]="link.icon"></a>
            </div>
        </div>
    </div>
</div>