<p class="lead mb-2">Açıklamaları Belirleyin</p>

<mat-card class="formUnit">
    <div class="lang">
        <button mat-stroked-button [matMenuTriggerFor]="menu"><span>Dil: </span><span class="font-weight-bold">{{currentLang}}</span></button>
        <mat-menu #menu="matMenu">
            <ng-container *ngFor="let lang of langs">
                <button mat-menu-item *ngIf="currentLang !== lang" (click)="changeLang(lang)">{{lang}}</button>
            </ng-container>
        </mat-menu>
    </div>
    <mat-card-content>
        <form [formGroup]="forms[currentLang]" (ngSubmit)="onSubmit()">
            <mat-form-field>
                <mat-label>Proje Adı</mat-label>
                <input type="text" matInput formControlName="name"
                        placeholder="Ex. Arya Stark">
                <mat-error *ngIf="!forms[currentLang].controls.name.valid && forms[currentLang].controls.name.touched">
                    Lütfen geçerli bir isim girin
                </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Öne çıkan başlık</mat-label>
                <input type="text" matInput formControlName="title"
                        placeholder="Ex. Arya Stark is Best">
                <mat-error *ngIf="!forms[currentLang].controls.title.valid && forms[currentLang].controls.title.touched">
                    Lütfen geçerli bir başlık girin
                </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Slogan</mat-label>
                <input type="text" matInput formControlName="slogan"
                        placeholder="Ex. Arya Stark is Best of best">
                <mat-error *ngIf="!forms[currentLang].controls.slogan.valid && forms[currentLang].controls.slogan.touched">
                    Lütfen geçerli bir slogan girin
                </mat-error>
            </mat-form-field>
            
            <mat-form-field>
                <mat-label>Özet</mat-label>
                <textarea maxlength="200"
                cdkTextareaAutosize
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"
                matInput placeholder="Ex. It makes me feel..." formControlName="summary"></textarea>
                <mat-error *ngIf="!forms[currentLang].controls.summary.valid && forms[currentLang].controls.summary.touched">
                    Lütfen projeniz için özet yazın
                </mat-error>
              </mat-form-field>

              <mat-form-field>
                <mat-label>Açıklama</mat-label>
                <textarea 
                cdkTextareaAutosize
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"
                matInput placeholder="Ex. It makes me feel because..." formControlName="desc"></textarea>
                <mat-error *ngIf="!forms[currentLang].controls.desc.valid && forms[currentLang].controls.desc.touched">
                    Lütfen projeniz için açıklama yazın...
                </mat-error>
              </mat-form-field>
        </form>

        <div class="shortInfo mt-2" *ngIf="!formsValid">
            <span class="color-pri">*</span> Güncelleme yapabilmeniz için formu tüm dillerde doldurmalısınız.
        </div>
    </mat-card-content>
</mat-card>

<app-fixed-action-buttons
    actionText="{{mode === 'edit' ? 'Güncelle' : 'İlerle'}}"
    mode="{{mode === 'edit' ? 'single' : 'stepper'}}"
    (applyAction)="onSubmit()"
    (cancelAction)="onBack()"
    [disabledState]="!formsValid"
></app-fixed-action-buttons>